import { LANGUAGES } from "../../config";

const initialState = {
  languages: LANGUAGES
};

console.log(LANGUAGES)

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
