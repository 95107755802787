import React from "react";
import Control from "../../Controls/Control";
import SaveBtn from "../../SaveBtn/SaveBtn";
import useAssistant from "../useAssistant/useAssistant";
import { IMAGE_SIZES_DIMENSIONS } from "../useAssistant/prompts.types";

const ImagePromptForm = () => {
  const {
    prompt,
    setPrompt,
    nbImages,
    imageSize,
    setNbImages,
    setImageSize,
    isLoading,
    assistantActions,
  } = useAssistant();

  const onSubmitPrompt = (e) => {
    e.preventDefault();
    assistantActions.onPromptImageAssistant(prompt, nbImages, imageSize);
  };
  console.log("prompt", prompt);
  console.log("nbImages", nbImages);
  console.log("imageSize", imageSize);

  return (
    <form onSubmit={onSubmitPrompt}>
      <Control
        label="Demander à l'assistant"
        type="textarea"
        value={prompt}
        change={(e) => {
          console.log(e.target.value);
          setPrompt(e.target.value);
        }}
        inputStyle={{ height: 210 }}
      />
      <Control
        label="Qualité"
        type="select"
        value={imageSize}
        change={(e) => setImageSize(e.target.value)}
        datas={Object.keys(IMAGE_SIZES_DIMENSIONS).map((sizeKey) => ({
          id: sizeKey,
          name: `${sizeKey} - ${IMAGE_SIZES_DIMENSIONS[sizeKey]}`,
        }))}
        dataIndex="id"
        dataLabel="name"
      />
      <Control
        label="Nombre d'images"
        type="number"
        value={nbImages}
        change={(e) => setNbImages(e.target.value)}
      />
      <SaveBtn isSaving={isLoading} typeBtn="submit" text="Suggérer" />
    </form>
  );
};

export default ImagePromptForm;
