import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

const initialState = {
  isLoading: false,
  sounds: [],
  soundsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  soundsSearch: [],
  sound: {
    name: languagesObject,
    file: null,
  },
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SOUNDS_FILTERS":
      return {
        ...state,
        soundsFilters: action.payload,
      };
    case "UPDATE_SOUNDS_SEARCH":
      return {
        ...state,
        soundsSearch: action.payload,
      };

    case "UPDATE_SOUNDS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_SOUNDS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_SOUND":
      return {
        ...state,
        sound: action.payload,
      };
    default:
      return state;
  }
}
