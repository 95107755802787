import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const purificationModel = () => ({
  name: languagesObject,
  page: "",
  sounds: [],
});

export const purificationInitialState = {
  isLoading: false,
  purifications: [],
  purificationsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  purificationsSearch: [],
  purification: purificationModel(),
  errors: {},
};

export default function (state = purificationInitialState, action) {
  switch (action.type) {
    case "UPDATE_PURIFICATIONS_FILTERS":
      return {
        ...state,
        purificationsFilters: action.payload,
      };
    case "UPDATE_PURIFICATIONS_SEARCH":
      return {
        ...state,
        purificationsSearch: action.payload,
      };
    case "UPDATE_PURIFICATIONS":
      return {
        ...state,
        purifications: action.payload,
      };

    case "UPDATE_PURIFICATIONS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_PURIFICATIONS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_PURIFICATION":
      return {
        ...state,
        purification: action.payload,
      };
    default:
      return state;
  }
}
