export const PROMPT_TYPES = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
};

export const PROMPT_MODELS = {
  STONE_DESCRIPTION: "STONE_DESCRIPTION",
  STONE_IMAGE: "STONE_IMAGE",
};

export const IMAGE_SIZES = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
};

export const IMAGE_SIZES_DIMENSIONS = {
  [IMAGE_SIZES.SMALL]: "256x256",
  [IMAGE_SIZES.MEDIUM]: "512x512",
  [IMAGE_SIZES.LARGE]: "1024x1024",
};

export const PROMPTS = {
  [PROMPT_MODELS.STONE_DESCRIPTION]: {
    promptType: PROMPT_TYPES.TEXT,
    prompt: {
      FR: "Ecris une description de la pierre {{stone}} et son utilisation en lithothérapie.",
      EN: "Write a description of the stone {{stone}} and its use in lithotherapy.",
    },
    nbWords: 100,
  },
  [PROMPT_MODELS.STONE_IMAGE]: {
    promptType: PROMPT_TYPES.IMAGE,
    prompt: {
      EN:
        "You are an image generation model specialized in lithotherapy stones. Please generate a realistic image of the semi-precious {{stone}} stone with the following specifications:\n" +
        "- Show the full {{stone}} stone without any cropping.\n" +
        "- Ensure the background is transparent.\n",
    },
    nbImages: 1,
    imageSize: IMAGE_SIZES.MEDIUM,
  },
};
