import { useEffect } from "react";
import { userActions } from "../../context/actions/userActions";
import useStore from "../../context/useStore";

const useUsers = (props) => {
  const [state, dispatch] = useStore();
  const users_actions = userActions(state, dispatch);
  const { updateUsersFilters, getUser, deleteUser } = users_actions;
  const usersState = state.users;
  const usersFilters = usersState.usersFilters;
  const usersErrors = usersState.errors;
  const { user, isLoading, usersSearch } = usersState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = usersState.usersFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateUsersFilters(newFilters);
  };

  useEffect(() => {
    if (props && props?.match?.params?.id != "create") {
      getUser(props.match.params.id);
    }
  }, []);

  return {
    state,
    dispatch,
    constants: state.constants.items,
    users_actions,
    usersState,
    usersSearch,
    updateFilters,
    usersFilters,
    usersErrors,
    user,
    isLoading,
    languages: state.languages.languages,
    deleteUser,
  };
};

export default useUsers;
