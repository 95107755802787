import { useEffect } from "react";
import colors from "../../../helpers/colors";
import SaveBtn from "../SaveBtn/SaveBtn";

const FiltersContainer = ({
  onSubmit = () => {},
  filtersState,
  filtersErrors,
  isDataLoaded,
  ...props
}) => {
  useEffect(() => {
    onSubmit();
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {props.children}
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <SaveBtn
            text={isDataLoaded ? "Actualiser" : "Rechercher"}
            typeBtn="submit"
            type="link"
            isSaving={filtersState.isLoading}
          />
        </div>
      </div>
    </form>
  );
};

export default FiltersContainer;
