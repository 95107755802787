import React from "react";
import useStones from "../../../../hooks/useStones/useStones";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const StonesFilters = () => {
  const {
    updateFilters,
    stonesState,
    stonesFilters,
    stonesErrors,
    stones_actions,
  } = useStones();
  return (
    <FiltersContainer
      onSubmit={stones_actions.searchStones}
      filtersState={stonesState}
      isDataLoaded={stonesState.stonesSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={stonesFilters.terms}
            change={updateFilters}
            errors={stonesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default StonesFilters;
