import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const unhappinessCategoryModel = () => ({
  name: languagesObject,
  music: "",
});

export const unhappinessCategoryInitialState = {
  isLoading: false,
  unhappinessCategories: [],
  unhappinessCategoriesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  unhappinessCategoriesSearch: [],
  unhappinessCategory: unhappinessCategoryModel(),
  errors: {},
};

export default function (state = unhappinessCategoryInitialState, action) {
  switch (action.type) {
    case "UPDATE_UNHAPPINESS_CATEGORIES_FILTERS":
      return {
        ...state,
        unhappinessCategoriesFilters: action.payload,
      };
    case "UPDATE_UNHAPPINESS_CATEGORIES_SEARCH":
      return {
        ...state,
        unhappinessCategoriesSearch: action.payload,
      };
    case "UPDATE_UNHAPPINESS_CATEGORIES":
      return {
        ...state,
        unhappinessCategories: action.payload,
      };

    case "UPDATE_UNHAPPINESS_CATEGORIES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_UNHAPPINESS_CATEGORIES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_UNHAPPINESS_CATEGORY":
      return {
        ...state,
        unhappinessCategory: action.payload,
      };
    default:
      return state;
  }
}
