import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const unhappinessCategoryActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORIES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORIES_LOADING",
      payload: isLoading,
    });
  };
  const updateUnhappinessCategoriesFilters = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORIES_FILTERS",
      payload: filters,
    });
  };
  const updateUnhappinessCategoriesPage = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORIES_FILTERS",
      payload: filters,
    });
    searchUnhappinessCategories(filters);
  };
  const searchUnhappinessCategories = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `unhappiness-categories/search`,
      filters || state.unhappinessCategories.unhappinessCategoriesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_UNHAPPINESS_CATEGORIES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getUnhappinessCategories = () => {
    updateIsLoading(true);
    dataService.get(
      `unhappiness-categories`,
      updateUnhappinessCategories,
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const updateUnhappinessCategories = (unhappinessCategories) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORIES",
      payload: unhappinessCategories,
    });
  };
  const getUnhappinessCategory = (unhappinessCategoryId) => {
    updateIsLoading(true);
    dataService.get(
      `unhappiness-categories/${unhappinessCategoryId}`,
      updateUnhappinessCategory,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateUnhappinessCategory = (unhappinessCategory) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_CATEGORY",
      payload: unhappinessCategory,
    });
  };
  const updateUnhappinessCategoryAttribute = ({ event, lang }) => {
    console.log("EV", event, lang);
    const { unhappinessCategory } = state.unhappinessCategories;
    const { name, value } = event.target;
    let newUnhappinessCategory = {};
    if (lang) {
      newUnhappinessCategory = {
        ...unhappinessCategory,
        [name]: {
          ...unhappinessCategory[name],
          [lang]: value,
        },
      };
    } else {
      newUnhappinessCategory = {
        ...unhappinessCategory,
        [name]: value,
      };
    }
    console.log("newUnhappinessCategory", newUnhappinessCategory);
    updateUnhappinessCategory(newUnhappinessCategory);
  };

  const saveUnhappinessCategory = () => {
    const { unhappinessCategory } = state.unhappinessCategories;
    updateIsLoading(true);
    if (unhappinessCategory.id) {
      dataService.patch(
        `unhappiness-categories/${unhappinessCategory.id}`,
        unhappinessCategory,
        (datas) => {
          updateUnhappinessCategory(datas);
          updateErrors({});
          toast.success("La catégorie de mal-être a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `unhappiness-categories`,
        unhappinessCategory,
        (datas) => {
          updateUnhappinessCategory(datas);
          updateErrors({});
          const { unhappinessCategories } = state.unhappinessCategories;
          updateUnhappinessCategories([...unhappinessCategories, datas]);
          toast.success("La catégorie de mal-être a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteUnhappinessCategory = (unhappinessCategoryId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `unhappiness-categories/${unhappinessCategoryId}`,
      (datas) => searchUnhappinessCategories(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchUnhappinessCategories,
    updateUnhappinessCategoriesFilters,
    updateUnhappinessCategory,
    updateUnhappinessCategoryAttribute,
    saveUnhappinessCategory,
    getUnhappinessCategory,
    deleteUnhappinessCategory,
    updateUnhappinessCategoriesPage,
    getUnhappinessCategories,
  };
};
