import React from "react";
import { Link } from "react-router-dom";
import useReloadings from "../../../../hooks/useReloadings/useReloadings";
import Table from "../../../common/Table/Table";

const ReloadingsListTable = () => {
  const { isLoading, reloadingsSearch, languages, reloadings_actions } =
    useReloadings();

  return (
    <Table
      isLoading={isLoading}
      nbResults={reloadingsSearch.count}
      datas={reloadingsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/reloadings/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => reloadings_actions.deleteReloading(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ReloadingsListTable;
