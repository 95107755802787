import React from 'react';
import useUsers from '../../../../hooks/useUsers/useUsers';
import Page from '../../../common/layout/Page';
import useTopMenu from '../../../common/layout/TopMenu/useTopMenu';
import Pagination from '../../../common/Pagination/Pagination';
import UsersFilters from './UsersFilters';
import UsersListTable from './UsersListTable';

const UsersList = (props) => {
    const { usersState, usersSearch, usersFilters, users_actions } = useUsers();

    return (
        <Page
            title="Utilisateurs"
            errors={usersState.errors}
            isLoading={usersState.isLoading}
            action={{
                to: 'users/create',
                text: 'Créer un utilisateur',
            }}
            {...props}
        >
            <div className="">
                <UsersFilters />
                <div className="mt-3">
                    <UsersListTable />
                </div>
                {usersSearch.count ? (
                    <Pagination
                        nbPages={Math.ceil(usersSearch.count / 25)}
                        page={usersFilters.page}
                        changePage={(p) =>
                            users_actions.updateUsersPage({
                                ...usersFilters,
                                page: p,
                            })
                        }
                    />
                ) : null}
            </div>
        </Page>
    );
};

export default UsersList;
