import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const aspectActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_ASPECTS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_ASPECTS_LOADING",
      payload: isLoading,
    });
  };
  const updateAspectsFilters = (filters) => {
    dispatch({
      type: "UPDATE_ASPECTS_FILTERS",
      payload: filters,
    });
  };
  const updateAspectsPage = (filters) => {
    dispatch({
      type: "UPDATE_ASPECTS_FILTERS",
      payload: filters,
    });
    searchAspects(filters);
  };
  const searchAspects = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `aspects/search`,
      filters || state.aspects.aspectsFilters,
      (data) => {
        console.log("DATA", data);
        dispatch({
          type: "UPDATE_ASPECTS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getAspect = (aspectId) => {
    updateIsLoading(true);
    dataService.get(`aspects/${aspectId}`, updateAspect, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateAspect = (aspect) => {
    dispatch({
      type: "UPDATE_ASPECT",
      payload: aspect,
    });
  };
  const getAspects = () => {
    updateIsLoading(true);
    dataService.get(`aspects`, updateAspects, updateErrors, () => updateIsLoading(false));
  };

  const updateAspects = (aspects) => {
    dispatch({
      type: "UPDATE_ASPECTS",
      payload: aspects,
    });
  };
  const updateAspectAttribute = ({ event, lang }) => {
    console.log("EV", event, lang);
    const { aspect } = state.aspects;
    const { name, value } = event.target;
    let newAspect = {};
    if (lang) {
      newAspect = {
        ...aspect,
        [name]: {
          ...aspect[name],
          [lang]: value,
        },
      };
    } else {
      newAspect = {
        ...aspect,
        [name]: value,
      };
    }
    console.log("newAspect", newAspect);
    updateAspect(newAspect);
  };

  const saveAspect = () => {
    const { aspect } = state.aspects;
    updateIsLoading(true);
    if (aspect.id) {
      dataService.patch(
        `aspects/${aspect.id}`,
        aspect,
        (datas) => {
          updateAspect(datas);
          updateErrors({});
          toast.success("La aspect a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `aspects`,
        aspect,
        (datas) => {
          updateAspect(datas);
          updateErrors({});
          const { aspects } = state.aspects;
          updateAspects([...aspects, datas]);
          toast.success("La aspect a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteAspect = (aspectId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `aspects/${aspectId}`,
      (datas) => searchAspects(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchAspects,
    updateAspectsFilters,
    updateAspect,
    updateAspectAttribute,
    saveAspect,
    getAspect,
    deleteAspect,
    getAspects,
    updateAspects,
    updateAspectsPage,
  };
};
