import React from "react";
import { Link } from "react-router-dom";
import useCrystalSystems from "../../../../hooks/useCrystalSystems/useCrystalSystems";
import Table from "../../../common/Table/Table";

const CrystalSystemsListTable = () => {
  const {
    isLoading,
    crystalSystemsSearch,
    languages,
    crystal_systems_actions,
  } = useCrystalSystems();

  return (
    <Table
      isLoading={isLoading}
      nbResults={crystalSystemsSearch.count}
      datas={crystalSystemsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/crystal-systems/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() =>
                    crystal_systems_actions.deleteCrystalSystem(el.id)
                  }
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default CrystalSystemsListTable;
