import React, { useState, useEffect } from "react";

import ExportCSV from "../ExportCsv/ExportCsv";
import LangBadge from "../LangBadge/LangBadge";
import Loader from "../Loader";

const Table = ({
  datas,
  fields,
  exportOptions,
  isLoading,
  noMargin = false,
  nbResults = null,
  lineBackgroundFromAttribute = false,
  lineColor = false,
  cardBg = "",
  languages = null,
}) => {
  return (
    <div>
      {exportOptions && (
        <div
          className={`d-flex justify-content-end animated faster mb-2 ${
            isLoading ? "fadeOut" : "fadeIn"
          }`}
        >
          <ExportCSV
            fields={fields}
            datas={datas}
            exportOptions={exportOptions}
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-end">
        <div className="badge text-white" style={{ fontSize: 12 }}>
          {nbResults !== null ? (
            <>
              {isLoading ? (
                "-"
              ) : (
                <>
                  {nbResults} résultat{nbResults > 0 ? "s" : ""}
                </>
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div
        className={`pt-0  w-100 custom-table-container  ${cardBg}`}
        style={{
          overflowY: "auto",
        }}
      >
        <table className="table table-striped table-borderless  custom-table mb-0">
          <thead>
            <tr>
              {fields.map((h, hk) => (
                <th
                  className={`px-2 ${h.headerClassname || ""}`}
                  key={"th" + hk + h.name}
                  style={{
                    textAlign: hk == fields.length - 1 ? "right" : "left",
                  }}
                >
                  {h.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!datas?.length ? (
              <tr className="animated fadeInUp faster">
                {fields.map((f) => {
                  return <td>-</td>;
                })}
              </tr>
            ) : (
              datas.map((d, dk) => (
                <tr
                  key={"dk" + dk}
                  className="animated fadeInUp faster"
                  style={{
                    animationDelay: dk * 30 + "ms",
                  }}
                >
                  {fields.map((f, fk) => (
                    <td
                      style={{
                        verticalAlign: "middle",
                        background: lineBackgroundFromAttribute
                          ? d[lineBackgroundFromAttribute]
                          : "",
                        color: lineColor || "",
                        textAlign: fk == fields.length - 1 ? "right" : "left",
                      }}
                      key={"df" + dk + "" + fk}
                    >
                      {f.render
                        ? f.render(f.path == "*" ? d : d[f.path], dk)
                        : languages
                        ? languages.map((lang, langk) => {
                            return (
                              <div
                                key={`${lang}${langk}${dk}`}
                                style={{
                                  opacity: langk == 0 ? "1" : "0.3",
                                }}
                              >
                                <LangBadge lang={lang} /> {d[f.path][lang]}
                              </div>
                            );
                          })
                        : d[f.path]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
