import React from "react";
import Control from "../../Controls/Control";
import SaveBtn from "../../SaveBtn/SaveBtn";
import useAssistant from "../useAssistant/useAssistant";

const TextPromptForm = () => {
  const {
    prompt,
    setPrompt,
    nbWords,
    setNbWords,
    isLoading,
    assistantActions,
  } = useAssistant();

  const onSubmitPrompt = (e) => {
    e.preventDefault();
    assistantActions.onPromptTextAssistant(prompt, nbWords);
  };

  return (
    <form onSubmit={onSubmitPrompt}>
      <Control
        label="Demander à l'assistant"
        type="textarea"
        value={prompt}
        change={(e) => setPrompt(e.target.value)}
        inputStyle={{ height: 210 }}
      />
      <Control
        label="Nombre de mots"
        type="number"
        value={nbWords}
        change={(e) => setNbWords(e.target.value)}
      />
      <SaveBtn isSaving={isLoading} typeBtn="submit" text="Suggérer" />
    </form>
  );
};

export default TextPromptForm;
