import React, { useEffect } from "react";
import { remedyActions } from "../../../../context/actions/remedyActions";
import { unhappinessCategoryActions } from "../../../../context/actions/unhappinessCategoryActions";
import { unhappinessSentenceActions } from "../../../../context/actions/unhappinessSentenceActions";
import {
  unhappinessInitialState,
  unhappinessModel,
} from "../../../../context/reducers/unhappinessReducers";
import useStore from "../../../../context/useStore";
import useUnhappinesses from "../../../../hooks/useUnhappinesses/useUnhappinesses";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import RemediesDetail from "../../Remedies/RemediesDetail/RemediesDetail";
import UnhappinessCategoriesDetail from "../../UnhappinessCategories/UnhappinessCategoriesDetail/UnhappinessCategoriesDetail";
import UnhappinessSentencesDetail from "../../UnhappinessSentences/UnhappinessSentencesDetail/UnhappinessSentencesDetail";

const UnhappinessesDetail = (props) => {
  const {
    state,
    dispatch,
    unhappinesses_actions,
    unhappiness,
    unhappinessesErrors,
    isLoading,
    constants,
  } = useUnhappinesses(props);
  const {
    updateUnhappinessAttribute,
    saveUnhappiness,
    getUnhappiness,
    updateUnhappiness,
  } = unhappinesses_actions;

  const { getUnhappinessCategories } = unhappinessCategoryActions(
    state,
    dispatch
  );
  const { getUnhappinessSentences } = unhappinessSentenceActions(
    state,
    dispatch
  );
  const { getRemedies } = remedyActions(state, dispatch);

  useEffect(() => {
    getUnhappinessCategories();
    getUnhappinessSentences();
    getRemedies();

    if (props && props?.match?.params?.id != "create") {
      getUnhappiness(props.match.params.id);
    } else {
      updateUnhappiness(unhappinessModel());
    }
  }, []);

  return (
    <Page
      title={unhappiness?.name?.FR || "Mal-être"}
      back={`/unhappinesses`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveUnhappiness} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateUnhappinessAttribute}
          type="text"
          value={unhappiness.name}
          error={unhappinessesErrors}
        />

        <div className="row">
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Phrases de mal-être"
              name="unhappinessSentences"
              value={unhappiness.unhappinessSentences}
              change={(event) => updateUnhappinessAttribute({ event })}
              btnLabel="Ajouter une phrase"
              field={{
                type: "btnList",
                btnInline: true,
                name: "unhappinessSentence",
                datas: state.unhappinessSentences.unhappinessSentences,
                dataIndex: "id",
                dataLabel: "sentence",
                dataLabelIndex: "FR",
              }}
              error={unhappinessesErrors}
              entityComponent={<UnhappinessSentencesDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Catégorie de mal-être"
              name="unhappinessCategory"
              change={(event) => updateUnhappinessAttribute({ event })}
              type="btnList"
              btnInline
              value={unhappiness.unhappinessCategory}
              datas={state.unhappinessCategories.unhappinessCategories}
              dataIndex="id"
              dataLabel="name"
              dataLabelIndex="FR"
              error={unhappinessesErrors}
              entityComponent={<UnhappinessCategoriesDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Remèdes"
              name="remedies"
              value={unhappiness.remedies}
              change={(event) => updateUnhappinessAttribute({ event })}
              btnLabel="Ajouter une couleur"
              field={{
                type: "btnList",
                btnInline: true,
                name: "color",
                datas: state.remedies.remedies,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={unhappinessesErrors}
              entityComponent={<RemediesDetail />}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default UnhappinessesDetail;
