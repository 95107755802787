import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const chakraActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_CHAKRAS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_CHAKRAS_LOADING",
      payload: isLoading,
    });
  };
  const updateChakrasFilters = (filters) => {
    dispatch({
      type: "UPDATE_CHAKRAS_FILTERS",
      payload: filters,
    });
  };
  const updateChakrasPage = (filters) => {
    dispatch({
      type: "UPDATE_CHAKRAS_FILTERS",
      payload: filters,
    });
    searchChakras(filters);
  };
  const searchChakras = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `chakras/search`,
      filters || state.chakras.chakrasFilters,
      (data) => {
        dispatch({
          type: "UPDATE_CHAKRAS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getChakra = (chakraId) => {
    updateIsLoading(true);
    dataService.get(`chakras/${chakraId}`, updateChakra, updateErrors, () =>
      updateIsLoading(false)
    );
  };
  const getChakras = () => {
    updateIsLoading(true);
    dataService.get(`chakras`, updateChakras, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateChakra = (chakra) => {
    dispatch({
      type: "UPDATE_CHAKRA",
      payload: chakra,
    });
  };
  const updateChakras = (chakras) => {
    dispatch({
      type: "UPDATE_CHAKRAS",
      payload: chakras,
    });
  };
  const updateChakraAttribute = ({ event, lang }) => {
    console.log("EV", event, lang);
    const { chakra } = state.chakras;
    const { name, value } = event.target;
    let newChakra = {};
    if (lang) {
      newChakra = {
        ...chakra,
        [name]: {
          ...chakra[name],
          [lang]: value,
        },
      };
    } else {
      newChakra = {
        ...chakra,
        [name]: value,
      };
    }
    console.log("newChakra", newChakra);
    updateChakra(newChakra);
  };

  const saveChakra = () => {
    const { chakra } = state.chakras;
    updateIsLoading(true);
    if (chakra.id) {
      dataService.patch(
        `chakras/${chakra.id}`,
        chakra,
        (datas) => {
          updateChakra(datas);
          updateErrors({});
          toast.success("Le chakra a bien été mis à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `chakras`,
        chakra,
        (datas) => {
          updateChakra(datas);
          const { chakras } = state.chakras;
          updateChakras([...chakras, datas]);
          updateErrors({});

          toast.success("Le chakra a bien été créé");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteChakra = (chakraId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `chakras/${chakraId}`,
      (datas) => searchChakras(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchChakras,
    updateChakrasFilters,
    updateChakra,
    updateChakraAttribute,
    saveChakra,
    getChakra,
    deleteChakra,
    getChakras,
    updateChakras,
    updateChakrasPage,
  };
};
