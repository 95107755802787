import React, { useEffect } from "react";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { colorActions } from "../../../../context/actions/colorActions";
import { crystalSystemActions } from "../../../../context/actions/crystalSystemActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { remedyActions } from "../../../../context/actions/remedyActions";
import { stepActions } from "../../../../context/actions/stepActions";
import useRemedies from "../../../../hooks/useRemedies/useRemedies";
import Page from "../../../common/layout/Page";
import useTopMenu from "../../../common/layout/TopMenu/useTopMenu";
import Pagination from "../../../common/Pagination/Pagination";
import RemediesFilters from "./RemediesFilters";
import RemediesListTable from "./RemediesListTable";

const RemediesList = (props) => {
  const {
    remediesState,
    remediesSearch,
    remediesFilters,
    remedies_actions,
    state,
    dispatch,
  } = useRemedies();

  const { getSteps } = stepActions(state, dispatch);

  useEffect(() => {
    getSteps();
  }, []);

  return (
    <Page
      title="Remède"
      errors={remediesState.errors}
      isLoading={remediesState.isLoading}
      action={{
        to: "remedies/create",
        text: "Créer une pierre",
      }}
      {...props}
    >
      <div className="">
        <RemediesFilters />
        <div className="mt-3">
          <RemediesListTable />
        </div>
        {remediesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(remediesSearch.count / 25)}
            page={remediesFilters.page}
            changePage={(p) =>
              remedies_actions.updateRemediesPage({
                ...remediesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default RemediesList;
