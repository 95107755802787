import React from "react";
import AssistantResult from "../AssistantResult/AssistantResult";

const AssistantContainer = ({ children }) => {
  return (
    <div>
      {children}
      <AssistantResult />
    </div>
  );
};

export default AssistantContainer;
