import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const colorActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_COLORS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_COLORS_LOADING",
      payload: isLoading,
    });
  };
  const updateColorsFilters = (filters) => {
    dispatch({
      type: "UPDATE_COLORS_FILTERS",
      payload: filters,
    });
  };
  const updateColorsPage = (filters) => {
    dispatch({
      type: "UPDATE_COLORS_FILTERS",
      payload: filters,
    });
    searchColors(filters);
  };
  const searchColors = (filters) => {
    updateIsLoading(true);
    dataService.post(
      `colors/search`,
      filters || state.colors.colorsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_COLORS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getColor = (colorId) => {
    updateIsLoading(true);
    dataService.get(`colors/${colorId}`, updateColor, updateErrors, () =>
      updateIsLoading(false)
    );
  };
  const getColors = () => {
    updateIsLoading(true);
    dataService.get(`colors`, updateColors, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateColor = (color) => {
    dispatch({
      type: "UPDATE_COLOR",
      payload: color,
    });
  };
  const updateColors = (colors) => {
    dispatch({
      type: "UPDATE_COLORS",
      payload: colors,
    });
  };
  const updateColorAttribute = ({ event, lang }) => {
    const { color } = state.colors;
    const { name, value } = event.target;
    let newColor = {};
    if (lang) {
      newColor = {
        ...color,
        [name]: {
          ...color[name],
          [lang]: value,
        },
      };
    } else {
      newColor = {
        ...color,
        [name]: value,
      };
    }
    console.log("newColor", newColor);
    updateColor(newColor);
  };

  const saveColor = () => {
    const { color } = state.colors;
    updateIsLoading(true);
    if (color.id) {
      dataService.patch(
        `colors/${color.id}`,
        color,
        (datas) => {
          updateColor(datas);
          updateErrors({});
          toast.success("La couleur a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `colors`,
        color,
        (datas) => {
          updateColor(datas);
          updateErrors({});
          const { colors } = state.colors;
          updateColors([...colors, datas]);
          toast.success("La couleur a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteColor = (colorId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `colors/${colorId}`,
      (datas) => searchColors(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchColors,
    updateColorsFilters,
    updateColor,
    updateColorAttribute,
    saveColor,
    getColor,
    deleteColor,
    getColors,
    updateColors,
    updateColorsPage,
  };
};
