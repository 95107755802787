import React from "react";
import useActions from "../../../../hooks/useActions/useActions";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const ActionsFilters = () => {
  const { updateFilters, actionsState, actionsFilters, actionsErrors, actions_actions } =
    useActions();
  return (
    <FiltersContainer
      onSubmit={actions_actions.searchActions}
      filtersState={actionsState}
      isDataLoaded={actionsState.actionsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={actionsFilters.terms}
            change={updateFilters}
            errors={actionsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ActionsFilters;
