import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";

const Dashboard = () => {
  const [state, dispatch] = useStore();

  return <Page title="Dashboard"></Page>;
};

export default Dashboard;
