import React from "react";
import useStoneCategories from "../../../../hooks/useStoneCategories/useStoneCategories";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const StoneCategoriesFilters = () => {
  const {
    updateFilters,
    stoneCategoriesState,
    stoneCategoriesFilters,
    stoneCategoriesErrors,
    stone_categories_actions,
  } = useStoneCategories();

  return (
    <FiltersContainer
      onSubmit={stone_categories_actions.searchStoneCategories}
      filtersState={stoneCategoriesState}
      isDataLoaded={stoneCategoriesState.stoneCategoriesSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={stoneCategoriesFilters.terms}
            change={updateFilters}
            errors={stoneCategoriesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default StoneCategoriesFilters;
