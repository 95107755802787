const menuItems = [
  {
    name: "Pierres",
    path: "/stones",
    icon: "fa fa-gem",
  },
  {
    name: "Attributs",
    path: "#",
    icon: "fa fa-cog",
    items: [
      {
        name: "Catégories de pierres",
        path: "/stone-categories",
        icon: "fa fa-folder",
      },
      {
        name: "Systèmes Cristallins",
        path: "/crystal-systems",
        icon: "fab fa-hive",
      },
      {
        name: "Couleurs",
        path: "/colors",
        icon: "fa fa-palette",
      },
      {
        name: "Aspects",
        path: "/aspects",
        icon: "fa fa-palette",
      },
      // {
      //   name: "Sons",
      //   path: "/sounds",
      //   icon: "pencil-ruler",
      // },
      {
        name: "Chakras",
        path: "/chakras",
        icon: "fab fa-medrt",
      },
      {
        name: "Rechargements",
        path: "/reloadings",
        icon: "fa fa-battery-three-quarters",
      },
      {
        name: "Purifications",
        path: "/purifications",
        icon: "fa fa-wind",
      },
    ],
  },
  {
    name: "Mal-êtres",
    path: "#",
    icon: "fa fa-cog",
    items: [
      {
        name: "Actions >",
        path: "/actions",
        icon: "fa fa-file-alt",
      },

      {
        name: "Étapes >",
        path: "/steps",
        icon: "fa fa-file-alt",
      },
      {
        name: "Remèdes >",
        path: "/remedies",
        icon: "fa fa-hand-holding-medical",
      },
      {
        name: "Mal-êtres",
        path: "/unhappinesses",
        icon: "fa fa-procedures",
      },
      {
        name: "< Catégories de mal-êtres",
        path: "/unhappiness-categories",
        icon: "fa fa-book-medical",
      },
      {
        name: "< Phrases de mal-êtres",
        path: "/unhappiness-sentences",
        icon: "fa fa-tired",
      },
    ],
  },
  {
    name: "Utilisateurs",
    path: "/users",
    icon: "fa fa-users",
  },
];
export default menuItems;
