import React from "react";
import useUnhappinessCategories from "../../../../hooks/useUnhappinessCategories/useUnhappinessCategories";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import UnhappinessCategoriesFilters from "./UnhappinessCategoriesFilters";
import UnhappinessCategoriesListTable from "./UnhappinessCategoriesListTable";

const UnhappinessCategoriesList = () => {
  const {
    unhappinessCategoriesState,
    unhappinessCategoriesSearch,
    unhappinessCategoriesFilters,
    unhappinessCategories_actions,
  } = useUnhappinessCategories();

  return (
    <Page
      title="Catégories de mal-être"
      errors={unhappinessCategoriesState.errors}
      isLoading={unhappinessCategoriesState.isLoading}
      action={{
        to: "unhappiness-categories/create",
        text: "Créer une catégorie de mal-être",
      }}
    >
      <div className="">
        <UnhappinessCategoriesFilters />
        <div className="mt-3">
          <UnhappinessCategoriesListTable />
        </div>
        {unhappinessCategoriesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(unhappinessCategoriesSearch.count / 25)}
            page={unhappinessCategoriesFilters.page}
            changePage={(p) =>
              unhappinessCategories_actions.updateUnhappinessCategoriesPage({
                ...unhappinessCategoriesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default UnhappinessCategoriesList;
