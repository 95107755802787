import React, { useEffect } from "react";
import {
  stoneCategoryInitialState,
  stoneCategoryModel,
} from "../../../../context/reducers/stoneCategoryReducers";
import useStore from "../../../../context/useStore";
import useStoneCategories from "../../../../hooks/useStoneCategories/useStoneCategories";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";

const StoneCategoriesDetail = (props) => {
  const {
    state,
    stone_categories_actions,
    stoneCategory,
    stoneCategoriesErrors,
    isLoading,
  } = useStoneCategories(props);
  const {
    updateStoneCategoryAttribute,
    saveStoneCategory,
    getStoneCategory,
    updateStoneCategory,
  } = stone_categories_actions;
  const { languages } = state;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getStoneCategory(paramsId);
    } else {
      updateStoneCategory(stoneCategoryModel());
    }
  }, []);

  return (
    <Page
      title={stoneCategory.name?.FR || "Catégorie de pierre"}
      back={`/stone-categories`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveStoneCategory} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateStoneCategoryAttribute}
          type="text"
          value={stoneCategory.name}
          error={stoneCategoriesErrors}
        />
      </FormContainer>
    </Page>
  );
};

export default StoneCategoriesDetail;
