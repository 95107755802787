import React, { useEffect } from "react";
import useActions from "../../../../hooks/useActions/useActions";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import ActionsFilters from "./ActionsFilters";
import ActionsListTable from "./ActionsListTable";

const ActionsList = (props) => {
  const { actionsState, actionsSearch, actionsFilters, actions_actions, state, dispatch } =
    useActions();

  useEffect(() => {}, []);

  return (
    <Page
      title="Actions"
      errors={actionsState.errors}
      isLoading={actionsState.isLoading}
      action={{
        to: "actions/create",
        text: "Créer une action",
      }}
      {...props}
    >
      <div className="">
        <ActionsFilters />
        <div className="mt-3">
          <ActionsListTable />
        </div>
        {actionsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(actionsSearch.count / 25)}
            page={actionsFilters.page}
            changePage={(p) =>
              actions_actions.updateActionsPage({
                ...actionsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ActionsList;
