import React from "react";
import { Link } from "react-router-dom";
import useUnhappinessSentences from "../../../../hooks/useUnhappinessSentences/useUnhappinessSentences";
import Table from "../../../common/Table/Table";

const UnhappinessSentencesListTable = () => {
  const {
    isLoading,
    unhappinessSentencesSearch,
    languages,
    unhappinessSentences_actions,
  } = useUnhappinessSentences();

  return (
    <Table
      isLoading={isLoading}
      nbResults={unhappinessSentencesSearch.count}
      datas={unhappinessSentencesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Phrase",
          path: "sentence",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/unhappiness-sentences/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() =>
                    unhappinessSentences_actions.deleteUnhappinessSentence(
                      el.id
                    )
                  }
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default UnhappinessSentencesListTable;
