import React, { useEffect } from "react";
import {
  purificationInitialState,
  purificationModel,
} from "../../../../context/reducers/purificationReducers";
import useStore from "../../../../context/useStore";
import usePurifications from "../../../../hooks/usePurifications/usePurifications";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";

const PurificationsDetail = (props) => {
  const {
    state,
    purifications_actions,
    purification,
    purificationsErrors,
    isLoading,
    constants,
  } = usePurifications(props);
  const {
    updatePurificationAttribute,
    savePurification,
    updatePurification,
    getPurification,
  } = purifications_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getPurification(paramsId);
    } else {
      updatePurification(purificationModel());
    }
  }, []);

  return (
    <Page
      title={purification.name?.FR || "Purifications"}
      back={`/purifications`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={savePurification} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updatePurificationAttribute}
          type="text"
          value={purification.name}
          error={purificationsErrors}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Page liée"
              name="page"
              value={purification.page}
              change={(event) => updatePurificationAttribute({ event })}
              type="btnList"
              btnInline
              datas={constants.PAGES.filter(
                (p) => p.category == "PURIFICATION"
              )}
              dataIndex="id"
              dataLabel="name"
              error={purificationsErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Sons"
              name="sounds"
              value={purification.sounds}
              change={(event) => updatePurificationAttribute({ event })}
              btnLabel="Ajouter un son"
              field={{
                type: "select",
                name: "sound",
                datas: constants.SOUNDS,
              }}
              error={purificationsErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default PurificationsDetail;
