import { useEffect } from "react";
import { unhappinessCategoryActions } from "../../context/actions/unhappinessCategoryActions";
import useStore from "../../context/useStore";

const useUnhappinessCategories = (props) => {
  const [state, dispatch] = useStore();
  const unhappinessCategories_actions = unhappinessCategoryActions(
    state,
    dispatch
  );
  const {
    updateUnhappinessCategoriesFilters,
    getUnhappinessCategory,
    deleteUnhappinessCategory,
  } = unhappinessCategories_actions;
  const unhappinessCategoriesState = state.unhappinessCategories;
  const unhappinessCategoriesFilters =
    unhappinessCategoriesState.unhappinessCategoriesFilters;
  const unhappinessCategoriesErrors = unhappinessCategoriesState.errors;
  const { unhappinessCategory, isLoading, unhappinessCategoriesSearch } =
    unhappinessCategoriesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject =
      unhappinessCategoriesState.unhappinessCategoriesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateUnhappinessCategoriesFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    unhappinessCategories_actions,
    unhappinessCategoriesState,
    unhappinessCategoriesSearch,
    updateFilters,
    unhappinessCategoriesFilters,
    unhappinessCategoriesErrors,
    unhappinessCategory,
    isLoading,
    languages: state.languages.languages,
    deleteUnhappinessCategory,
    constants: state.constants.items,
  };
};

export default useUnhappinessCategories;
