import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const purificationActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_PURIFICATIONS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_PURIFICATIONS_LOADING",
      payload: isLoading,
    });
  };
  const updatePurificationsFilters = (filters) => {
    dispatch({
      type: "UPDATE_PURIFICATIONS_FILTERS",
      payload: filters,
    });
  };
  const updatePurificationsPage = (filters) => {
    dispatch({
      type: "UPDATE_PURIFICATIONS_FILTERS",
      payload: filters,
    });
    searchPurifications(filters);
  };
  const searchPurifications = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `purifications/search`,
      filters || state.purifications.purificationsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_PURIFICATIONS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getPurification = (purificationId) => {
    updateIsLoading(true);
    dataService.get(
      `purifications/${purificationId}`,
      updatePurification,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updatePurification = (purification) => {
    dispatch({
      type: "UPDATE_PURIFICATION",
      payload: purification,
    });
  };
  const getPurifications = () => {
    updateIsLoading(true);
    dataService.get(`purifications`, updatePurifications, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updatePurifications = (purifications) => {
    dispatch({
      type: "UPDATE_PURIFICATIONS",
      payload: purifications,
    });
  };
  const updatePurificationAttribute = ({ event, lang }) => {
    console.log("EV", event, lang);
    const { purification } = state.purifications;
    const { name, value } = event.target;
    let newPurification = {};
    if (lang) {
      newPurification = {
        ...purification,
        [name]: {
          ...purification[name],
          [lang]: value,
        },
      };
    } else {
      newPurification = {
        ...purification,
        [name]: value,
      };
    }
    console.log("newPurification", newPurification);
    updatePurification(newPurification);
  };

  const savePurification = () => {
    const { purification } = state.purifications;
    updateIsLoading(true);
    if (purification.id) {
      dataService.patch(
        `purifications/${purification.id}`,
        purification,
        (datas) => {
          updatePurification(datas);
          updateErrors({});
          toast.success("La purification a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `purifications`,
        purification,
        (datas) => {
          updatePurification(datas);
          updateErrors({});
          const { purifications } = state.purifications;
          updatePurifications([...purifications, datas]);
          toast.success("La purification a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deletePurification = (purificationId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `purifications/${purificationId}`,
      (datas) => searchPurifications(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchPurifications,
    updatePurificationsFilters,
    updatePurification,
    updatePurificationAttribute,
    savePurification,
    getPurification,
    deletePurification,
    getPurifications,
    updatePurifications,
    updatePurificationsPage,
  };
};
