import React from "react";
import useColors from "../../../../hooks/useColors/useColors";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const ColorsFilters = () => {
  const {
    updateFilters,
    colorsState,
    colorsFilters,
    colorsErrors,
    colors_actions,
  } = useColors();

  return (
    <FiltersContainer
      onSubmit={colors_actions.searchColors}
      filtersState={colorsState}
      isDataLoaded={colorsState.colorsSearch?.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={colorsFilters.terms}
            change={updateFilters}
            errors={colorsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ColorsFilters;
