import { useEffect, useState } from "react";
import tools from "./helpers/tools";

const BackgroundSwitcher = () => {
  const [currentBg, setCurrentBg] = useState(0);
  const urls = ["/images/bg1.png"];

  const incrementBg = () => {
    setCurrentBg((curr) => (currentBg == urls.length - 1 ? 0 : curr + 1));
  };
  const decrementBg = () => {
    setCurrentBg((curr) => (currentBg == 0 ? urls.length - 1 : curr - 1));
  };

  useEffect(() => {
    setCurrentBg(tools.getState("layout", { bgimage: 0 }).bgimage);
  }, []);

  useEffect(() => {
    console.log("currentBg", currentBg);
    let bodyElem = document.querySelector("body");
    if (bodyElem) {
      bodyElem.style.backgroundImage = `url(${urls[currentBg]})`;
    }
    tools.saveState("layout", "bgimage", currentBg);
  }, [currentBg]);

  return null;
  // <div
  //   style={{
  //     position: "fixed",
  //     bottom: 0,
  //     right: 0,

  //     zIndex: "9999",
  //   }}
  // >
  //   <div className="d-cener flex-row">
  //     <button
  //       className="btn btn-default bg-trans border-0 shadow-none"
  //       onClick={decrementBg}
  //     >
  //       <i className="fa fa-chevron-left" />
  //     </button>
  //     <button className="btn btn-default bg-trans border-0 shadow-none">
  //       <i className="fa fa-chevron-right" onClick={incrementBg} />
  //     </button>
  //   </div>
  // </div>
};

export default BackgroundSwitcher;
