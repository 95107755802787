import React from "react";
import useStones from "../../../../hooks/useStones/useStones";
import FileUpload from "../../../common/FileUpload/FileUpload";
import ImagesPreview from "../../../common/FileUpload/ImagesPreview";

const StoneFilesManager = () => {
  const { stones_actions, stone, isLoading } = useStones();
  const {
    uploadStoneImages,
    deleteStoneImage,
    updateStoneMainImage,
    updateStoneThumbnailImage,
    moveStoneImagePosition,
  } = stones_actions;

  return (
    <div className="d-center bg-light flex-column rounded ">
      {stone.id ? (
        <>
          {stone.images.length ? (
            <div className="d-center w-100">
              <ImagesPreview
                className="mb-3"
                uniqId="stoneimgpreview"
                files={stone.images.sort((a, b) => (a.isMain ? -1 : 1))}
                deleteImage={(imageId) => deleteStoneImage({ imageId, stoneId: stone.id })}
                setMainImage={updateStoneMainImage}
                setThumbnail={updateStoneThumbnailImage}
                moveImagePosition={(oldIndex, newIndex) =>
                  moveStoneImagePosition({ oldIndex, newIndex })
                }
              />
            </div>
          ) : null}
          <FileUpload
            onFileDrop={(files) =>
              uploadStoneImages({
                files,
                stoneId: stone.id,
              })
            }
            isLoading={isLoading}
          />
        </>
      ) : (
        <p>Enregistrez la pierre avant de pouvoir envoyer des images</p>
      )}
    </div>
  );
};

export default StoneFilesManager;
