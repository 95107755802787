import React from "react";
import { Link } from "react-router-dom";
import useChakras from "../../../../hooks/useChakras/useChakras";
import Table from "../../../common/Table/Table";

const ChakrasListTable = () => {
  const { isLoading, chakrasSearch, languages, chakras_actions, state } = useChakras();

  return (
    <Table
      isLoading={isLoading}
      nbResults={chakrasSearch.count}
      datas={chakrasSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Sons",
          path: "sounds",
          render: (el) => {
            let { SOUNDS } = state.constants.items;
            return el.map((sound) => SOUNDS.find((s) => s.id == sound).name).join(", ");
          },
        },

        {
          name: "Page",
          path: "page",
          render: (el) => {
            let { PAGES } = state.constants.items;
            return PAGES.find((s) => s.id == el).name;
          },
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-default btn-sm mr-1" to={`/chakras/${el.id}`}>
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => chakras_actions.deleteChakra(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ChakrasListTable;
