import React, { useEffect } from "react";
import { userInitialState } from "../../../../context/reducers/userReducers";
import useStore from "../../../../context/useStore";
import useUsers from "../../../../hooks/useUsers/useUsers";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import Page from "../../../common/layout/Page";

const UsersDetail = (props) => {
  const { users_actions, user, usersErrors, isLoading, constants } =
    useUsers(props);
  const { updateUserAttribute, saveUser, getUser, updateUser } = users_actions;

  useEffect(() => {
    if (props && props?.match?.params?.id != "create") {
      getUser(props.match.params.id);
    } else {
      updateUser(userInitialState.user);
    }
  }, []);

  return (
    <Page
      title={user?.name?.FR || "Utilisateur"}
      back={`/users`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveUser} isLoading={isLoading}>
        <Control
          label="Nom"
          type="text"
          name="lastname"
          value={user.lastname}
          change={(event) => updateUserAttribute({ event })}
          error={usersErrors}
        />
        <Control
          label="Prénom"
          type="text"
          name="firstname"
          value={user.firstname}
          change={(event) => updateUserAttribute({ event })}
          error={usersErrors}
        />
        <Control
          label="Email"
          type="email"
          name="email"
          value={user.email}
          change={(event) => updateUserAttribute({ event })}
          error={usersErrors}
        />

        {!user.id && (
          <Control
            label="Mot de passe"
            type="password"
            name="password"
            value={user.password}
            change={(event) => updateUserAttribute({ event })}
            error={usersErrors}
          />
        )}
        <Control
          label="Rôle"
          type="btnList"
          datas={constants.MANAGER_ROLES}
          dataIndex="id"
          dataLabel="name"
          name="role"
          btnInline
          value={user.role}
          change={(event) => updateUserAttribute({ event })}
          error={usersErrors}
        />
      </FormContainer>
    </Page>
  );
};

export default UsersDetail;
