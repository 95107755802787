import tools from "../../helpers/tools";

export const userInitialState = {
  isLoading: false,
  users: [],
  usersFilters: {
    filters: { terms: "" },
    page: 1,
  },
  usersSearch: [],
  user: {
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    password: "",
  },
  errors: {},
};

export default function (state = userInitialState, action) {
  switch (action.type) {
    case "UPDATE_USERS_FILTERS":
      return {
        ...state,
        usersFilters: action.payload,
      };
    case "UPDATE_USERS_SEARCH":
      return {
        ...state,
        usersSearch: action.payload,
      };
    case "UPDATE_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case "UPDATE_USERS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_USERS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
