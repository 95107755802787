import React from "react";
import { Link } from "react-router-dom";
import usePurifications from "../../../../hooks/usePurifications/usePurifications";
import Table from "../../../common/Table/Table";

const PurificationsListTable = () => {
  const { isLoading, purificationsSearch, languages, purifications_actions } =
    usePurifications();

  return (
    <Table
      isLoading={isLoading}
      nbResults={purificationsSearch.count}
      datas={purificationsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/purifications/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() =>
                    purifications_actions.deletePurification(el.id)
                  }
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default PurificationsListTable;
