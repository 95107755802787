import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import "./theme/variables.scss";
import "./animate.css";
import "./vivify.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";
import { recaptcha_token } from "./config";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <React.StrictMode>
    <Store rootReducer={rootReducer}>
      <ErrorBoundary>
        <GoogleReCaptchaProvider reCaptchaKey={recaptcha_token}>
          <App />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </Store>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
