import React, { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import useTranslate from "../../../hooks/useTranslate/useTranslate";
//import useTranslate from "../../../hooks/useTranslate/useTranslate";
import Control from "../Controls/Control";
import LangBadge from "../LangBadge/LangBadge";
import Loader from "../Loader";

// example provider
let timeout = null;
const IntlInput = (props) => {
  const [state] = useStore();
  const { languages } = state;
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);

  const { translateTo } = useTranslate();
  const [translations, setTranslations] = useState({});

  const updateTranslations = async ({ terms, to }) => {
    setIsLoadingTranslation(true);
    const results = await translateTo({ terms, to });
    if (results) {
      setTranslations({
        ...translations,
        [to]: results,
      });
    }
    setIsLoadingTranslation(false);
  };

  return (
    <div className="row">
      {languages.languages.map((lang, langk) => {
        const errorName = `${props.name}.${lang}`;
        return (
          <div className="col">
            <Control
              {...props}
              key={`${props.name}${lang}`}
              label={
                <>
                  <LangBadge lang={lang} /> {props.label}
                </>
              }
              change={(event) => props.change({ event, lang })}
              value={props.value[lang]}
              error={
                props.error && props.error[errorName]
                  ? { [props.name]: props.error[errorName] }
                  : {}
              }
              info={
                lang != "FR" && (
                  <div className="d-flex flex-column align-items-end">
                    <button
                      className="btn btn-link btn-sm d-center"
                      type="button"
                      disabled={isLoadingTranslation}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateTranslations({ terms: props.value.FR, to: lang });
                      }}
                    >
                      {isLoadingTranslation ? (
                        <Loader className="mr-1" color="white" />
                      ) : (
                        <i className="fa fa-globe mr-1 " />
                      )}
                      FR
                      <i className="fa fa-arrow-right mx-1 " />
                      {lang}
                    </button>
                    {translations[lang] && (
                      <button
                        className="btn btn-default btn-sm"
                        type="button"
                        onClick={() =>
                          props.change({
                            event: {
                              target: {
                                name: props.name,
                                value: translations[lang],
                              },
                            },
                            lang,
                          })
                        }
                      >
                        <code>{translations[lang]}</code>
                      </button>
                    )}
                  </div>
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default IntlInput;
