import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const actionActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_ACTIONS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_ACTIONS_LOADING",
      payload: isLoading,
    });
  };
  const updateActionsFilters = (filters) => {
    dispatch({
      type: "UPDATE_ACTIONS_FILTERS",
      payload: filters,
    });
  };
  const updateActionsPage = (filters) => {
    dispatch({
      type: "UPDATE_ACTIONS_FILTERS",
      payload: filters,
    });
    searchActions(filters);
  };
  const searchActions = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `actions/search`,
      filters || state.actions.actionsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_ACTIONS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getAction = (actionId) => {
    updateIsLoading(true);
    dataService.get(`actions/${actionId}`, updateAction, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateAction = (action) => {
    dispatch({
      type: "UPDATE_ACTION",
      payload: action,
    });
  };
  const getActions = () => {
    updateIsLoading(true);
    dataService.get(`actions`, updateActions, updateErrors, () => updateIsLoading(false));
  };

  const updateActions = (actions) => {
    dispatch({
      type: "UPDATE_ACTIONS",
      payload: actions,
    });
  };
  const updateActionAttribute = ({ event, lang }) => {
    const { action } = state.actions;
    const { name, value } = event.target;
    let newAction = {};
    if (lang) {
      newAction = {
        ...action,
        [name]: {
          ...action[name],
          [lang]: value,
        },
      };
    } else {
      newAction = {
        ...action,
        [name]: value,
      };
    }
    console.log("newAction", newAction);
    updateAction(newAction);
  };

  const saveAction = () => {
    const { action } = state.actions;
    updateIsLoading(true);
    if (action.id) {
      dataService.patch(
        `actions/${action.id}`,
        { ...action, image: action.image?.id },
        (datas) => {
          updateAction(datas);
          updateErrors({});
          toast.success("L'action a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `actions`,
        action,
        (datas) => {
          updateAction(datas);
          updateErrors({});
          const { actions } = state.actions;
          updateActions([...actions, datas]);
          toast.success("L'action a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteAction = (actionId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `actions/${actionId}`,
      (datas) => searchActions(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const uploadActionImage = ({ file, actionId }) => {
    updateIsLoading(true);
    toast.info("L'envoi de l'image est en cours...");
    dataService.postFile(
      `actions/${actionId}/image`,
      file,
      () => getAction(actionId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const deleteActionImage = ({ actionId, imageId }) => {
    updateIsLoading(true);

    dataService.remove(
      `actions/${actionId}/image/${imageId}`,
      () => getAction(actionId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchActions,
    updateActionsFilters,
    updateAction,
    updateActionAttribute,
    saveAction,
    getAction,
    deleteAction,
    updateActionsPage,
    getActions,
    uploadActionImage,
    deleteActionImage,
  };
};
