import axios from "axios";
import fileDownload from "js-file-download";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import dataService from "../../../helpers/dataService";
import Loader from "../Loader";
import SaveBtn from "../SaveBtn/SaveBtn";
import ExistingFile from "./ExistingFile";
import ImagesPreview from "./ImagesPreview";

const FileUpload = ({
  noFixedHeight = false,
  onFileDrop,

  isLoading,
  updateAuthorized = () => {},
}) => {
  const [currentFiles, setCurrentFiles] = useState(null);
  const onDrop = (files) => {
    // Do something with the files
    const authorizedFormats = ["image/jpeg", "image/png"];
    console.log(files);
    if (files.length > 0) {
      let hasErrors = false;
      Array.from(files).forEach((file) => {
        if (!authorizedFormats.includes(file.type)) {
          toast.error(`${file.name} n'a pas le bon format (jpg, jpeg ou png)`);
          hasErrors = true;
        }
      });
      if (!hasErrors) {
        setCurrentFiles(files);
      }
    } else {
      alert("Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf.");
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const upldoadFile = () => {
    let data = new FormData();
    for (var i = 0; i < currentFiles.length; i++) {
      data.append("files", currentFiles[i]);
    }

    onFileDrop(data);
    cancelFile();
  };

  const cancelFile = () => {
    setCurrentFiles(null);
  };

  return (
    <>
      {currentFiles ? (
        <div className="d-flex align-items-center justify-content-between h-100 w-100 flex-column">
          <ImagesPreview files={currentFiles} />

          <div className="d-center justify-content-around w-100 flex-row flex-fill my-3">
            <button className="btn btn-light" onClick={cancelFile}>
              Annuler
            </button>
            <SaveBtn save={upldoadFile} type="success" saving={false} text={"Envoyer"} />
          </div>
        </div>
      ) : (
        <div
          className="d-center text-center p-3 w-100  rounded cursor-pointer"
          style={{
            height: noFixedHeight ? "auto" : "100%",
            border: "1px dashed grey",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Déposez le fichier ici ...</p>
          ) : (
            <div>
              Glissez-déposez des fichiers <br />
              ou cliquez pour parcourir vos dossiers
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FileUpload;
