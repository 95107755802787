import React, { useEffect } from "react";
import {
  unhappinessCategoryInitialState,
  unhappinessCategoryModel,
} from "../../../../context/reducers/unhappinessCategoryReducers";
import useStore from "../../../../context/useStore";
import useUnhappinessCategories from "../../../../hooks/useUnhappinessCategories/useUnhappinessCategories";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";

const UnhappinessCategoriesDetail = (props) => {
  const {
    state,
    unhappinessCategories_actions,
    unhappinessCategory,
    unhappinessCategoriesErrors,
    isLoading,
    constants,
  } = useUnhappinessCategories(props);
  const {
    updateUnhappinessCategoryAttribute,
    saveUnhappinessCategory,
    updateUnhappinessCategory,
    getUnhappinessCategory,
  } = unhappinessCategories_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getUnhappinessCategory(paramsId);
    } else {
      updateUnhappinessCategory(unhappinessCategoryModel());
    }
  }, []);

  return (
    <Page
      title={unhappinessCategory.name?.FR || "Catégorie de mal-être"}
      back={`/unhappiness-categories`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveUnhappinessCategory} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateUnhappinessCategoryAttribute}
          type="text"
          value={unhappinessCategory.name}
          error={unhappinessCategoriesErrors}
        />
        <Control
          label="Musique d'ambiance"
          name="music"
          value={unhappinessCategory.music}
          change={(event) => updateUnhappinessCategoryAttribute({ event })}
          type="select"
          datas={constants.MUSICS}
          dataIndex="id"
          dataLabel="name"
          error={unhappinessCategoriesErrors}
        />
      </FormContainer>
    </Page>
  );
};

export default UnhappinessCategoriesDetail;
