import tools from '../../helpers/tools';

let languagesObject = tools.languagesObject();

export const actionModel = () => ({
    name: languagesObject,
    image: null,
});

export const actionInitialState = {
    isLoading: false,
    actions: [],
    actionsFilters: {
        filters: { terms: '' },
        page: 1,
    },
    actionsSearch: [],
    action: actionModel(),
    errors: {},
};

export default function (state = actionInitialState, action) {
    switch (action.type) {
        case 'UPDATE_ACTIONS_FILTERS':
            return {
                ...state,
                actionsFilters: action.payload,
            };
        case 'UPDATE_ACTIONS_SEARCH':
            return {
                ...state,
                actionsSearch: action.payload,
            };
        case 'UPDATE_ACTIONS':
            return {
                ...state,
                actions: action.payload,
            };

        case 'UPDATE_ACTIONS_ERRORS':
            return {
                ...state,
                errors: action.payload,
            };

        case 'UPDATE_ACTIONS_LOADING':
            return {
                ...state,
                isLoading: action.payload,
                errors: action.payload ? {} : state.errors,
            };
        case 'UPDATE_ACTION':
            return {
                ...state,
                action: action.payload,
            };
        default:
            return state;
    }
}
