import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { unhappinessCategoryActions } from "../../../../context/actions/unhappinessCategoryActions";
import useUnhappinesses from "../../../../hooks/useUnhappinesses/useUnhappinesses";
import Table from "../../../common/Table/Table";

const UnhappinessesListTable = () => {
  const {
    state,
    dispatch,
    isLoading,
    unhappinessesSearch,
    languages,
    unhappinesses_actions,
  } = useUnhappinesses();

  return (
    <Table
      isLoading={isLoading}
      nbResults={unhappinessesSearch.count}
      datas={unhappinessesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Catégorie",
          path: "unhappinessCategory",
          render: (el) => {
            let { unhappinessCategories } = state.unhappinessCategories;
            return (
              <Link
                className="text-white d-block p-0"
                to={`/unhappiness-categories/${el}`}
              >
                {unhappinessCategories.find((s) => s.id == el)?.name.FR}
              </Link>
            );
          },
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/unhappinesses/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => unhappinesses_actions.deleteUnhappiness(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default UnhappinessesListTable;
