import React from "react";
import useUsers from "../../../../hooks/useUsers/useUsers";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const UsersFilters = () => {
  const {
    updateFilters,
    usersState,
    usersFilters,
    usersErrors,
    users_actions,
  } = useUsers();
  return (
    <FiltersContainer
      onSubmit={users_actions.searchUsers}
      filtersState={usersState}
      isDataLoaded={usersState.usersSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={usersFilters.terms}
            change={updateFilters}
            errors={usersErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default UsersFilters;
