import React from "react";
import useUnhappinessCategories from "../../../../hooks/useUnhappinessCategories/useUnhappinessCategories";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const UnhappinessCategoriesFilters = () => {
  const {
    updateFilters,
    unhappinessCategoriesState,
    unhappinessCategoriesFilters,
    unhappinessCategoriesErrors,
    unhappinessCategories_actions,
  } = useUnhappinessCategories();

  return (
    <FiltersContainer
      onSubmit={unhappinessCategories_actions.searchUnhappinessCategories}
      filtersState={unhappinessCategoriesState}
      isDataLoaded={
        unhappinessCategoriesState.unhappinessCategoriesSearch.data?.length
      }
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={unhappinessCategoriesFilters.terms}
            change={updateFilters}
            errors={unhappinessCategoriesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default UnhappinessCategoriesFilters;
