const colors = {
  primary: "#07629e",
  secondary: "#1F1F1F",
  tertiary: "#292c2f",
  success: "#017d0a",
  info: "#a2b1c7",
  warning: "#a56d02",
  dark: "#121212",
  danger: "#cc5764",
  light: "#232429",
  default: "#3b3b3b",
  trans: "rgba(0, 0, 0, 0)",
  input: "#a8a8a8",
  content: "#1d1d1d",
  white: "#9c9c9c",
};

export default colors;
