import { useEffect } from "react";
import { stepActions } from "../../context/actions/stepActions";
import useStore from "../../context/useStore";

const useSteps = (props) => {
  const [state, dispatch] = useStore();
  const steps_actions = stepActions(state, dispatch);
  const { updateStepsFilters, getStep, deleteStep } = steps_actions;
  const stepsState = state.steps;
  const stepsFilters = stepsState.stepsFilters;
  const stepsErrors = stepsState.errors;
  const { step, isLoading, stepsSearch } = stepsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = stepsState.stepsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateStepsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    steps_actions,
    stepsState,
    stepsSearch,
    updateFilters,
    stepsFilters,
    stepsErrors,
    step,
    isLoading,
    languages: state.languages.languages,
    deleteStep,
    constants: state.constants.items,
  };
};

export default useSteps;
