import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const reloadingModel = () => ({
  name: languagesObject,
  page: "",
  sounds: [],
});

export const reloadingInitialState = {
  isLoading: false,
  reloadings: [],
  reloadingsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  reloadingsSearch: [],
  reloading: reloadingModel(),
  errors: {},
};

export default function (state = reloadingInitialState, action) {
  switch (action.type) {
    case "UPDATE_RELOADINGS_FILTERS":
      return {
        ...state,
        reloadingsFilters: action.payload,
      };
    case "UPDATE_RELOADINGS_SEARCH":
      return {
        ...state,
        reloadingsSearch: action.payload,
      };
    case "UPDATE_RELOADINGS":
      return {
        ...state,
        reloadings: action.payload,
      };

    case "UPDATE_RELOADINGS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_RELOADINGS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_RELOADING":
      return {
        ...state,
        reloading: action.payload,
      };
    default:
      return state;
  }
}
