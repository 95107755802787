import React, { useEffect } from "react";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { colorActions } from "../../../../context/actions/colorActions";
import { crystalSystemActions } from "../../../../context/actions/crystalSystemActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { stoneCategoryActions } from "../../../../context/actions/stoneCategoryActions";
import useStones from "../../../../hooks/useStones/useStones";
import Page from "../../../common/layout/Page";
import useTopMenu from "../../../common/layout/TopMenu/useTopMenu";
import Pagination from "../../../common/Pagination/Pagination";
import StonesFilters from "./StonesFilters";
import StonesListTable from "./StonesListTable";

const StonesList = (props) => {
  const {
    stonesState,
    stonesSearch,
    stonesFilters,
    stones_actions,
    state,
    dispatch,
  } = useStones();

  const { getStoneCategories } = stoneCategoryActions(state, dispatch);
  const { getCrystalSystems } = crystalSystemActions(state, dispatch);
  const { getColors } = colorActions(state, dispatch);
  const { getChakras } = chakraActions(state, dispatch);
  const { getReloadings } = reloadingActions(state, dispatch);
  const { getPurifications } = purificationActions(state, dispatch);

  useEffect(() => {
    getStoneCategories();
    getCrystalSystems();
    getColors();
    getChakras();
    getReloadings();
    getPurifications();
  }, []);

  return (
    <Page
      title="Pierres"
      errors={stonesState.errors}
      isLoading={stonesState.isLoading}
      action={{
        to: "stones/create",
        text: "Créer une pierre",
      }}
      {...props}
    >
      <div className="">
        <StonesFilters />
        <div className="mt-3">
          <StonesListTable />
        </div>
        {stonesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(stonesSearch.count / 25)}
            page={stonesFilters.page}
            changePage={(p) =>
              stones_actions.updateStonesPage({
                ...stonesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default StonesList;
