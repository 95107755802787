import React, { useEffect } from "react";
import { chakraInitialState, chakraModel } from "../../../../context/reducers/chakraReducers";
import useStore from "../../../../context/useStore";
import useChakras from "../../../../hooks/useChakras/useChakras";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";

const ChakrasDetail = (props) => {
  const { state, chakras_actions, chakra, chakrasErrors, isLoading, constants } = useChakras(props);
  const { updateChakraAttribute, saveChakra, getChakra, updateChakra } = chakras_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getChakra(paramsId);
    } else {
      updateChakra(chakraModel());
    }
  }, []);

  return (
    <Page title={chakra?.name?.FR || "Chakra"} back={`/chakras`} isLoading={isLoading}>
      <FormContainer onSubmit={saveChakra} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateChakraAttribute}
          type="text"
          value={chakra.name}
          error={chakrasErrors}
        />
        <Control
          label="Page liée"
          name="page"
          value={chakra.page}
          change={(event) => updateChakraAttribute({ event })}
          type="select"
          datas={constants.PAGES}
          dataIndex="id"
          dataLabel="name"
          error={chakrasErrors}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Musique d'ambiance"
              name="music"
              value={chakra.music}
              change={(event) => updateChakraAttribute({ event })}
              type="btnList"
              btnInline
              datas={constants.MUSICS}
              dataIndex="id"
              dataLabel="name"
              error={chakrasErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Sons"
              name="sounds"
              value={chakra.sounds}
              change={(event) => updateChakraAttribute({ event })}
              btnLabel="Ajouter un son"
              field={{
                type: "select",
                name: "sound",
                datas: constants.SOUNDS,
              }}
              error={chakrasErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default ChakrasDetail;
