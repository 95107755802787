import { useEffect } from "react";
import { unhappinessSentenceActions } from "../../context/actions/unhappinessSentenceActions";
import useStore from "../../context/useStore";

const useUnhappinessSentences = (props) => {
  const [state, dispatch] = useStore();
  const unhappinessSentences_actions = unhappinessSentenceActions(
    state,
    dispatch
  );
  const {
    updateUnhappinessSentencesFilters,
    getUnhappinessSentence,
    deleteUnhappinessSentence,
    updateUnhappinessSentence,
  } = unhappinessSentences_actions;
  const unhappinessSentencesState = state.unhappinessSentences;
  const unhappinessSentencesFilters =
    unhappinessSentencesState.unhappinessSentencesFilters;
  const unhappinessSentencesErrors = unhappinessSentencesState.errors;
  const { unhappinessSentence, isLoading, unhappinessSentencesSearch } =
    unhappinessSentencesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = unhappinessSentencesState.unhappinessSentencesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateUnhappinessSentencesFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    unhappinessSentences_actions,
    unhappinessSentencesState,
    unhappinessSentencesSearch,
    updateFilters,
    unhappinessSentencesFilters,
    unhappinessSentencesErrors,
    unhappinessSentence,
    isLoading,
    languages: state.languages.languages,
    deleteUnhappinessSentence,
  };
};

export default useUnhappinessSentences;
