import React, { useEffect } from "react";
import { actionActions } from "../../../../context/actions/actionActions";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { colorActions } from "../../../../context/actions/colorActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { stepActions } from "../../../../context/actions/stepActions";
import { stoneActions } from "../../../../context/actions/stoneActions";
import { stepModel } from "../../../../context/reducers/stepReducers";
import useSteps from "../../../../hooks/useSteps/useSteps";
import Control from "../../../common/Controls/Control";
import FileUpload from "../../../common/FileUpload/FileUpload";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import Formula from "../../../common/Formula/Formula";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import ChakrasDetail from "../../Chakras/ChakrasDetail/ChakrasDetail";
import PurificationsDetail from "../../Purifications/PurificationsDetail/PurificationsDetail";
import ReloadingsDetail from "../../Reloadings/ReloadingsDetail/ReloadingsDetail";

const StepsDetail = (props) => {
  const { state, dispatch, steps_actions, step, stepsErrors, isLoading, constants } =
    useSteps(props);
  const { updateStepAttribute, saveStep, getStep, updateStep } = steps_actions;

  const { getStones } = stoneActions(state, dispatch);
  const { getChakras } = chakraActions(state, dispatch);
  const { getActions } = actionActions(state, dispatch);

  useEffect(() => {
    getStones();
    getChakras();
    getActions();

    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getStep(paramsId);
    } else {
      updateStep(stepModel());
    }
  }, []);

  return (
    <Page title={step.name?.FR || "Étape"} back={`/steps`} isLoading={isLoading}>
      <FormContainer onSubmit={saveStep} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 mb-3">
            <Control
              label="Action"
              name="action"
              change={(event) => updateStepAttribute({ event })}
              type="select"
              value={step.action}
              datas={state.actions.actions.map((sc) => ({
                ...sc,
                name: sc.name.FR,
              }))}
              dataIndex="id"
              dataLabel="name"
              error={stepsErrors}
            />
          </div>
          <div className="col-12">
            <SimpleDynamicList
              label="Pierres"
              name="stones"
              value={step.stones}
              change={(event) => updateStepAttribute({ event })}
              btnLabel="Ajouter une pierre"
              field={{
                type: "select",
                name: "stone",
                datas: state.stones.stones,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stepsErrors}
            />
          </div>
          <div className="col-12">
            <Control
              label="Chakra"
              name="chakra"
              change={(event) => updateStepAttribute({ event })}
              type="btnList"
              btnInline
              value={step.chakra}
              datas={state.chakras.chakras}
              dataIndex="id"
              dataLabel="name"
              dataLabelIndex="FR"
              error={stepsErrors}
              entityComponent={<ChakrasDetail />}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default StepsDetail;
