import useAssistant from "../../components/common/AIAssistant/useAssistant/useAssistant";

const useTranslate = () => {
  const { assistantActions } = useAssistant();
  const translateTo = async ({ terms, to }) => {
    const result = await assistantActions.onPromptTranslateAssistant(terms, to);

    return result;
  };

  return { translateTo };
};

export default useTranslate;
