import { useEffect, useState } from "react";

const AudioPreview = ({ file }) => {
  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    if (file) {
      setObjectUrl(file.url ? file.url : URL.createObjectURL(file));
    }
  }, [file]);

  return (
    <audio controls>
      <source src={objectUrl} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioPreview;
