import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const chakraModel = () => ({
  name: languagesObject,
  page: "",
  sounds: [],
  music: "",
});

export const chakraInitialState = {
  isLoading: false,
  chakras: [],
  chakrasFilters: {
    filters: { terms: "" },
    page: 1,
  },
  chakrasSearch: [],
  chakra: chakraModel(),
  errors: {},
};

export default function (state = chakraInitialState, action) {
  switch (action.type) {
    case "UPDATE_CHAKRAS_FILTERS":
      return {
        ...state,
        chakrasFilters: action.payload,
      };
    case "UPDATE_CHAKRAS_SEARCH":
      return {
        ...state,
        chakrasSearch: action.payload,
      };
    case "UPDATE_CHAKRAS":
      return {
        ...state,
        chakras: action.payload,
      };

    case "UPDATE_CHAKRAS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CHAKRAS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_CHAKRA":
      return {
        ...state,
        chakra: action.payload,
      };
    default:
      return state;
  }
}
