import React from "react";
import TextPromptForm from "./TextPromptForm";
import AssistantContainer from "../AssistantContainer/AssistantContainer";
import ImagePromptForm from "./ImagePromptForm";
import { PROMPTS, PROMPT_TYPES } from "../useAssistant/prompts.types";

const AssistantPromptForm = ({ promptModel }) => {
  const promptType = PROMPTS[promptModel].promptType;
  let component = null;
  switch (promptType) {
    case PROMPT_TYPES.TEXT:
      component = <TextPromptForm />;
      break;
    case PROMPT_TYPES.IMAGE:
      component = <ImagePromptForm />;
      break;
    default:
      component = <TextPromptForm />;
      break;
  }
  return <AssistantContainer>{component}</AssistantContainer>;
};

export default AssistantPromptForm;
