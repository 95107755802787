import React from "react";
import useCrystalSystems from "../../../../hooks/useCrystalSystems/useCrystalSystems";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import CrystalSystemsFilters from "./CrystalSystemsFilters";
import CrystalSystemsListTable from "./CrystalSystemsListTable";

const CrystalSystemsList = () => {
  const {
    crystalSystemsState,
    crystalSystemsSearch,
    crystalSystemsFilters,
    crystal_systems_actions,
  } = useCrystalSystems();

  return (
    <Page
      title="Systèmes cristallins"
      errors={crystalSystemsState.errors}
      isLoading={crystalSystemsState.isLoading}
      action={{
        to: "crystal-systems/create",
        text: "Créer un système cristallin",
      }}
    >
      <div className="">
        <CrystalSystemsFilters />
        <div className="mt-3">
          <CrystalSystemsListTable />
        </div>
        {crystalSystemsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(crystalSystemsSearch.count / 25)}
            page={crystalSystemsFilters.page}
            changePage={(p) =>
              crystal_systems_actions.updateCrystalSystemsPage({
                ...crystalSystemsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default CrystalSystemsList;
