import React from "react";
import useReloadings from "../../../../hooks/useReloadings/useReloadings";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import ReloadingsFilters from "./ReloadingsFilters";
import ReloadingsListTable from "./ReloadingsListTable";

const ReloadingsList = () => {
  const {
    reloadingsState,
    reloadingsSearch,
    reloadingsFilters,
    reloadings_actions,
  } = useReloadings();

  return (
    <Page
      title="Rechargements"
      errors={reloadingsState.errors}
      isLoading={reloadingsState.isLoading}
      action={{
        to: "reloadings/create",
        text: "Créer un rechargement",
      }}
    >
      <div className="">
        <ReloadingsFilters />
        <div className="mt-3">
          <ReloadingsListTable />
        </div>
        {reloadingsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(reloadingsSearch.count / 25)}
            page={reloadingsFilters.page}
            changePage={(p) =>
              reloadings_actions.updateReloadingsPage({
                ...reloadingsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ReloadingsList;
