import React, { useEffect } from "react";
import { aspectActions } from "../../../../context/actions/aspectActions";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { colorActions } from "../../../../context/actions/colorActions";
import { crystalSystemActions } from "../../../../context/actions/crystalSystemActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { stoneCategoryActions } from "../../../../context/actions/stoneCategoryActions";
import { stoneModel } from "../../../../context/reducers/stoneReducers";
import useStones from "../../../../hooks/useStones/useStones";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import Formula from "../../../common/Formula/Formula";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import ChakrasDetail from "../../Chakras/ChakrasDetail/ChakrasDetail";
import ColorsDetail from "../../Colors/ColorsDetail/ColorsDetail";
import CrystalSystemsDetail from "../../CrystalSystems/CrystalSystemsDetail/CrystalSystemsDetail";
import PurificationsDetail from "../../Purifications/PurificationsDetail/PurificationsDetail";
import ReloadingsDetail from "../../Reloadings/ReloadingsDetail/ReloadingsDetail";
import StoneCategoriesDetail from "../../StoneCategories/StoneCategoriesDetail/StoneCategoriesDetail";
import Anecdotes from "./Anecdotes/Anecdotes";
import StoneAudioManager from "./StoneAudioManager";
import StoneFilesManager from "./StoneFilesManager";
import OpenAssistantButton from "../../../common/AIAssistant/OpenAssistantButton/OpenAssistantButton";
import {
  IMAGE_SIZES,
  PROMPT_MODELS,
} from "../../../common/AIAssistant/useAssistant/prompts.types";

const StonesDetail = (props) => {
  const { state, dispatch, stones_actions, stone, stonesErrors, isLoading } =
    useStones(props);
  const {
    updateStoneAttribute,
    saveStone,
    getStone,
    updateStone,
    updateStoneAudioAttribute,
  } = stones_actions;

  const { getStoneCategories } = stoneCategoryActions(state, dispatch);
  const { getCrystalSystems } = crystalSystemActions(state, dispatch);
  const { getColors } = colorActions(state, dispatch);
  const { getChakras } = chakraActions(state, dispatch);
  const { getReloadings } = reloadingActions(state, dispatch);
  const { getPurifications } = purificationActions(state, dispatch);
  const { getAspects } = aspectActions(state, dispatch);

  useEffect(() => {
    getStoneCategories();
    getCrystalSystems();
    getColors();
    getChakras();
    getReloadings();
    getPurifications();
    getAspects();

    if (props?.match?.params?.id != "create") {
      getStone(props.match.params.id);
    } else {
      updateStone(stoneModel());
    }
  }, []);

  console.log("stonesErrors", stonesErrors);

  console.log("Stone: ", stone);

  return (
    <Page
      title={stone.name?.FR || "Pierre"}
      back={`/stones`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveStone} isLoading={isLoading}>
        <div className="row">
          <div className="col-12">
            <Control
              label="Rendre cette pierre visible sur l'application mobile"
              type="checkbox"
              checked={stone.visible}
              name="visible"
              change={(e) =>
                updateStoneAttribute({
                  event: {
                    target: { name: "visible", value: e.target.checked },
                  },
                })
              }
              error={stonesErrors}
              // setError={setErrors}
            />
          </div>
          <div className="col-12 ">
            <IntlInput
              label="Nom"
              name="name"
              change={updateStoneAttribute}
              type="text"
              value={stone.name}
              error={stonesErrors}
            />
            <IntlInput
              label="Description"
              name="description"
              change={updateStoneAttribute}
              type="textarea"
              value={stone.description}
              error={stonesErrors}
            />
            <div className="row">
              <div className="col-12 col-md-4">
                <OpenAssistantButton
                  promptModel={PROMPT_MODELS.STONE_DESCRIPTION}
                  modelParams={{
                    stone: stone.name.FR,
                    lang: "FR",
                  }}
                  text="Suggérer une description"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <Control
                  label="Catégorie de pierre"
                  name="stoneCategory"
                  change={(event) => updateStoneAttribute({ event })}
                  type="autocomplete"
                  autoCompleteDisplayIndex="name"
                  autoCompleteDisplayIndexSub="FR"
                  value={stone.stoneCategory}
                  datas={state.stoneCategories.stoneCategories.map((sc) => ({
                    ...sc,
                    name: sc.name.FR,
                  }))}
                  dataIndex="id"
                  dataLabel="name"
                  dataLabelIndex="FR"
                  error={stonesErrors}
                  entityComponent={<StoneCategoriesDetail />}
                />
              </div>
              <div className="col-12 col-md-6">
                <Control
                  label="Système cristallin"
                  name="crystalSystem"
                  change={(event) => updateStoneAttribute({ event })}
                  type="btnList"
                  btnInline
                  value={stone.crystalSystem}
                  datas={state.crystalSystems.crystalSystems}
                  dataIndex="id"
                  dataLabel="name"
                  dataLabelIndex="FR"
                  error={stonesErrors}
                  entityComponent={<CrystalSystemsDetail />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Formule"
              name="formula"
              change={(event) => updateStoneAttribute({ event })}
              type="text"
              value={stone.formula}
              error={stonesErrors}
              suffix={<Formula text={stone.formula} />}
              hideEmptyBtn
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Échelle de Mohs"
              name="mohsScale"
              change={(event) => updateStoneAttribute({ event })}
              type="range"
              inputParams={{ min: 0, max: 10, step: 0.1 }}
              value={stone.mohsScale}
              error={stonesErrors}
              inputGroupClassname={"bg-trans"}
            />
          </div>

          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Couleurs"
              name="colors"
              value={stone.colors}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter une couleur"
              field={{
                type: "btnList",
                btnInline: true,
                name: "color",
                datas: state.colors.colors,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stonesErrors}
              entityComponent={<ColorsDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Aspect"
              name="aspects"
              value={stone.aspects}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter un aspect"
              field={{
                type: "btnList",
                btnInline: true,
                name: "name",
                datas: state.aspects.aspects,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stonesErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Chakras"
              name="chakras"
              value={stone.chakras}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter un chakra"
              field={{
                type: "btnList",
                btnInline: true,
                name: "chakra",
                datas: state.chakras.chakras,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stonesErrors}
              entityComponent={<ChakrasDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Rechargements"
              name="reloadings"
              value={stone.reloadings}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter un rechargement"
              field={{
                type: "btnList",
                btnInline: true,
                name: "reloading",
                datas: state.reloadings.reloadings,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stonesErrors}
              entityComponent={<ReloadingsDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Purifications"
              name="purifications"
              value={stone.purifications}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter une purification"
              field={{
                type: "btnList",
                btnInline: true,
                name: "reloading",
                datas: state.purifications.purifications,
                dataIndex: "id",
                dataLabel: "name",
                dataLabelIndex: "FR",
              }}
              error={stonesErrors}
              entityComponent={<PurificationsDetail />}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Signes astrologiques"
              name="astrologicalSigns"
              value={stone.astrologicalSigns}
              change={(event) => updateStoneAttribute({ event })}
              btnLabel="Ajouter un signe Astrologique"
              field={{
                type: "btnList",
                btnInline: true,
                name: "astrologicalSign",
                datas: state.constants.items.ASTROLOGICAL_SIGNS,
                dataIndex: "id",
                dataLabel: "name",
              }}
              error={stonesErrors}
            />
          </div>
          <div className="col-12">
            <Anecdotes
              anecdotes={stone.anecdotes}
              setAnecdotes={(anecdotes) =>
                updateStoneAttribute({
                  event: { target: { name: "anecdotes", value: anecdotes } },
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12  py-0 my-3">
            <h2
              className="rounded-pill mb-3 border-default text-white text-center"
              style={{ lineHeight: "40px", fontSize: 27 }}
            >
              IMAGES
            </h2>
            <div className="my-2">
              <OpenAssistantButton
                promptModel={PROMPT_MODELS.STONE_IMAGE}
                modelParams={{
                  stone: stone.name.EN,
                  lang: "EN",
                  nbImages: 1,
                  imageSize: IMAGE_SIZES.MEDIUM,
                }}
                text="Suggérer des images"
              />
            </div>
            <StoneFilesManager />
          </div>
          <div className="col-12  py-0 my-3">
            <h2
              className="rounded-pill mb-3 border-default text-white text-center"
              style={{ lineHeight: "40px", fontSize: 27 }}
            >
              AUDIO DESCRIPTION
            </h2>
            {stone.audioDescription?.file && (
              <>
                <Control
                  label="Nom du narrateur"
                  name="readBy"
                  change={(event) => updateStoneAudioAttribute({ event })}
                  type="text"
                  value={stone.audioDescription?.readBy || ""}
                  error={stonesErrors}
                  hideEmptyBtn
                />
                <IntlInput
                  label="Description du fichier"
                  name="audioName"
                  change={updateStoneAudioAttribute}
                  type="text"
                  value={stone.audioDescription.audioName || {}}
                  error={stonesErrors}
                />
              </>
            )}
          </div>
          <div className="col-12  py-0 my-3">
            <StoneAudioManager />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default StonesDetail;
