import React, { useEffect } from "react";
import {
  crystalSystemInitialState,
  crystalSystemModel,
} from "../../../../context/reducers/crystalSystemReducers";
import useStore from "../../../../context/useStore";
import useCrystalSystems from "../../../../hooks/useCrystalSystems/useCrystalSystems";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";

const CrystalSystemsDetail = (props) => {
  const {
    state,
    crystal_systems_actions,
    crystalSystem,
    crystalSystemsErrors,
    isLoading,
  } = useCrystalSystems(props);
  const {
    updateCrystalSystemAttribute,
    saveCrystalSystem,
    getCrystalSystem,
    updateCrystalSystem,
  } = crystal_systems_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getCrystalSystem(props.match.params.id);
    } else {
      updateCrystalSystem(crystalSystemModel());
    }
  }, []);

  return (
    <Page
      title={crystalSystem.name?.FR || "Système Cristallin"}
      back={`/crystal-systems`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveCrystalSystem} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateCrystalSystemAttribute}
          type="text"
          value={crystalSystem.name}
          error={crystalSystemsErrors}
        />
      </FormContainer>
    </Page>
  );
};

export default CrystalSystemsDetail;
