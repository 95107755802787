import { useEffect } from "react";
import { purificationActions } from "../../context/actions/purificationActions";
import useStore from "../../context/useStore";

const usePurifications = (props) => {
  const [state, dispatch] = useStore();
  const purifications_actions = purificationActions(state, dispatch);
  const { updatePurificationsFilters, getPurification, deletePurification } =
    purifications_actions;
  const purificationsState = state.purifications;
  const purificationsFilters = purificationsState.purificationsFilters;
  const purificationsErrors = purificationsState.errors;
  const { purification, isLoading, purificationsSearch } = purificationsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = purificationsState.purificationsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updatePurificationsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    purifications_actions,
    purificationsState,
    purificationsSearch,
    updateFilters,
    purificationsFilters,
    purificationsErrors,
    purification,
    isLoading,
    languages: state.languages.languages,
    deletePurification,
  };
};

export default usePurifications;
