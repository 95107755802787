import authReducer from "./reducers/authReducers";
import errorReducer from "./reducers/errorReducers";
import constantReducer from "./reducers/constantReducers";
import modalReducer from "./reducers/modalReducers";
import rightPanelReducer from "./reducers/rightPanelReducers";
import leftPanelReducer from "./reducers/leftPanelReducers";
import stoneCategoryReducers from "./reducers/stoneCategoryReducers";
import crystalSystemReducers from "./reducers/crystalSystemReducers";
import languageReducers from "./reducers/languageReducers";
import soundReducers from "./reducers/soundReducers";
import colorReducers from "./reducers/colorReducers";
import chakraReducers from "./reducers/chakraReducers";
import reloadingReducers from "./reducers/reloadingReducers";
import purificationReducers from "./reducers/purificationReducers";
import unhappinessCategoryReducers from "./reducers/unhappinessCategoryReducers";
import unhappinessSentenceReducers from "./reducers/unhappinessSentenceReducers";
import stoneReducers from "./reducers/stoneReducers";
import stepReducers from "./reducers/stepReducers";
import remedyReducers from "./reducers/remedyReducers";
import userReducers from "./reducers/userReducers";
import unhappinessReducers from "./reducers/unhappinessReducers";
import actionReducers from "./reducers/actionReducers";
import aspectReducers from "./reducers/aspectReducers";

function combineReducers(reducers) {
  // First get an array with all the keys of the reducers (the reducer names)
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    // This is the object we are going to return.
    const nextState = {};

    // Loop through all the reducer keys
    for (let i = 0; i < reducerKeys.length; i++) {
      // Get the current key name
      const key = reducerKeys[i];
      // Get the current reducer
      const reducer = reducers[key];
      // Get the the previous state
      const previousStateForKey = state[key];
      // Get the next state by running the reducer
      const nextStateForKey = reducer(previousStateForKey, action);
      // Update the new state for the current reducer
      nextState[key] = nextStateForKey;
    }
    return nextState;
  };
}

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  constants: constantReducer,
  modal: modalReducer,
  rightPanel: rightPanelReducer,
  leftPanel: leftPanelReducer,
  stoneCategories: stoneCategoryReducers,
  crystalSystems: crystalSystemReducers,
  languages: languageReducers,
  sounds: soundReducers,
  colors: colorReducers,
  chakras: chakraReducers,
  reloadings: reloadingReducers,
  purifications: purificationReducers,
  unhappinessCategories: unhappinessCategoryReducers,
  unhappinessSentences: unhappinessSentenceReducers,
  stones: stoneReducers,
  remedies: remedyReducers,
  steps: stepReducers,
  actions: actionReducers,
  users: userReducers,
  unhappinesses: unhappinessReducers,
  aspects: aspectReducers,
});
