import { useEffect } from "react";
import { stoneActions } from "../../context/actions/stoneActions";
import useStore from "../../context/useStore";

const useStones = (props) => {
  const [state, dispatch] = useStore();
  const stones_actions = stoneActions(state, dispatch);
  const { updateStonesFilters, getStone, deleteStone } = stones_actions;
  const stonesState = state.stones;
  const stonesFilters = stonesState.stonesFilters;
  const stonesErrors = stonesState.errors;
  const { stone, isLoading, stonesSearch } = stonesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = stonesState.stonesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateStonesFilters(newFilters);
  };

  useEffect(() => {
    if (props && props?.match?.params?.id != "create") {
      getStone(props.match.params.id);
    }
  }, []);

  return {
    state,
    dispatch,
    stones_actions,
    stonesState,
    stonesSearch,
    updateFilters,
    stonesFilters,
    stonesErrors,
    stone,
    isLoading,
    languages: state.languages.languages,
    deleteStone,
    constants: state.constants.items,
  };
};

export default useStones;
