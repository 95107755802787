import { useEffect } from "react";
import { colorActions } from "../../context/actions/colorActions";
import useStore from "../../context/useStore";

const useColors = (props) => {
  const [state, dispatch] = useStore();
  const colors_actions = colorActions(state, dispatch);
  const { updateColorsFilters, getColor, deleteColor } = colors_actions;
  const colorsState = state.colors;
  const colorsFilters = colorsState.colorsFilters;
  const colorsErrors = colorsState.errors;
  const { color, isLoading, colorsSearch } = colorsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = colorsState.colorsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateColorsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    colors_actions,
    colorsState,
    colorsSearch,
    updateFilters,
    colorsFilters,
    colorsErrors,
    color,
    isLoading,
    languages: state.languages.languages,
    deleteColor,
  };
};

export default useColors;
