import { toast } from 'react-toastify';
import dataService from '../../helpers/dataService';

export const userActions = (state, dispatch, props) => {
    const updateErrors = (errors) => {
        dispatch({
            type: 'UPDATE_USERS_ERRORS',
            payload: errors,
        });
    };

    const updateIsLoading = (isLoading) => {
        dispatch({
            type: 'UPDATE_USERS_LOADING',
            payload: isLoading,
        });
    };
    const updateUsersFilters = (filters) => {
        dispatch({
            type: 'UPDATE_USERS_FILTERS',
            payload: filters,
        });
    };
    const updateUsersPage = (filters) => {
        dispatch({
            type: 'UPDATE_USERS_FILTERS',
            payload: filters,
        });
        searchUsers(filters);
    };
    const searchUsers = (filters = null) => {
        updateIsLoading(true);
        dataService.post(
            `managers/search`,
            filters || state.users.usersFilters,
            (data) => {
                dispatch({
                    type: 'UPDATE_USERS_SEARCH',
                    payload: data,
                });
            },
            updateErrors,
            () => updateIsLoading(false)
        );
    };
    const getUser = (userId) => {
        updateIsLoading(true);
        dataService.get(`managers/${userId}`, updateUser, updateErrors, () =>
            updateIsLoading(false)
        );
    };
    const getUsers = () => {
        updateIsLoading(true);
        dataService.get(`managers`, updateUsers, updateErrors, () => updateIsLoading(false));
    };

    const updateUser = (user) => {
        dispatch({
            type: 'UPDATE_USER',
            payload: user,
        });
    };
    const updateUsers = (users) => {
        dispatch({
            type: 'UPDATE_USERS',
            payload: users,
        });
    };
    const updateUserAttribute = ({ event, lang }) => {
        console.log('EV', event, lang);
        const { user } = state.users;
        const { name, value } = event.target;
        let newUser = {};
        if (lang) {
            newUser = {
                ...user,
                [name]: {
                    ...user[name],
                    [lang]: value,
                },
            };
        } else {
            newUser = {
                ...user,
                [name]: value,
            };
        }
        console.log('newUser', newUser);
        updateUser(newUser);
    };

    const saveUser = () => {
        const { user } = state.users;
        updateIsLoading(true);
        if (user.id) {
            dataService.patch(
                `managers/${user.id}`,
                user,
                (datas) => {
                    updateUser(datas);
                    updateErrors({});
                    toast.success('Le user a bien été mis à jour');
                },
                updateErrors,
                () => updateIsLoading(false)
            );
        } else {
            dataService.post(
                `managers`,
                user,
                (datas) => {
                    updateUser(datas);
                    updateErrors({});

                    toast.success('Le user a bien été créé');
                },
                updateErrors,
                () => updateIsLoading(false)
            );
        }
    };

    const deleteUser = (userId) => {
        if (!window.confirm('Supprimer cette entrée ?')) return false;
        updateIsLoading(true);

        dataService.remove(
            `managers/${userId}`,
            (datas) => searchUsers(),
            updateErrors,
            () => updateIsLoading(false)
        );
    };

    return {
        updateIsLoading,
        searchUsers,
        updateUsersFilters,
        updateUser,
        updateUserAttribute,
        saveUser,
        getUser,
        deleteUser,
        getUsers,
        updateUsers,
        updateUsersPage,
    };
};
