import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import useUsers from "../../../../hooks/useUsers/useUsers";
import Table from "../../../common/Table/Table";

const UsersListTable = () => {
  const { isLoading, usersSearch, languages, users_actions, state } =
    useUsers();
  const constants = state.constants.items;

  return (
    <Table
      isLoading={isLoading}
      nbResults={usersSearch.count}
      datas={usersSearch.data}
      fields={[
        {
          name: "Nom",
          path: "*",
          render: (el) => tools.getFullName(el),
        },
        {
          name: "Rôle",
          path: "role",
          render: (el) => constants.MANAGER_ROLES.find((r) => r.id == el).name,
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/users/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => users_actions.deleteUser(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default UsersListTable;
