import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const unhappinessActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_UNHAPPINESSES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_UNHAPPINESSES_LOADING",
      payload: isLoading,
    });
  };
  const updateUnhappinessesPage = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESSES_FILTERS",
      payload: filters,
    });
    searchUnhappinesses(filters);
  };
  const updateUnhappinessesFilters = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESSES_FILTERS",
      payload: filters,
    });
  };
  const searchUnhappinesses = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `unhappinesses/search`,
      filters || state.unhappinesses.unhappinessesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_UNHAPPINESSES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getUnhappiness = (unhappinessId) => {
    updateIsLoading(true);
    dataService.get(
      `unhappinesses/${unhappinessId}`,
      updateUnhappiness,
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getUnhappinesses = () => {
    updateIsLoading(true);
    dataService.get(`unhappinesses`, updateUnhappinesses, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateUnhappiness = (unhappiness) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEM",
      payload: unhappiness,
    });
  };
  const updateUnhappinesses = (unhappinesses) => {
    dispatch({
      type: "UPDATE_UNHAPPINESSES",
      payload: unhappinesses,
    });
  };
  const updateUnhappinessAttribute = ({ event, lang }) => {
    console.log("updateUnhappinessAttribute", event, lang);
    const { unhappiness } = state.unhappinesses;
    const { name, value } = event.target;
    let newUnhappiness = {};
    if (lang) {
      newUnhappiness = {
        ...unhappiness,
        [name]: {
          ...unhappiness[name],
          [lang]: value,
        },
      };
    } else {
      newUnhappiness = { ...unhappiness, [name]: value };
    }
    console.log("newUnhappiness", newUnhappiness);
    updateUnhappiness(newUnhappiness);
  };

  const saveUnhappiness = () => {
    const { unhappiness } = state.unhappinesses;
    updateIsLoading(true);
    if (unhappiness.id) {
      dataService.patch(
        `unhappinesses/${unhappiness.id}`,
        unhappiness,
        (datas) => {
          updateUnhappiness(datas);
          updateErrors({});
          toast.success("Le mal-être a bien été miseà jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `unhappinesses`,
        unhappiness,
        (datas) => {
          updateUnhappiness(datas);
          updateErrors({});

          toast.success("Le mal-être a bien été créé");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteUnhappiness = (unhappinessId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `unhappinesses/${unhappinessId}`,
      (datas) => searchUnhappinesses(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchUnhappinesses,
    updateUnhappinessesFilters,
    updateUnhappiness,
    updateUnhappinessAttribute,
    saveUnhappiness,
    getUnhappiness,
    deleteUnhappiness,
    getUnhappinesses,
    updateUnhappinesses,
    updateUnhappinessesPage,
  };
};
