import { AnimatePresence } from "framer-motion";
import { Switch, useLocation } from "react-router-dom";
import PrivateRoute from "../../components/common/PrivateRoute";
import ChakrasDetail from "../../components/pages/Chakras/ChakrasDetail/ChakrasDetail";
import ChakrasList from "../../components/pages/Chakras/ChakrasList/ChakrasList";
import ColorsDetail from "../../components/pages/Colors/ColorsDetail/ColorsDetail";
import ColorsList from "../../components/pages/Colors/ColorsList/ColorsList";
import CrystalSystemsDetail from "../../components/pages/CrystalSystems/CrystalSystemsDetail/CrystalSystemsDetail";
import CrystalSystemsList from "../../components/pages/CrystalSystems/CrystalSystemsList.js/CrystalSystemsList";
import Dashboard from "../../components/pages/Dashboard/Dashboard";
import Profile from "../../components/pages/Profile/Profile";
import PurificationsDetail from "../../components/pages/Purifications/PurificationsDetail/PurificationsDetail";
import PurificationsList from "../../components/pages/Purifications/PurificationsList/PurificationsList";
import ReloadingsDetail from "../../components/pages/Reloadings/ReloadingsDetail/ReloadingsDetail";
import ReloadingsList from "../../components/pages/Reloadings/ReloadingsList/ReloadingsList";
import StoneCategoriesDetail from "../../components/pages/StoneCategories/StoneCategoriesDetail/StoneCategoriesDetail";
import StoneCategoriesList from "../../components/pages/StoneCategories/StoneCategoriesList.js/StoneCategoriesList";
import StonesDetail from "../../components/pages/Stones/StonesDetail/StonesDetail";
import StonesList from "../../components/pages/Stones/StonesList/StonesList";
import UnhappinessCategoriesDetail from "../../components/pages/UnhappinessCategories/UnhappinessCategoriesDetail/UnhappinessCategoriesDetail";
import UnhappinessCategoriesList from "../../components/pages/UnhappinessCategories/UnhappinessCategoriesList/UnhappinessCategoriesList";
import UnhappinessSentencesDetail from "../../components/pages/UnhappinessSentences/UnhappinessSentencesDetail/UnhappinessSentencesDetail";
import UnhappinessSentencesList from "../../components/pages/UnhappinessSentences/UnhappinessSentencesList/UnhappinessSentencesList";
import UsersList from "../../components/pages/Users/UsersList/UsersList";
import UsersDetail from "../../components/pages/Users/UsersDetail/UsersDetail";
import RemediesDetail from "../../components/pages/Remedies/RemediesDetail/RemediesDetail";
import RemediesList from "../../components/pages/Remedies/RemediesList/RemediesList";
import StepsList from "../../components/pages/Steps/StepsList/StepsList";
import StepsDetail from "../../components/pages/Steps/StepsDetail/StepsDetail";
import UnhappinessesList from "../../components/pages/Unhappinesses/UnhappinessesList/UnhappinessesList";
import UnhappinessesDetail from "../../components/pages/Unhappinesses/UnhappinessesDetail/UnhappinessesDetail";
import ActionsList from "../../components/pages/Actions/ActionsList/ActionsList";
import ActionsDetail from "../../components/pages/Actions/ActionsDetail/ActionsDetail";
import AspectsList from "../../components/pages/Aspects/AspectsList/AspectsList";
import AspectsDetail from "../../components/pages/Aspects/AspectsDetail/AspectsDetail";

const PrivateRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/profile" exact component={Profile} />
        <PrivateRoute path="/users" exact component={UsersList} />
        <PrivateRoute path="/users/:id" exact component={UsersDetail} />
        <PrivateRoute
          path="/stone-categories"
          exact
          component={StoneCategoriesList}
        />
        <PrivateRoute
          path="/stone-categories/:id"
          exact
          component={StoneCategoriesDetail}
        />
        <PrivateRoute
          path="/crystal-systems"
          exact
          component={CrystalSystemsList}
        />
        <PrivateRoute
          path="/crystal-systems/:id"
          exact
          component={CrystalSystemsDetail}
        />

        <PrivateRoute path="/colors" exact component={ColorsList} />
        <PrivateRoute path="/colors/:id" exact component={ColorsDetail} />
        <PrivateRoute path="/chakras" exact component={ChakrasList} />
        <PrivateRoute path="/aspects" exact component={AspectsList} />
        <PrivateRoute path="/aspects/:id" exact component={AspectsDetail} />
        <PrivateRoute path="/chakras" exact component={ChakrasList} />
        <PrivateRoute path="/chakras/:id" exact component={ChakrasDetail} />
        <PrivateRoute path="/reloadings" exact component={ReloadingsList} />
        <PrivateRoute
          path="/reloadings/:id"
          exact
          component={ReloadingsDetail}
        />
        <PrivateRoute
          path="/purifications"
          exact
          component={PurificationsList}
        />
        <PrivateRoute
          path="/purifications/:id"
          exact
          component={PurificationsDetail}
        />
        <PrivateRoute
          path="/unhappiness-categories"
          exact
          component={UnhappinessCategoriesList}
        />
        <PrivateRoute
          path="/unhappiness-categories/:id"
          exact
          component={UnhappinessCategoriesDetail}
        />
        <PrivateRoute
          path="/unhappiness-sentences"
          exact
          component={UnhappinessSentencesList}
        />
        <PrivateRoute
          path="/unhappiness-sentences/:id"
          exact
          component={UnhappinessSentencesDetail}
        />
        <PrivateRoute path="/remedies" exact component={RemediesList} />
        <PrivateRoute path="/remedies/:id" exact component={RemediesDetail} />
        <PrivateRoute path="/steps" exact component={StepsList} />
        <PrivateRoute path="/steps/:id" exact component={StepsDetail} />
        <PrivateRoute path="/actions" exact component={ActionsList} />
        <PrivateRoute path="/actions/:id" exact component={ActionsDetail} />
        <PrivateRoute path="/stones" exact component={StonesList} />
        <PrivateRoute path="/stones/:id" exact component={StonesDetail} />
        <PrivateRoute
          path="/unhappinesses"
          exact
          component={UnhappinessesList}
        />
        <PrivateRoute
          path="/unhappinesses/:id"
          exact
          component={UnhappinessesDetail}
        />
      </Switch>
    </AnimatePresence>
  );
};

export default PrivateRoutes;
