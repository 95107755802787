import React, { useEffect } from "react";
import { actionActions } from "../../../../context/actions/actionActions";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { stoneActions } from "../../../../context/actions/stoneActions";
import useSteps from "../../../../hooks/useSteps/useSteps";
import Page from "../../../common/layout/Page";
import useTopMenu from "../../../common/layout/TopMenu/useTopMenu";
import Pagination from "../../../common/Pagination/Pagination";
import StepsFilters from "./StepsFilters";
import StepsListTable from "./StepsListTable";

const StepsList = (props) => {
  const { stepsState, stepsSearch, stepsFilters, steps_actions, state, dispatch } = useSteps();

  const { getStones } = stoneActions(state, dispatch);
  const { getChakras } = chakraActions(state, dispatch);
  const { getActions } = actionActions(state, dispatch);

  useEffect(() => {
    getStones();
    getChakras();
    getActions();
  }, []);

  return (
    <Page
      title="Étape"
      errors={stepsState.errors}
      isLoading={stepsState.isLoading}
      action={{
        to: "steps/create",
        text: "Créer une étape",
      }}
      {...props}
    >
      <div className="">
        <StepsFilters />
        <div className="mt-3">
          <StepsListTable />
        </div>
        {stepsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(stepsSearch.count / 25)}
            page={stepsFilters.page}
            changePage={(p) =>
              steps_actions.updateStepsPage({
                ...stepsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default StepsList;
