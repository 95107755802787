const roleConfig = {
  ADMIN: [],
  SUPER_ADMIN: [],
  COLLABORATOR: [
    "view_payments", //voir le panel paiements
    "create_projet", //voir le formulaire de création du projet + client
    "create_user", //créer un utilisateur BOA
    "create_needs_form", //créer/enregistrer des form de besoin
    "view_quote", //voir le panel devis
  ],
};

export default roleConfig;
