import { useEffect } from "react";
import { stoneCategoryActions } from "../../context/actions/stoneCategoryActions";
import useStore from "../../context/useStore";

const useStoneCategories = (props) => {
  const [state, dispatch] = useStore();
  const stone_categories_actions = stoneCategoryActions(state, dispatch);
  const {
    updateStoneCategoriesFilters,
    getStoneCategory,
    deleteStoneCategory,
  } = stone_categories_actions;
  const stoneCategoriesState = state.stoneCategories;
  const stoneCategoriesFilters = stoneCategoriesState.stoneCategoriesFilters;
  const stoneCategoriesErrors = stoneCategoriesState.errors;
  const { stoneCategory, isLoading, stoneCategoriesSearch } =
    stoneCategoriesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = stoneCategoriesState.stoneCategoriesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateStoneCategoriesFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    stone_categories_actions,
    stoneCategoriesState,
    stoneCategoriesSearch,
    updateFilters,
    stoneCategoriesFilters,
    stoneCategoriesErrors,
    stoneCategory,
    isLoading,
    languages: state.languages.languages,
    deleteStoneCategory,
  };
};

export default useStoneCategories;
