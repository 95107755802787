import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const unhappinessModel = () => ({
  name: languagesObject,
  unhappinessCategory: "",
  unhappinessSentences: [""],
  remedies: [""],
});

export const unhappinessInitialState = {
  isLoading: false,
  unhappinesses: [],
  unhappinessesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  unhappinessesSearch: [],
  unhappiness: unhappinessModel(),
  errors: {},
};

export default function (state = unhappinessInitialState, action) {
  switch (action.type) {
    case "UPDATE_UNHAPPINESSES_FILTERS":
      return {
        ...state,
        unhappinessesFilters: action.payload,
      };
    case "UPDATE_UNHAPPINESSES_SEARCH":
      return {
        ...state,
        unhappinessesSearch: action.payload,
      };
    case "UPDATE_UNHAPPINESSES":
      return {
        ...state,
        unhappinesses: action.payload,
      };

    case "UPDATE_UNHAPPINESSES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_UNHAPPINESSES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_CRYSTAL_SYSTEM":
      return {
        ...state,
        unhappiness: action.payload,
      };
    default:
      return state;
  }
}
