import { useEffect } from "react";
import { crystalSystemActions } from "../../context/actions/crystalSystemActions";
import useStore from "../../context/useStore";

const useCrystalSystems = (props) => {
  const [state, dispatch] = useStore();
  const crystal_systems_actions = crystalSystemActions(state, dispatch);
  const { updateCrystalSystemsFilters, getCrystalSystem, deleteCrystalSystem } =
    crystal_systems_actions;
  const crystalSystemsState = state.crystalSystems;
  const crystalSystemsFilters = crystalSystemsState.crystalSystemsFilters;
  const crystalSystemsErrors = crystalSystemsState.errors;
  const { crystalSystem, isLoading, crystalSystemsSearch } =
    crystalSystemsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = crystalSystemsState.crystalSystemsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateCrystalSystemsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    crystal_systems_actions,
    crystalSystemsState,
    crystalSystemsSearch,
    updateFilters,
    crystalSystemsFilters,
    crystalSystemsErrors,
    crystalSystem,
    isLoading,
    languages: state.languages.languages,
    deleteCrystalSystem,
  };
};

export default useCrystalSystems;
