import React from "react";
import { Link } from "react-router-dom";
import useStones from "../../../../hooks/useStones/useStones";
import Table from "../../../common/Table/Table";

const StonesListTable = () => {
  const { isLoading, stonesSearch, languages, stones_actions, state } =
    useStones();

  return (
    <Table
      isLoading={isLoading}
      nbResults={stonesSearch.count}
      datas={stonesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Catégorie",
          path: "stoneCategory",
          render: (el) => {
            let { stoneCategories } = state.stoneCategories;
            return (
              <Link
                className="text-white d-block p-0"
                to={`/stone-categories/${el}`}
              >
                {stoneCategories.find((s) => s.id == el)?.name.FR}
              </Link>
            );
          },
        },
        {
          name: "Chakras",
          path: "chakras",
          render: (el) => {
            let { chakras } = state.chakras;
            return el.map((chakra) => (
              <Link
                className="text-white d-block p-0"
                to={`/chakras/${chakra}`}
              >
                {chakras.find((s) => s.id == chakra)?.name.FR}
              </Link>
            ));
          },
        },
        {
          name: "Couleurs",
          path: "colors",
          render: (el) => {
            let { colors } = state.colors;
            return el.map((color) => (
              <Link className="text-white d-block p-0" to={`/colors/${color}`}>
                {colors.find((s) => s.id == color)?.name.FR}
              </Link>
            ));
          },
        },
        {
          name: "Rechargements",
          path: "reloadings",
          render: (el) => {
            let { reloadings } = state.reloadings;
            return el.map((reloading) => (
              <Link
                className="text-white d-block p-0"
                to={`/reloadings/${reloading}`}
              >
                {reloadings.find((s) => s.id == reloading)?.name.FR}
              </Link>
            ));
          },
        },
        {
          name: "Purifications",
          path: "purifications",
          render: (el) => {
            let { purifications } = state.purifications;
            return el.map((purification) => (
              <Link
                className="text-white d-block p-0"
                to={`/purifications/${purification}`}
              >
                {purifications.find((s) => s.id == purification)?.name.FR}
              </Link>
            ));
          },
        },
        {
          name: "Publié?",
          path: "visible",
          render: (visible) => {

            return (
              <div className="d-flex justify-content-center">
                {visible && <div className="badge badge-success">Oui</div>}
                {!visible && <div className="badge badge-danger">Non</div>}
              </div>
            );
          }
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/stones/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => stones_actions.deleteStone(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default StonesListTable;
