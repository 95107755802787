import React from "react";
import useReloadings from "../../../../hooks/useReloadings/useReloadings";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const ReloadingsFilters = () => {
  const {
    updateFilters,
    reloadingsState,
    reloadingsFilters,
    reloadingsErrors,
    reloadings_actions,
  } = useReloadings();

  return (
    // <form
    //   onSubmit={(e) => {
    //     e.preventDefault();
    //     reloadings_actions.searchReloadings();
    //   }}
    // >
    <FiltersContainer
      onSubmit={reloadings_actions.searchReloadings}
      filtersState={reloadingsState}
      isDataLoaded={reloadingsState.reloadingsSearch?.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={reloadingsFilters.terms}
            change={updateFilters}
            errors={reloadingsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
    // </form>
  );
};

export default ReloadingsFilters;
