import React from "react";
import useAspects from "../../../../hooks/useAspects/useAspects";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import AspectsFilters from "./AspectsFilters";
import AspectsListTable from "./AspectsListTable";

const AspectsList = (props) => {
  const { aspectsState, aspectsSearch, aspectsFilters, aspects_actions, state, dispatch } =
    useAspects();

  return (
    <Page
      title="Aspect"
      errors={aspectsState.errors}
      isLoading={aspectsState.isLoading}
      action={{
        to: "aspects/create",
        text: "Créer un aspect",
      }}
      {...props}
    >
      <div className="">
        <AspectsFilters />
        <div className="mt-3">
          <AspectsListTable />
        </div>
        {aspectsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(aspectsSearch.count / 25)}
            page={aspectsFilters.page}
            changePage={(p) =>
              aspects_actions.updateAspectsPage({
                ...aspectsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default AspectsList;
