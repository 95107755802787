import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const stepModel = () => ({
  action: "",
  stones: [],
  chakra: "",
});

export const stepInitialState = {
  isLoading: false,
  steps: [],
  stepsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  stepsSearch: [],
  step: stepModel(),
  errors: {},
};

export default function (state = stepInitialState, action) {
  switch (action.type) {
    case "UPDATE_STEPS_FILTERS":
      return {
        ...state,
        stepsFilters: action.payload,
      };
    case "UPDATE_STEPS_SEARCH":
      return {
        ...state,
        stepsSearch: action.payload,
      };
    case "UPDATE_STEPS":
      return {
        ...state,
        steps: action.payload,
      };

    case "UPDATE_STEPS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_STEPS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_STEP":
      return {
        ...state,
        step: action.payload,
      };
    default:
      return state;
  }
}
