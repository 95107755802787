import React from "react";
import useCrystalSystems from "../../../../hooks/useCrystalSystems/useCrystalSystems";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const CrystalSystemsFilters = () => {
  const {
    updateFilters,
    crystalSystemsState,
    crystalSystemsFilters,
    crystalSystemsErrors,
    crystal_systems_actions,
  } = useCrystalSystems();

  return (
    <FiltersContainer
      onSubmit={crystal_systems_actions.searchCrystalSystems}
      filtersState={crystalSystemsState}
      isDataLoaded={crystalSystemsState.crystalSystemsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={crystalSystemsFilters.terms}
            change={updateFilters}
            errors={crystalSystemsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default CrystalSystemsFilters;
