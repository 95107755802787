import { useEffect } from "react";
import { chakraActions } from "../../context/actions/chakraActions";
import useStore from "../../context/useStore";

const useChakras = (props) => {
  const [state, dispatch] = useStore();
  const chakras_actions = chakraActions(state, dispatch);
  const { updateChakrasFilters, getChakra, deleteChakra } = chakras_actions;
  const chakrasState = state.chakras;
  const chakrasFilters = chakrasState.chakrasFilters;
  const chakrasErrors = chakrasState.errors;
  const { chakra, isLoading, chakrasSearch } = chakrasState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = chakrasState.chakrasFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateChakrasFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    chakras_actions,
    chakrasState,
    chakrasSearch,
    updateFilters,
    chakrasFilters,
    chakrasErrors,
    chakra,
    isLoading,
    languages: state.languages.languages,
    deleteChakra,
  };
};

export default useChakras;
