import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const crystalSystemActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEMS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEMS_LOADING",
      payload: isLoading,
    });
  };
  const updateCrystalSystemsPage = (filters) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEMS_FILTERS",
      payload: filters,
    });
    searchCrystalSystems(filters);
  };
  const updateCrystalSystemsFilters = (filters) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEMS_FILTERS",
      payload: filters,
    });
  };
  const searchCrystalSystems = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `crystal-systems/search`,
      filters || state.crystalSystems.crystalSystemsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_CRYSTAL_SYSTEMS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getCrystalSystem = (crystalSystemId) => {
    updateIsLoading(true);
    dataService.get(
      `crystal-systems/${crystalSystemId}`,
      updateCrystalSystem,
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getCrystalSystems = () => {
    updateIsLoading(true);
    dataService.get(`crystal-systems`, updateCrystalSystems, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateCrystalSystem = (crystalSystem) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEM",
      payload: crystalSystem,
    });
  };
  const updateCrystalSystems = (crystalSystems) => {
    dispatch({
      type: "UPDATE_CRYSTAL_SYSTEMS",
      payload: crystalSystems,
    });
  };
  const updateCrystalSystemAttribute = ({ event, lang }) => {
    console.log("updateCrystalSystemAttribute", event, lang);
    const { crystalSystem } = state.crystalSystems;
    const { name, value } = event.target;
    const newCrystalSystem = {
      ...crystalSystem,
      [name]: {
        ...crystalSystem[name],
        [lang]: value,
      },
    };
    updateCrystalSystem(newCrystalSystem);
  };

  const saveCrystalSystem = () => {
    const { crystalSystem } = state.crystalSystems;
    updateIsLoading(true);
    if (crystalSystem.id) {
      dataService.patch(
        `crystal-systems/${crystalSystem.id}`,
        crystalSystem,
        (datas) => {
          updateCrystalSystem(datas);
          updateErrors({});
          toast.success("La catégorie a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `crystal-systems`,
        crystalSystem,
        (datas) => {
          updateCrystalSystem(datas);
          updateErrors({});
          const { crystalSystems } = state.crystalSystems;
          updateCrystalSystems([...crystalSystems, datas]);
          toast.success("La catégorie a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteCrystalSystem = (crystalSystemId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `crystal-systems/${crystalSystemId}`,
      (datas) => searchCrystalSystems(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchCrystalSystems,
    updateCrystalSystemsFilters,
    updateCrystalSystem,
    updateCrystalSystemAttribute,
    saveCrystalSystem,
    getCrystalSystem,
    deleteCrystalSystem,
    getCrystalSystems,
    updateCrystalSystems,
    updateCrystalSystemsPage,
  };
};
