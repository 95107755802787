import React from "react";
import { Link } from "react-router-dom";
import useRemedies from "../../../../hooks/useRemedies/useRemedies";
import Table from "../../../common/Table/Table";
import Control from "../../../common/Controls/Control";

const RemediesListTable = () => {
  const { isLoading, remediesSearch, languages, remedies_actions, state } =
    useRemedies();

  return (
    <Table
      isLoading={isLoading}
      nbResults={remediesSearch.count}
      datas={remediesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        // {
        //   name: "Étape",
        //   path: "steps",
        //   render: (el) => {
        //     let { steps } = state.steps;
        //     return el.map((step) => (
        //       <Link className="text-white d-block p-0" to={`/steps/${step}`}>
        //         {steps.find((s) => s.id == step)?.action?.FR}
        //       </Link>
        //     ));
        //   },
        // },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <div className="d-center" style={{ gap: 10 }}>
                <Control
                  label={el.visible ? "Visible" : "Invisible"}
                  checked={el.visible}
                  type="checkbox"
                  name={`visible${el.id}`}
                  change={(e) =>
                    remedies_actions.patchRemedy({
                      ...el,
                      visible: e.target.checked,
                    })
                  }
                />
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/remedies/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => remedies_actions.deleteRemedy(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default RemediesListTable;
