import { useEffect, useState } from "react";
import DeleteBtn from "../DeleteBtn/DeleteBtn";

const ImagesPreview = ({ uniqId = "imagepreviewer", className = "", file, deleteImage = null }) => {
  const [objectUrl, setObjectUrl] = useState(null);
  useEffect(() => {
    if (file) {
      setObjectUrl(file.url ? file.url : URL.createObjectURL(file));
    }
  }, [file]);

  const hasImage = objectUrl ? true : false;

  return (
    <div className={`position-relative w-100 ${className}`} id={`image-preview-wrapper${uniqId}`}>
      <div
        className=""
        id={`image-preview-container${uniqId}`}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
          height: hasImage ? 400 : 50,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        {!hasImage && <p className="d-center h-100">Aucune image pour le moment</p>}

        <div
          className="d-inline-block position-relative"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            backgroundImage: `url(${objectUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      {file.id && (
        <div className="d-center">
          <DeleteBtn
            style={{ fontSize: 21 }}
            className="d-center text-danger bg-white p-3  btn-sm"
            deleteAction={() => deleteImage(file.id)}
          />
        </div>
      )}
    </div>
  );
};

export default ImagesPreview;
