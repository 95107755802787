import React from "react";
import { Link } from "react-router-dom";
import useSteps from "../../../../hooks/useSteps/useSteps";
import Table from "../../../common/Table/Table";

const StepsListTable = () => {
  const { isLoading, stepsSearch, languages, steps_actions, state } = useSteps();

  return (
    <Table
      isLoading={isLoading}
      nbResults={stepsSearch.count}
      datas={stepsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Action",
          path: "action",
          render: (el) => {
            let { actions } = state.actions;
            return (
              <Link className="text-white d-block p-0" to={`/stones/${el}`}>
                {actions.find((s) => s.id == el)?.name.FR}
              </Link>
            );
          },
        },
        {
          name: "Pierres",
          path: "stones",
          render: (el) => {
            let { stones } = state.stones;
            console.log("EL", el);
            return el.map((st) => {
              return (
                <Link className="text-white d-block p-0" to={`/stones/${el}`}>
                  {stones.find((s) => s.id == st)?.name.FR}
                </Link>
              );
            });
          },
        },
        {
          name: "Chakras",
          path: "chakra",
          render: (el) => {
            let { chakras } = state.chakras;
            return (
              <Link className="text-white d-block p-0" to={`/chakras/${el}`}>
                {chakras.find((s) => s.id == el)?.name.FR}
              </Link>
            );
          },
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-default btn-sm mr-1" to={`/steps/${el.id}`}>
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => steps_actions.deleteStep(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default StepsListTable;
