import { useEffect } from "react";
import { remedyActions } from "../../context/actions/remedyActions";
import useStore from "../../context/useStore";

const useRemedies = (props) => {
  const [state, dispatch] = useStore();
  const remedies_actions = remedyActions(state, dispatch);
  const { updateRemediesFilters, getRemedy, deleteRemedy } = remedies_actions;
  const remediesState = state.remedies;
  const remediesFilters = remediesState.remediesFilters;
  const remediesErrors = remediesState.errors;
  const { remedy, isLoading, remediesSearch } = remediesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = remediesState.remediesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateRemediesFilters(newFilters);
  };

  return {
    state,
    dispatch,
    remedies_actions,
    remediesState,
    remediesSearch,
    updateFilters,
    remediesFilters,
    remediesErrors,
    remedy,
    isLoading,
    languages: state.languages.languages,
    deleteRemedy,
    constants: state.constants.items,
  };
};

export default useRemedies;
