import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const unhappinessSentenceActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCES_LOADING",
      payload: isLoading,
    });
  };
  const updateUnhappinessSentencesFilters = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCES_FILTERS",
      payload: filters,
    });
  };
  const updateUnhappinessSentencesPage = (filters) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCES_FILTERS",
      payload: filters,
    });
    searchUnhappinessSentences(filters);
  };
  const searchUnhappinessSentences = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `unhappiness-sentences/search`,
      filters || state.unhappinessSentences.unhappinessSentencesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_UNHAPPINESS_SENTENCES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getUnhappinessSentence = (unhappinessSentenceId) => {
    updateIsLoading(true);
    dataService.get(
      `unhappiness-sentences/${unhappinessSentenceId}`,
      updateUnhappinessSentence,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateUnhappinessSentence = (unhappinessSentence) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCE",
      payload: unhappinessSentence,
    });
  };
  const getUnhappinessSentences = () => {
    updateIsLoading(true);
    dataService.get(
      `unhappiness-sentences`,
      updateUnhappinessSentences,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateUnhappinessSentences = (unhappinessSentences) => {
    dispatch({
      type: "UPDATE_UNHAPPINESS_SENTENCES",
      payload: unhappinessSentences,
    });
  };
  const updateUnhappinessSentenceAttribute = ({ event, lang }) => {
    const { unhappinessSentence } = state.unhappinessSentences;
    const { name, value } = event.target;
    let newUnhappinessSentence = {};
    if (lang) {
      newUnhappinessSentence = {
        ...unhappinessSentence,
        [name]: {
          ...unhappinessSentence[name],
          [lang]: value,
        },
      };
    } else {
      newUnhappinessSentence = {
        ...unhappinessSentence,
        [name]: value,
      };
    }
    updateUnhappinessSentence(newUnhappinessSentence);
  };

  const saveUnhappinessSentence = () => {
    const { unhappinessSentence } = state.unhappinessSentences;
    updateIsLoading(true);
    if (unhappinessSentence.id) {
      dataService.patch(
        `unhappiness-sentences/${unhappinessSentence.id}`,
        unhappinessSentence,
        (datas) => {
          updateUnhappinessSentence(datas);
          updateErrors({});
          toast.success("La phrase a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `unhappiness-sentences`,
        unhappinessSentence,
        (datas) => {
          updateUnhappinessSentence(datas);
          updateErrors({});
          const { unhappinessSentences } = state.unhappinessSentences;
          updateUnhappinessSentences([...unhappinessSentences, datas]);
          toast.success("La phrase a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteUnhappinessSentence = (unhappinessSentenceId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `unhappiness-sentences/${unhappinessSentenceId}`,
      (datas) => searchUnhappinessSentences(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchUnhappinessSentences,
    updateUnhappinessSentencesFilters,
    updateUnhappinessSentence,
    updateUnhappinessSentenceAttribute,
    saveUnhappinessSentence,
    getUnhappinessSentence,
    deleteUnhappinessSentence,
    updateUnhappinessSentencesPage,
    getUnhappinessSentences,
  };
};
