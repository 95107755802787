import React from "react";
import useUnhappinesses from "../../../../hooks/useUnhappinesses/useUnhappinesses";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const UnhappinessesFilters = () => {
  const {
    updateFilters,
    unhappinessesState,
    unhappinessesFilters,
    unhappinessesErrors,
    unhappinesses_actions,
  } = useUnhappinesses();
  return (
    <FiltersContainer
      onSubmit={unhappinesses_actions.searchUnhappinesses}
      filtersState={unhappinessesState}
      isDataLoaded={unhappinessesState.unhappinessesSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={unhappinessesFilters.terms}
            change={updateFilters}
            errors={unhappinessesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default UnhappinessesFilters;
