import React from "react";
import { Link } from "react-router-dom";
import { rightPanelActions } from "../../../context/actions/rightPanelActions";
import useStore from "../../../context/useStore";

const RightPanel = () => {
  const [state, dispatch] = useStore();
  const actions = rightPanelActions(state, dispatch);
  const rightPanelProps = state.rightPanel;

  return (
    <>
      <div
        onClick={() => {
          actions.updateRightPanel({ isOpen: false, content: null });
        }}
        className="position-fixed w-100 h-100 align-items-center justify-content-center"
        style={{
          display: rightPanelProps.isOpen ? "flex" : "none",
          top: 0,
          left: 0,
          background: "rgb(0 0 0 / 54%)",
          zIndex: "9999",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          className={
            "position-fixed p-3 flex-column align-items-start " +
            (rightPanelProps.isOpen ? "animated fadeInRight faster" : "")
          }
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "42%",
            height: "100%",
            maxWidth: "400",
            borderRadius: 5,
            overflowY: "scroll",
            right: 0,
            top: 0,
            zIndex: "9999",
            minWidth: 350,
            background: "rgba(31, 31, 31, 0.44)",
            backdropFilter: "blur(10px)",
          }}
        >
          {rightPanelProps.backUrl && (
            <Link className="btn btn-link" to={rightPanelProps.backUrl}>
              <i className="fa fa-arrow-left mr-2" />
              retour à la page d'origine
            </Link>
          )}
          <button
            className="btn btn-trans"
            style={{
              position: "absolute",
              right: 13,
              top: 13,
            }}
            onClick={(e) => {
              e.stopPropagation();
              actions.updateRightPanel({ isOpen: false, content: null });
            }}
          >
            <i className="fa fa-times text-white" />
          </button>
          {rightPanelProps.content}
        </div>
      </div>
    </>
  );
};

export default RightPanel;
