import React from "react";
import { Link } from "react-router-dom";
import useStoneCategories from "../../../../hooks/useStoneCategories/useStoneCategories";
import Table from "../../../common/Table/Table";

const StoneCategoriesListTable = () => {
  const {
    isLoading,
    stoneCategoriesSearch,
    languages,
    stone_categories_actions,
  } = useStoneCategories();

  return (
    <Table
      isLoading={isLoading}
      nbResults={stoneCategoriesSearch.count}
      datas={stoneCategoriesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/stone-categories/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() =>
                    stone_categories_actions.deleteStoneCategory(el.id)
                  }
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default StoneCategoriesListTable;
