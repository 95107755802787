import React from "react";
import usePurifications from "../../../../hooks/usePurifications/usePurifications";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import PurificationsFilters from "./PurificationsFilters";
import PurificationsListTable from "./PurificationsListTable";

const PurificationsList = () => {
  const {
    purificationsState,
    purificationsSearch,
    purificationsFilters,
    purifications_actions,
  } = usePurifications();

  return (
    <Page
      title="Purifications"
      errors={purificationsState.errors}
      isLoading={purificationsState.isLoading}
      action={{
        to: "purifications/create",
        text: "Créer une purification",
      }}
    >
      <div className="">
        <PurificationsFilters />
        <div className="mt-3">
          <PurificationsListTable />
        </div>
        {purificationsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(purificationsSearch.count / 25)}
            page={purificationsFilters.page}
            changePage={(p) =>
              purifications_actions.updatePurificationsPage({
                ...purificationsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default PurificationsList;
