import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const stoneCategoryModel = () => ({
  name: languagesObject,
});

export const stoneCategoryInitialState = {
  isLoading: false,
  stoneCategories: [],
  stoneCategoriesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  stoneCategoriesSearch: [],
  stoneCategory: stoneCategoryModel(),
  errors: {},
};

export default function (state = stoneCategoryInitialState, action) {
  switch (action.type) {
    case "UPDATE_STONE_CATEGORIES_FILTERS":
      return {
        ...state,
        stoneCategoriesFilters: action.payload,
      };
    case "UPDATE_STONE_CATEGORIES_SEARCH":
      return {
        ...state,
        stoneCategoriesSearch: action.payload,
      };
    case "UPDATE_STONE_CATEGORIES":
      return {
        ...state,
        stoneCategories: action.payload,
      };

    case "UPDATE_STONE_CATEGORIES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_STONE_CATEGORIES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_STONE_CATEGORY":
      return {
        ...state,
        stoneCategory: action.payload,
      };
    default:
      return state;
  }
}
