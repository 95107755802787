import { useEffect } from "react";
import { aspectActions } from "../../context/actions/aspectActions";
import useStore from "../../context/useStore";

const useAspects = (props) => {
  const [state, dispatch] = useStore();
  const aspects_actions = aspectActions(state, dispatch);
  const { updateAspectsFilters, getAspect, deleteAspect } = aspects_actions;
  const aspectsState = state.aspects;
  const aspectsFilters = aspectsState.aspectsFilters;
  const aspectsErrors = aspectsState.errors;
  const { aspect, isLoading, aspectsSearch } = aspectsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = aspectsState.aspectsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateAspectsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    aspects_actions,
    aspectsState,
    aspectsSearch,
    updateFilters,
    aspectsFilters,
    aspectsErrors,
    aspect,
    isLoading,
    languages: state.languages.languages,
    deleteAspect,
  };
};

export default useAspects;
