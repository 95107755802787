import React from "react";
import { Link } from "react-router-dom";
import useActions from "../../../../hooks/useActions/useActions";
import Table from "../../../common/Table/Table";

const ActionsListTable = () => {
  const { isLoading, actionsSearch, languages, actions_actions, state } = useActions();

  return (
    <Table
      isLoading={isLoading}
      nbResults={actionsSearch.count}
      datas={actionsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-default btn-sm mr-1" to={`/actions/${el.id}`}>
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => actions_actions.deleteAction(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ActionsListTable;
