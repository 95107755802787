import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const reloadingActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_RELOADINGS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_RELOADINGS_LOADING",
      payload: isLoading,
    });
  };
  const updateReloadingsFilters = (filters) => {
    dispatch({
      type: "UPDATE_RELOADINGS_FILTERS",
      payload: filters,
    });
  };
  const updateReloadingsPage = (filters) => {
    dispatch({
      type: "UPDATE_RELOADINGS_FILTERS",
      payload: filters,
    });
    searchReloadings(filters);
  };
  const searchReloadings = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `reloadings/search`,
      filters || state.reloadings.reloadingsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_RELOADINGS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getReloading = (reloadingId) => {
    updateIsLoading(true);
    dataService.get(
      `reloadings/${reloadingId}`,
      updateReloading,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateReloading = (reloading) => {
    dispatch({
      type: "UPDATE_RELOADING",
      payload: reloading,
    });
  };
  const getReloadings = () => {
    updateIsLoading(true);
    dataService.get(`reloadings`, updateReloadings, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateReloadings = (reloadings) => {
    dispatch({
      type: "UPDATE_RELOADINGS",
      payload: reloadings,
    });
  };
  const updateReloadingAttribute = ({ event, lang }) => {
    console.log("EV", event, lang);
    const { reloading } = state.reloadings;
    const { name, value } = event.target;
    let newReloading = {};
    if (lang) {
      newReloading = {
        ...reloading,
        [name]: {
          ...reloading[name],
          [lang]: value,
        },
      };
    } else {
      newReloading = {
        ...reloading,
        [name]: value,
      };
    }
    console.log("newReloading", newReloading);
    updateReloading(newReloading);
  };

  const saveReloading = () => {
    const { reloading } = state.reloadings;
    updateIsLoading(true);
    if (reloading.id) {
      dataService.patch(
        `reloadings/${reloading.id}`,
        reloading,
        (datas) => {
          updateReloading(datas);
          updateErrors({});
          toast.success("Le rechargement a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `reloadings`,
        reloading,
        (datas) => {
          updateReloading(datas);
          updateErrors({});
          const { reloadings } = state.reloadings;
          updateReloadings([...reloadings, datas]);
          toast.success("Le rechargement a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteReloading = (reloadingId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `reloadings/${reloadingId}`,
      (datas) => searchReloadings(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchReloadings,
    updateReloadingsFilters,
    updateReloading,
    updateReloadingAttribute,
    saveReloading,
    getReloading,
    deleteReloading,
    getReloadings,
    updateReloadings,
    updateReloadingsPage,
  };
};
