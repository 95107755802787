import React, { useEffect } from "react";
import {
  colorInitialState,
  colorModel,
} from "../../../../context/reducers/colorReducers";
import useStore from "../../../../context/useStore";
import useColors from "../../../../hooks/useColors/useColors";
import Control from "../../../common/Controls/Control";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import FormContainer from "../../../common/FormContainer.js/FormContainer";

const ColorsDetail = (props) => {
  const { state, colors_actions, color, colorsErrors, isLoading } =
    useColors(props);
  const { updateColorAttribute, saveColor, getColor, updateColor } =
    colors_actions;
  const constants = state.constants.items;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getColor(paramsId);
    } else {
      updateColor(colorModel());
    }
  }, []);

  return (
    <Page
      title={color.name?.FR || "Couleur de pierre"}
      back={`/colors`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveColor} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateColorAttribute}
          type="text"
          value={color.name}
          error={colorsErrors}
        />

        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Couleur"
              name="color"
              change={(event) => updateColorAttribute({ event })}
              type="color"
              value={color.color}
              error={colorsErrors}
              inputStyle={{ height: 50 }}
              info={
                "Cliquez sur le cadre coloré pour choisir la couleur, ou écrivez directement son code hexadécimal"
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Sons"
              name="sounds"
              value={color.sounds}
              change={(event) => updateColorAttribute({ event })}
              btnLabel="Ajouter un son"
              field={{
                type: "select",
                name: "sound",
                datas: constants.SOUNDS,
              }}
              error={colorsErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default ColorsDetail;
