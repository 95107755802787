import React from "react";
import useUnhappinessSentences from "../../../../hooks/useUnhappinessSentences/useUnhappinessSentences";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const UnhappinessSentencesFilters = () => {
  const {
    updateFilters,
    unhappinessSentencesState,
    unhappinessSentencesFilters,
    unhappinessSentencesErrors,
    unhappinessSentences_actions,
  } = useUnhappinessSentences();

  return (
    // <form
    //   onSubmit={(e) => {
    //     e.preventDefault();
    //     unhappinessSentences_actions.searchUnhappinessSentences();
    //   }}
    // >
    <FiltersContainer
      onSubmit={unhappinessSentences_actions.searchUnhappinessSentences}
      filtersState={unhappinessSentencesState}
      isDataLoaded={
        unhappinessSentencesState.unhappinessSentencesSearch?.data?.length
      }
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={unhappinessSentencesFilters.terms}
            change={updateFilters}
            errors={unhappinessSentencesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
    // </form>
  );
};

export default UnhappinessSentencesFilters;
