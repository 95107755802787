import useStore from "../../../../../context/useStore";
import IntlInput from "../../../../common/IntlInput/IntlInput";

const Anecdotes = ({ anecdotes, setAnecdotes }) => {
  const [state] = useStore();
  const { languages } = state;

  const updateAnecdote = (e, index) => {
    const newAnecdotes = anecdotes.map((a, ak) => {
      return ak == index ? { ...a, name: { ...a.name, [e.lang]: e.event.target.value } } : a;
    });
    setAnecdotes(newAnecdotes);
  };

  const addAnecdote = () => {
    const newAnecdote = { name: {} };
    languages.languages.forEach((lang) => {
      newAnecdote.name[lang] = "";
    });
    setAnecdotes([...anecdotes, newAnecdote]);
  };

  const deleteAnecdote = (index) => {
    const newAnecdotes = anecdotes.filter((a, ak) => ak != index);
    setAnecdotes(newAnecdotes);
  };

  return (
    <div>
      <label className="d-flex align-items-center">
        <button className="btn btn-outline-default mr-1 btn-sm" type="button" onClick={addAnecdote}>
          <i className="fa fa-plus" />
        </button>
        <span className="label-text pb-0">Anecdotes</span>
      </label>
      {anecdotes.map((anecdote, ak) => {
        return (
          <div>
            <i className="px-1">Anecdote #{parseInt(ak) + 1}</i>
            <button
              onClick={() => deleteAnecdote(ak)}
              className="btn btn-sm shadow-none"
              type="button"
            >
              <i className="fa fa-times text-danger" />
            </button>
            <IntlInput
              label="Texte"
              name="name"
              change={(e) => updateAnecdote(e, ak)}
              type="textarea"
              value={anecdote.name}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Anecdotes;
