import React from "react";
import useActions from "../../../../hooks/useActions/useActions";
import FileUploadSingle from "../../../common/FileUpload/FileUploadSingle";
import ImagePreview from "../../../common/FileUpload/ImagePreview";

const ActionFilesManager = () => {
  const { actions_actions, action, isLoading } = useActions();
  const { uploadActionImage, deleteActionImage } = actions_actions;

  return (
    <div className="d-center bg-light flex-column rounded ">
      {action.id ? (
        <>
          {action.image ? (
            <div className="d-center w-100">
              <ImagePreview
                className="mb-3"
                uniqId="actionimgpreview"
                file={action.image}
                deleteImage={(imageId) => deleteActionImage({ imageId, actionId: action.id })}
              />
            </div>
          ) : (
            <FileUploadSingle
              onFileDrop={(file) =>
                uploadActionImage({
                  file,
                  actionId: action.id,
                })
              }
              isLoading={isLoading}
            />
          )}
        </>
      ) : (
        <p>Enregistrez l'action avant de pouvoir envoyer une image</p>
      )}
    </div>
  );
};

export default ActionFilesManager;
