import React from "react";
import useUnhappinessSentences from "../../../../hooks/useUnhappinessSentences/useUnhappinessSentences";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import UnhappinessSentencesFilters from "./UnhappinessSentencesFilters";
import UnhappinessSentencesListTable from "./UnhappinessSentencesListTable";

const UnhappinessSentencesList = () => {
  const {
    unhappinessSentencesState,
    unhappinessSentencesSearch,
    unhappinessSentencesFilters,
    unhappinessSentences_actions,
  } = useUnhappinessSentences();

  return (
    <Page
      title="Phrases de mal-être"
      errors={unhappinessSentencesState.errors}
      isLoading={unhappinessSentencesState.isLoading}
      action={{
        to: "unhappiness-sentences/create",
        text: "Créer une phrase de mal-être",
      }}
    >
      <div className="">
        <UnhappinessSentencesFilters />
        <div className="mt-3">
          <UnhappinessSentencesListTable />
        </div>
        {unhappinessSentencesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(unhappinessSentencesSearch.count / 25)}
            page={unhappinessSentencesFilters.page}
            changePage={(p) =>
              unhappinessSentences_actions.updateUnhappinessSentencesPage({
                ...unhappinessSentencesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default UnhappinessSentencesList;
