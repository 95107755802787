import React from "react";
import useAspects from "../../../../hooks/useAspects/useAspects";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const AspectsFilters = () => {
  const { updateFilters, aspectsState, aspectsFilters, aspectsErrors, aspects_actions } =
    useAspects();
  return (
    <FiltersContainer
      onSubmit={aspects_actions.searchAspects}
      filtersState={aspectsState}
      isDataLoaded={aspectsState.aspectsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={aspectsFilters.terms}
            change={updateFilters}
            errors={aspectsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default AspectsFilters;
