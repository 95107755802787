import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";
import tools from "../../helpers/tools";

export const stoneActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_STONES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_STONES_LOADING",
      payload: isLoading,
    });
  };
  const updateStonesFilters = (filters) => {
    dispatch({
      type: "UPDATE_STONES_FILTERS",
      payload: filters,
    });
  };
  const updateStonesPage = (filters) => {
    dispatch({
      type: "UPDATE_STONES_FILTERS",
      payload: filters,
    });
    searchStones(filters);
  };
  const searchStones = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `stones/search`,
      filters || state.stones.stonesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_STONES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const getStone = (stoneId) => {
    updateIsLoading(true);
    dataService.get(
      `stones/${stoneId}`,
      (stone) => {
        updateStone({ ...stone, audioDescription: stone.audioDescription || {} });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateStone = (stone) => {
    dispatch({
      type: "UPDATE_STONE",
      payload: stone,
    });
  };
  const getStones = () => {
    updateIsLoading(true);
    dataService.get(`stones`, updateStones, updateErrors, () => updateIsLoading(false));
  };

  const updateStones = (stones) => {
    dispatch({
      type: "UPDATE_STONES",
      payload: stones,
    });
  };
  const updateStoneAttribute = ({ event, lang }) => {
    const { stone } = state.stones;
    const { name, value } = event.target;
    let newStone = {};
    if (lang) {
      newStone = {
        ...stone,
        [name]: {
          ...stone[name],
          [lang]: value,
        },
      };
    } else {
      newStone = {
        ...stone,
        [name]: value,
      };
    }
    updateStone(newStone);
  };
  const updateStoneAudioAttribute = ({ event, lang }) => {
    const { stone } = state.stones;
    const { name, value } = event.target;
    let newStone = {};
    if (lang) {
      newStone = {
        ...stone,
        audioDescription: {
          ...stone.audioDescription,
          [name]: {
            ...stone.audioDescription[name],
            [lang]: value,
          },
        },
      };
    } else {
      newStone = {
        ...stone,
        audioDescription: {
          ...stone.audioDescription,
          [name]: value,
        },
      };
    }
    updateStone(newStone);
  };
  const updateStoneMainImage = (imageId) => {
    const { stone } = state.stones;
    let newStone = {};

    newStone = {
      ...stone,
      images: stone.images.map((image) => {
        return image.file.id == imageId
          ? { ...image, isMain: true, isThumbnail: false }
          : { ...image, isMain: false };
      }),
    };

    updateStone(newStone);
  };
  const updateStoneThumbnailImage = (imageId) => {
    const { stone } = state.stones;
    let newStone = {};

    newStone = {
      ...stone,
      images: stone.images.map((image) => {
        return image.file.id == imageId
          ? { ...image, isThumbnail: true, isMain: false }
          : { ...image, isThumbnail: false };
      }),
    };

    updateStone(newStone);
  };
  const uploadStoneImages = ({ files, stoneId }) => {
    updateIsLoading(true);
    toast.info("L'envoi des images est en cours...");
    dataService.postFile(
      `stones/${stoneId}/images`,
      files,
      () => getStone(stoneId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const uploadStoneAudioDescription = ({ file, stoneId }) => {
    updateIsLoading(true);
    toast.info("L'envoi du fichier audio est en cours...");
    dataService.postFile(
      `stones/${stoneId}/audioDescription`,
      file,
      () => getStone(stoneId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const deleteStoneImage = ({ stoneId, imageId }) => {
    updateIsLoading(true);

    dataService.remove(
      `stones/${stoneId}/images/${imageId}`,
      () => getStone(stoneId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const deleteStoneAudio = ({ stoneId, audioId }) => {
    updateIsLoading(true);

    dataService.remove(
      `stones/${stoneId}/audioDescription/${audioId}`,
      () => getStone(stoneId),
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const moveStoneImagePosition = ({ oldIndex, newIndex }) => {
    const { stone } = state.stones;
    let newStoneImages = tools.arrayMove(stone.images, oldIndex, newIndex);

    updateStone({ ...stone, images: newStoneImages });
  };

  const saveStone = () => {
    const { stone } = state.stones;
    updateIsLoading(true);
    if (stone.id) {
      if (stone.audioDescription?.file) {
        if (!stone.audioDescription.readBy) {
          toast.error("Veuillez renseigner le nom du narrateur de l'audio description");
          return false;
        }
        if (!stone.audioDescription.audioName?.FR || !stone.audioDescription.audioName?.EN) {
          toast.error("Veuillez renseigner la description du fichier audio");
          return false;
        }
      }
      dataService.patch(
        `stones/${stone.id}`,
        {
          ...stone,
          images: stone.images.map((f) => ({ ...f, file: f.file.id })),
          audioDescription: {
            ...stone.audioDescription,
            file: stone.audioDescription?.file?.id || null,
          },
        },
        (datas) => {
          updateStone(datas);
          updateErrors({});
          toast.success("La pierre a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `stones`,
        stone,
        (datas) => {
          updateStone(datas);
          updateErrors({});

          toast.success("La pierre a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteStone = (stoneId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `stones/${stoneId}`,
      (datas) => searchStones(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchStones,
    updateStonesFilters,
    updateStone,
    updateStoneAttribute,
    saveStone,
    getStone,
    deleteStone,
    updateStonesPage,
    uploadStoneImages,
    deleteStoneImage,
    updateStoneMainImage,
    updateStoneThumbnailImage,
    moveStoneImagePosition,
    getStones,
    uploadStoneAudioDescription,
    deleteStoneAudio,
    updateStoneAudioAttribute,
  };
};
