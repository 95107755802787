import React from "react";
import useAssistant from "../useAssistant/useAssistant";

const OpenAssistantButton = ({
  promptModel,
  modelParams,
  text = "Ouvrir l'assistant",
}) => {
  const { assistantActions } = useAssistant();

  return (
    <button
      className="btn btn-default btn-sm"
      type="button"
      onClick={() => assistantActions.onOpenAssistant(promptModel, modelParams)}
    >
      <i className="fa fa-brain mr-1" />
      {text}
    </button>
  );
};

export default OpenAssistantButton;
