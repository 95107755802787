import React, { useEffect } from "react";
import { actionActions } from "../../../../context/actions/actionActions";
import { remedyActions } from "../../../../context/actions/remedyActions";
import { stepActions } from "../../../../context/actions/stepActions";
import { stoneActions } from "../../../../context/actions/stoneActions";
import { remedyModel } from "../../../../context/reducers/remedyReducers";
import useRemedies from "../../../../hooks/useRemedies/useRemedies";
import Control from "../../../common/Controls/Control";
import FileUpload from "../../../common/FileUpload/FileUpload";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import Formula from "../../../common/Formula/Formula";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import StepsDetail from "../../Steps/StepsDetail/StepsDetail";

const RemediesDetail = (props) => {
  const {
    state,
    dispatch,
    remedies_actions,
    remedy,
    remediesErrors,
    isLoading,
    constants,
  } = useRemedies(props);
  const { updateRemedyAttribute, saveRemedy, getRemedy, updateRemedy } =
    remedies_actions;

  const { getSteps } = stepActions(state, dispatch);
  const { getActions } = actionActions(state, dispatch);
  const { getStones } = stoneActions(state, dispatch);

  useEffect(() => {
    getSteps();
    getActions();
    getStones();

    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getRemedy(paramsId);
    } else {
      updateRemedy(remedyModel());
    }
  }, []);

  return (
    <Page
      title={remedy.name?.FR || "Remède"}
      back={`/remedies`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveRemedy} isLoading={isLoading}>
        <div className="row">
          <div className="col-12">
            <Control
              label={remedy.visible ? "Publié" : "Non publié"}
              checked={remedy.visible}
              type="checkbox"
              name="visible"
              change={(e) =>
                updateRemedyAttribute({
                  event: {
                    target: { name: "visible", value: e.target.checked },
                  },
                })
              }
            />
            <IntlInput
              label="Nom"
              name="name"
              change={updateRemedyAttribute}
              type="text"
              value={remedy.name}
              error={remediesErrors}
            />

            <SimpleDynamicList
              label="Étapes"
              name="steps"
              value={remedy.steps}
              change={(event) => updateRemedyAttribute({ event })}
              btnLabel="Ajouter une étape"
              field={{
                type: "btnList",
                btnInline: true,
                name: "step",
                datas: state.steps.steps.map((s) => {
                  var actionRef = state.actions.actions.find(
                    (a) => a.id == s.action
                  );

                  var stonesRef = s.stones.map((stone) => {
                    var stRef = state.stones.stones.find(
                      (st) => st.id == stone
                    );
                    return stRef ? stRef.name.FR : "";
                  });
                  console.log("stonesRef", stonesRef);
                  if (actionRef) {
                    return {
                      ...s,
                      action: {
                        ...actionRef.name,
                        FR: (
                          <span>
                            {actionRef.name.FR}
                            <span className="font-italic text-primary">
                              ({stonesRef.join(", ")})
                            </span>
                          </span>
                        ),
                      },
                    };
                  }
                  return s;
                }),
                dataIndex: "id",
                dataLabel: "action",
                dataLabelIndex: "FR",
              }}
              error={remediesErrors}
              entityComponent={<StepsDetail />}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default RemediesDetail;
