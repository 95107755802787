import React from "react";
import { Link } from "react-router-dom";
import useColors from "../../../../hooks/useColors/useColors";
import Table from "../../../common/Table/Table";

const ColorsListTable = () => {
  const { isLoading, colorsSearch, languages, colors_actions, state } =
    useColors();

  return (
    <Table
      isLoading={isLoading}
      nbResults={colorsSearch.count}
      datas={colorsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Couleur",
          path: "color",
          render: (el) => (
            <div
              className="d-center rounded"
              style={{
                width: "100%",
                height: 30,
                background: el,
                color: "white",
                fontWeight: "800",
                textShadow: "0 0 3px black",
                boxShadow: `0px 3px 14px -3px ${el}`,
              }}
            >
              {el}
            </div>
          ),
        },
        {
          name: "Sons",
          path: "sounds",
          render: (el) => {
            let { SOUNDS } = state.constants.items;
            return el
              .map((sound) => SOUNDS.find((s) => s.id == sound).name)
              .join(", ");
          },
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/colors/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn btn-trans btn-sm"
                  onClick={() => colors_actions.deleteColor(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ColorsListTable;
