var API_URL = (exports.API_URL = process.env.REACT_APP_API_URL);
var ASSISTANT_URL = (exports.ASSISTANT_URL =
  process.env.REACT_APP_ASSISTANT_URL);
var PLATFORM = (exports.PLATFORM = process.env.REACT_APP_PLATFORM);
var recaptcha_token = (exports.recaptcha_token =
  process.env.REACT_APP_RECAPTCHA_TOKEN);
var AVATAR_URL = (exports.AVATAR_URL = API_URL + "files/avatars/");
var APP_URL = (exports.APP_URL = process.env.REACT_APP_APP_URL);
var APP_NAME = (exports.APP_NAME = process.env.REACT_APP_APP_NAME);
var LANGUAGES = (exports.LANGUAGES =
  process.env.REACT_APP_LANGUAGES.split(" "));
var AZURE_KEY = (exports.AZURE_KEY = process.env.REACT_APP_AZURE_KEY);
var AZURE_KEY2 = (exports.AZURE_KEY2 = process.env.REACT_APP_AZURE_KEY2);
var AZURE_REGION = (exports.AZURE_REGION = process.env.REACT_APP_AZURE_REGION);
var AZURE_ENDPOINT = (exports.AZURE_ENDPOINT =
  process.env.REACT_APP_AZURE_ENDPOINT);
