import { useEffect } from "react";
import { actionActions } from "../../context/actions/actionActions";
import useStore from "../../context/useStore";

const useActions = (props) => {
  const [state, dispatch] = useStore();
  const actions_actions = actionActions(state, dispatch);
  const { updateActionsFilters, getAction, deleteAction } = actions_actions;
  const actionsState = state.actions;
  const actionsFilters = actionsState.actionsFilters;
  const actionsErrors = actionsState.errors;
  const { action, isLoading, actionsSearch } = actionsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = actionsState.actionsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateActionsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    actions_actions,
    actionsState,
    actionsSearch,
    updateFilters,
    actionsFilters,
    actionsErrors,
    action,
    isLoading,
    languages: state.languages.languages,
    deleteAction,
    constants: state.constants.items,
  };
};

export default useActions;
