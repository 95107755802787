import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const stepActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_STEPS_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_STEPS_LOADING",
      payload: isLoading,
    });
  };
  const updateStepsFilters = (filters) => {
    dispatch({
      type: "UPDATE_STEPS_FILTERS",
      payload: filters,
    });
  };
  const updateStepsPage = (filters) => {
    dispatch({
      type: "UPDATE_STEPS_FILTERS",
      payload: filters,
    });
    searchSteps(filters);
  };
  const searchSteps = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `steps/search`,
      filters || state.steps.stepsFilters,
      (data) => {
        dispatch({
          type: "UPDATE_STEPS_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getStep = (stepId) => {
    updateIsLoading(true);
    dataService.get(`steps/${stepId}`, updateStep, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateStep = (step) => {
    dispatch({
      type: "UPDATE_STEP",
      payload: step,
    });
  };
  const getSteps = () => {
    updateIsLoading(true);
    dataService.get(`steps`, updateSteps, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateSteps = (steps) => {
    dispatch({
      type: "UPDATE_STEPS",
      payload: steps,
    });
  };
  const updateStepAttribute = ({ event, lang }) => {
    const { step } = state.steps;
    const { name, value } = event.target;
    let newStep = {};
    if (lang) {
      newStep = {
        ...step,
        [name]: {
          ...step[name],
          [lang]: value,
        },
      };
    } else {
      newStep = {
        ...step,
        [name]: value,
      };
    }
    console.log("newStep", newStep);
    updateStep(newStep);
  };

  const saveStep = () => {
    const { step } = state.steps;
    updateIsLoading(true);
    if (step.id) {
      dataService.patch(
        `steps/${step.id}`,
        step,
        (datas) => {
          updateStep(datas);
          updateErrors({});
          toast.success("Le step a bien été mis à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `steps`,
        step,
        (datas) => {
          updateStep(datas);
          updateErrors({});
          const { steps } = state.steps;
          updateSteps([...steps, datas]);
          toast.success("Le step a bien été créé");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteStep = (stepId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `steps/${stepId}`,
      (datas) => searchSteps(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchSteps,
    updateStepsFilters,
    updateStep,
    updateStepAttribute,
    saveStep,
    getStep,
    deleteStep,
    updateStepsPage,
    getSteps,
  };
};
