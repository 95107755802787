import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const remedyModel = () => ({
  name: languagesObject,
  steps: [""],
});

export const remedyInitialState = {
  isLoading: false,
  remedies: [],
  remediesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  remediesSearch: [],
  remedy: remedyModel(),
  errors: {},
};

export default function (state = remedyInitialState, action) {
  switch (action.type) {
    case "UPDATE_REMEDIES_FILTERS":
      return {
        ...state,
        remediesFilters: action.payload,
      };
    case "UPDATE_REMEDIES_SEARCH":
      return {
        ...state,
        remediesSearch: action.payload,
      };
    case "UPDATE_REMEDIES":
      return {
        ...state,
        remedies: action.payload,
      };

    case "UPDATE_REMEDIES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_REMEDIES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_REMEDY":
      return {
        ...state,
        remedy: action.payload,
      };
    default:
      return state;
  }
}
