import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const stoneCategoryActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORIES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORIES_LOADING",
      payload: isLoading,
    });
  };
  const updateStoneCategoriesFilters = (filters) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORIES_FILTERS",
      payload: filters,
    });
  };
  const updateStoneCategoriesPage = (filters) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORIES_FILTERS",
      payload: filters,
    });
    searchStoneCategories(filters);
  };
  const searchStoneCategories = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `stone-categories/search`,
      filters || state.stoneCategories.stoneCategoriesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_STONE_CATEGORIES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getStoneCategories = () => {
    updateIsLoading(true);
    dataService.get(
      `stone-categories`,
      updateStoneCategories,
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getStoneCategory = (stoneCategoryId) => {
    updateIsLoading(true);
    dataService.get(
      `stone-categories/${stoneCategoryId}`,
      updateStoneCategory,
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const updateStoneCategory = (stoneCategory) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORY",
      payload: stoneCategory,
    });
  };
  const updateStoneCategories = (stoneCategories) => {
    dispatch({
      type: "UPDATE_STONE_CATEGORIES",
      payload: stoneCategories,
    });
  };
  const updateStoneCategoryAttribute = ({ event, lang }) => {
    console.log("updateStoneCategoryAttribute", event, lang);
    const { stoneCategory } = state.stoneCategories;
    const { name, value } = event.target;
    let newStoneCategory = {};
    if (lang) {
      newStoneCategory = {
        ...stoneCategory,
        [name]: {
          ...stoneCategory[name],
          [lang]: value,
        },
      };
    } else {
      newStoneCategory = {
        ...stoneCategory,
        [name]: value,
      };
    }

    updateStoneCategory(newStoneCategory);
  };

  const saveStoneCategory = () => {
    const { stoneCategory } = state.stoneCategories;
    updateIsLoading(true);
    if (stoneCategory.id) {
      dataService.patch(
        `stone-categories/${stoneCategory.id}`,
        stoneCategory,
        (datas) => {
          updateStoneCategory(datas);
          updateErrors({});
          toast.success("La catégorie a bien été mise à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `stone-categories`,
        stoneCategory,
        (datas) => {
          updateStoneCategory(datas);
          updateErrors({});
          const { stoneCategories } = state.stoneCategories;
          updateStoneCategories([...stoneCategories, datas]);
          toast.success("La catégorie a bien été créée");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteStoneCategory = (stoneCategoryId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `stone-categories/${stoneCategoryId}`,
      (datas) => searchStoneCategories(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    updateIsLoading,
    searchStoneCategories,
    updateStoneCategoriesFilters,
    updateStoneCategory,
    updateStoneCategoryAttribute,
    saveStoneCategory,
    getStoneCategory,
    deleteStoneCategory,
    getStoneCategories,
    updateStoneCategoriesPage,
  };
};
