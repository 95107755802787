import React from "react";
import useSteps from "../../../../hooks/useSteps/useSteps";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const StepsFilters = () => {
  const {
    updateFilters,
    stepsState,
    stepsFilters,
    stepsErrors,
    steps_actions,
  } = useSteps();
  return (
    <FiltersContainer
      onSubmit={steps_actions.searchSteps}
      filtersState={stepsState}
      isDataLoaded={stepsState.stepsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={stepsFilters.terms}
            change={updateFilters}
            errors={stepsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default StepsFilters;
