import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const aspectModel = () => ({
  name: languagesObject,
});

export const aspectInitialState = {
  isLoading: false,
  aspects: [],
  aspectsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  aspectsSearch: [],
  aspect: aspectModel(),
  errors: {},
};

export default function (state = aspectInitialState, action) {
  switch (action.type) {
    case "UPDATE_ASPECTS_FILTERS":
      return {
        ...state,
        aspectsFilters: action.payload,
      };
    case "UPDATE_ASPECTS_SEARCH":
      return {
        ...state,
        aspectsSearch: action.payload,
      };
    case "UPDATE_ASPECTS":
      return {
        ...state,
        aspects: action.payload,
      };

    case "UPDATE_ASPECTS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_ASPECTS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_ASPECT":
      return {
        ...state,
        aspect: action.payload,
      };
    default:
      return state;
  }
}
