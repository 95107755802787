import React, { useEffect } from "react";
import { actionActions } from "../../../../context/actions/actionActions";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { stoneActions } from "../../../../context/actions/stoneActions";
import { aspectModel } from "../../../../context/reducers/aspectReducers";
import useAspects from "../../../../hooks/useAspects/useAspects";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import ChakrasDetail from "../../Chakras/ChakrasDetail/ChakrasDetail";

const AspectsDetail = (props) => {
  const { state, dispatch, aspects_actions, aspect, aspectsErrors, isLoading, constants } =
    useAspects(props);
  const { updateAspectAttribute, saveAspect, getAspect, updateAspect } = aspects_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getAspect(paramsId);
    } else {
      updateAspect(aspectModel());
    }
  }, []);

  return (
    <Page title={aspect.name?.FR || "Aspect"} back={`/aspects`} isLoading={isLoading}>
      <FormContainer onSubmit={saveAspect} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 mb-3">
            <IntlInput
              label="Nom"
              name="name"
              change={updateAspectAttribute}
              type="text"
              value={aspect.name}
              error={aspectsErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default AspectsDetail;
