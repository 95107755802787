import React, { useEffect } from "react";
import { unhappinessCategoryActions } from "../../../../context/actions/unhappinessCategoryActions";
import useUnhappinesses from "../../../../hooks/useUnhappinesses/useUnhappinesses";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import UnhappinessesFilters from "./UnhappinessesFilters";
import UnhappinessesListTable from "./UnhappinessesListTable";

const UnhappinessesList = (props) => {
  const {
    state,
    dispatch,
    unhappinessesState,
    unhappinessesSearch,
    unhappinessesFilters,
    unhappinesses_actions,
  } = useUnhappinesses();

  const { getUnhappinessCategories } = unhappinessCategoryActions(state, dispatch);

  useEffect(() => {
    getUnhappinessCategories();
  }, []);

  return (
    <Page
      title="Mal-êtres"
      errors={unhappinessesState.errors}
      isLoading={unhappinessesState.isLoading}
      action={{
        to: "unhappinesses/create",
        text: "Créer un unhappiness",
      }}
      {...props}
    >
      <div className="">
        <div className="row">
          <div className="col-6">
            Process pour la création d'un mal-être :
            <ol>
              <li>Créer les phrases de mal-être</li>
              <li>Créer les catégories de mal-être</li>
              <li>Créer les actions</li>
              <li>Créer les étapes en utilisant les actions</li>
              <li>Créer les remèdes en utilisant les étapes</li>
              <li>Rattacher les remèdes au mal-être</li>
            </ol>
          </div>
          <div className="col-6">
            Exemple pour la création du remède contre le mal au dos, pour l'associer au mal-être
            "Douleurs physiques" :<br />
            <i>
              Le remède consiste à se munir d'une Améthyste et d'un Quartz, de les recharger, de
              placer l'Améthyste 30s sur le dos, puis de placer le Quartz 30s sur le dos.
            </i>
            <ol>
              <li>
                Créer des phrases de mal-être "j'ai mal au dos", "mon dos est bloqué", "j'ai un
                lumbaguo", ...
              </li>
              <li>Créer la catégorie de mal-être "Douleurs"</li>
              <li>
                Créer les actions : "Purifier et recharger ses pierres", "Placer les pierres sur la
                zone douloureuse", ...
              </li>
              <li>
                Créer 3 étapes :{" "}
                <ul>
                  <li>
                    dans la 1ere, choisir l'action "purifier et recharger ses pierres", lui ajouter
                    les pierres "Améthyste" et "Quartz"
                  </li>
                  <li>
                    dans la 2nd, choisir l'action "Placer la pierre sur la zone douloureuse", lui
                    ajouter la pierre "Améthyste" et choisir le chakras concerné "dos"
                  </li>
                  <li>
                    dans la 3ème, choisir la même action "Placer la pierre sur la zone douloureuse",
                    lui ajouter la pierre "Quartz" et choisir le chakras concerné "dos"
                  </li>
                </ul>
                <li>
                  Créer un remède qui s'appelle "Remède contre le mal de dos" :
                  <ul>
                    <li>
                      Lui ajouter l'étape "Purifier et recharger ses pierres (Améthyste, Quartz)"
                    </li>
                    <li>
                      Lui ajouter l'étape "Placer la pierre sur la zone douloureuse (Améthyste)"
                    </li>
                    <li>Lui ajouter l'étape "Placer la pierre sur la zone douloureuse (Quartz)"</li>
                  </ul>
                </li>
              </li>
              <li>
                Créer un mal-être "Douleurs physiques" :
                <ul>
                  <li>
                    Lui ajouter les phrases de mal-être ""j'ai mal au dos", "mon dos est bloqué",
                    "j'ai un lumbaguo", ...
                  </li>
                  <li>Lui assigner la catégorie "Douleur"</li>
                  <li>Lui ajouter le remède "Remède contre le mal de dos"</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <UnhappinessesFilters />
        <div className="mt-3">
          <UnhappinessesListTable />
        </div>
        {unhappinessesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(unhappinessesSearch.count / 25)}
            page={unhappinessesFilters.page}
            changePage={(p) =>
              unhappinesses_actions.updateUnhappinessesPage({
                ...unhappinessesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default UnhappinessesList;
