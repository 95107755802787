import { useEffect } from "react";
import { reloadingActions } from "../../context/actions/reloadingActions";
import useStore from "../../context/useStore";

const useReloadings = (props) => {
  const [state, dispatch] = useStore();
  const reloadings_actions = reloadingActions(state, dispatch);
  const {
    updateReloadingsFilters,
    getReloading,
    deleteReloading,
    updateReloading,
  } = reloadings_actions;
  const reloadingsState = state.reloadings;
  const reloadingsFilters = reloadingsState.reloadingsFilters;
  const reloadingsErrors = reloadingsState.errors;
  const { reloading, isLoading, reloadingsSearch } = reloadingsState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = reloadingsState.reloadingsFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateReloadingsFilters(newFilters);
  };

  return {
    state,
    dispatch,
    constants: state.constants.items,
    reloadings_actions,
    reloadingsState,
    reloadingsSearch,
    updateFilters,
    reloadingsFilters,
    reloadingsErrors,
    reloading,
    isLoading,
    languages: state.languages.languages,
    deleteReloading,
  };
};

export default useReloadings;
