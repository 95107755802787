import React, { useState } from "react";
import Loader from "../Loader";

const DeleteBtn = ({
  style = {},
  deleteAction,
  typeBtn = "button",
  className = "",
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const btnClick = (e) => {
    if (confirmDelete) {
      deleteAction(e);
      setIsLoading(true);
      setConfirmDelete(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } else {
      setConfirmDelete(true);
    }
  };

  return (
    <button
      className={`btn btn-light btn-sm ${className}`}
      type={typeBtn}
      style={{ ...style }}
      onClick={btnClick}
    >
      {isLoading ? (
        <Loader color={"white"} />
      ) : (
        <i className={`fa ${confirmDelete ? "fa-check" : "fa-times"}`} />
      )}
    </button>
  );
};

export default DeleteBtn;
