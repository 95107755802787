import React from "react";
import { Link } from "react-router-dom";
import useUnhappinessCategories from "../../../../hooks/useUnhappinessCategories/useUnhappinessCategories";
import Table from "../../../common/Table/Table";

const UnhappinessCategoriesListTable = () => {
  const {
    isLoading,
    unhappinessCategoriesSearch,
    languages,
    unhappinessCategories_actions,
    constants,
  } = useUnhappinessCategories();

  return (
    <Table
      isLoading={isLoading}
      nbResults={unhappinessCategoriesSearch.count}
      datas={unhappinessCategoriesSearch.data}
      languages={languages}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Musique",
          path: "music",
          render: (el) => constants.MUSICS.find((m) => m.id == el).name,
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link
                  className="btn btn-default btn-sm mr-1"
                  to={`/unhappiness-categories/${el.id}`}
                >
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() =>
                    unhappinessCategories_actions.deleteUnhappinessCategory(
                      el.id
                    )
                  }
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default UnhappinessCategoriesListTable;
