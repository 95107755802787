import React from "react";
import useColors from "../../../../hooks/useColors/useColors";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import ColorsFilters from "./ColorsFilters";
import ColorsListTable from "./ColorsListTable";

const ColorsList = () => {
  const { colorsState, colorsSearch, colorsFilters, colors_actions } =
    useColors();

  return (
    <Page
      title="Couleurs de pierres"
      errors={colorsState.errors}
      isLoading={colorsState.isLoading}
      action={{
        to: "colors/create",
        text: "Créer une couleur",
      }}
    >
      <div className="">
        <ColorsFilters />
        <div className="mt-3">
          <ColorsListTable />
        </div>
        {colorsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(colorsSearch.count / 25)}
            page={colorsFilters.page}
            changePage={(p) =>
              colors_actions.updateColorsPage({
                ...colorsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ColorsList;
