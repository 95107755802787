import React from "react";
import useChakras from "../../../../hooks/useChakras/useChakras";
import Page from "../../../common/layout/Page";
import useTopMenu from "../../../common/layout/TopMenu/useTopMenu";
import Pagination from "../../../common/Pagination/Pagination";
import ChakrasFilters from "./ChakrasFilters";
import ChakrasListTable from "./ChakrasListTable";

const ChakrasList = (props) => {
  const { chakrasState, chakrasSearch, chakrasFilters, chakras_actions } =
    useChakras();

  return (
    <Page
      title="Chakras"
      errors={chakrasState.errors}
      isLoading={chakrasState.isLoading}
      action={{
        to: "chakras/create",
        text: "Créer un chakra",
      }}
      {...props}
    >
      <div className="">
        <ChakrasFilters />
        <div className="mt-3">
          <ChakrasListTable />
        </div>
        {chakrasSearch.count ? (
          <Pagination
            nbPages={Math.ceil(chakrasSearch.count / 25)}
            page={chakrasFilters.page}
            changePage={(p) =>
              chakras_actions.updateChakrasPage({
                ...chakrasFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ChakrasList;
