import axios from "axios";
import fileDownload from "js-file-download";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import dataService from "../../../helpers/dataService";
import Loader from "../Loader";
import SaveBtn from "../SaveBtn/SaveBtn";
import AudioPreview from "./AudioPreview";
import ExistingFile from "./ExistingFile";
import ImagePreview from "./ImagesPreview";

const AudioUpload = ({
  noFixedHeight = false,
  onFileDrop,

  isLoading,
  updateAuthorized = () => {},
}) => {
  const [currentFiles, setCurrentFiles] = useState(null);
  const onDrop = (files) => {
    console.log("audio", files);
    // Do something with the files
    const authorizedFormats = ["audio/mpeg"];
    console.log(files);
    if (files.length > 0) {
      let hasErrors = false;
      if (files.length > 1) {
        toast.error(`Un seul fichier autorisé`);
        hasErrors = true;
      }

      if (!authorizedFormats.includes(files[0].type)) {
        toast.error(`${files[0].name} n'a pas le bon format (mp3)`);
        hasErrors = true;
      }

      if (!hasErrors) {
        setCurrentFiles(files[0]);
      }
    } else {
      alert("Le fichier choisi est incorrect. Le format accepté est mp3.");
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const upldoadFile = () => {
    let data = new FormData();

    data.append("files", currentFiles);

    onFileDrop(data);
    cancelFile();
  };

  const cancelFile = () => {
    setCurrentFiles(null);
  };

  return (
    <>
      {currentFiles ? (
        <div className="d-flex align-items-center justify-content-between h-100 w-100 flex-column">
          <AudioPreview file={currentFiles} />

          <div className="d-center justify-content-around w-100 flex-row flex-fill my-3">
            <button className="btn btn-light" onClick={cancelFile}>
              Annuler
            </button>
            <SaveBtn save={upldoadFile} type="success" saving={false} text={"Envoyer"} />
          </div>
        </div>
      ) : (
        <div
          className="d-center text-center p-3 w-100  rounded cursor-pointer"
          style={{
            height: noFixedHeight ? "auto" : "100%",
            border: "1px dashed grey",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Déposez le fichier ici ...</p>
          ) : (
            <div>
              Glissez-déposez un fichier mp3 <br />
              ou cliquez pour parcourir vos dossiers
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AudioUpload;
