import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";

const BtnListInput = (props) => {
  const { name, value, k, btnInline, datas, dataIndex, change } = props;
  const [isOpen, setIsOpen] = useState(!value);

  const valueChange = (e, val, isExclusive) => {
    var filteredValue = val;

    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        k: props.k,
      },
    };
    setIsOpen(false);
    change(changeEvent);
  };

  useEffect(() => {
    setIsOpen(value ? false : true);
  }, [value]);

  return (
    <LabelContainer {...props}>
      <div
        className={
          " d-flex flex-fill bg-light rounded " +
          (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")
        }
      >
        {datas
          .sort(
            (a, b) => {
              return a[dataIndex] == value ? -1 : 1;
            },
            { [dataIndex]: "" }
          )
          .map((data, dk) => {
            let isActive = data[dataIndex] == value;
            return (
              isActive && (
                <ButtonItem
                  key={"btnlist" + props.name + props.id + dk}
                  {...props}
                  isActive={isActive}
                  data={data}
                  dk={dk}
                  valueChange={valueChange}
                />
              )
            );
          })}
        {isOpen &&
          datas
            .sort(
              (a, b) => {
                return a[dataIndex] == value ? -1 : 1;
              },
              { [dataIndex]: "" }
            )
            .map((data, dk) => {
              let isActive = data[dataIndex] == value;

              return (
                isOpen &&
                !isActive && (
                  <ButtonItem
                    key={"btnlist" + props.name + props.id + dk}
                    {...props}
                    data={data}
                    dk={dk}
                    valueChange={valueChange}
                  />
                )
              );
            })}
        {!isOpen && (
          <button
            className="btn btn-sm btn-outline-default border-0 my-1 mx-1"
            onClick={() => setIsOpen(true)}
          >
            <i className="fa fa-pen" />
            <small style={{ position: "relative", top: 6, left: -2 }}>
              {datas.length - (value ? 1 : 0)}
            </small>
          </button>
        )}
        <button
          type="button"
          className="btn btn-sm btn-outline-default border-0 my-1 mx-1"
          onClick={(e) => valueChange(e, null)}
        >
          <i>vider</i>
        </button>
      </div>
    </LabelContainer>
  );
};

export default BtnListInput;
