import React, { useEffect } from "react";
import { chakraActions } from "../../../../context/actions/chakraActions";
import { colorActions } from "../../../../context/actions/colorActions";
import { purificationActions } from "../../../../context/actions/purificationActions";
import { reloadingActions } from "../../../../context/actions/reloadingActions";
import { actionActions } from "../../../../context/actions/actionActions";
import { stoneActions } from "../../../../context/actions/stoneActions";
import { actionModel } from "../../../../context/reducers/actionReducers";
import useActions from "../../../../hooks/useActions/useActions";
import Control from "../../../common/Controls/Control";
import FileUpload from "../../../common/FileUpload/FileUpload";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import Formula from "../../../common/Formula/Formula";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import ActionFilesManager from "./ActionFileManager";

const ActionsDetail = (props) => {
  const { state, dispatch, actions_actions, action, actionsErrors, isLoading, constants } =
    useActions(props);
  const { updateActionAttribute, saveAction, getAction, updateAction } = actions_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getAction(paramsId);
    } else {
      updateAction(actionModel());
    }
  }, []);

  return (
    <Page title={action.name?.FR || "Action"} back={`/actions`} isLoading={isLoading}>
      <FormContainer onSubmit={saveAction} isLoading={isLoading}>
        <div className="row">
          <div className="col-12">
            <IntlInput
              label="Nom"
              name="name"
              change={updateActionAttribute}
              type="textarea"
              value={action.name}
              error={actionsErrors}
            />
          </div>
          <div className="col-12">
            <ActionFilesManager />
            {actionsErrors.image && <div className="alert alert-danger">{actionsErrors.image}</div>}
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default ActionsDetail;
