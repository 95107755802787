import axios from "axios";
import { atom, useAtom } from "jotai";
import { PROMPTS, PROMPT_TYPES } from "./prompts.types";
import { toast } from "react-toastify";
import AssistantPromptForm from "../PromptForms/AssistantPromptForm";
import useStore from "../../../../context/useStore";
import { rightPanelActions } from "../../../../context/actions/rightPanelActions";
import { ASSISTANT_URL } from "../../../../config";

export const promptAtom = atom("");
export const nbWordsAtom = atom("");
export const nbImagesAtom = atom("");
export const imageSizeAtom = atom("");
export const isLoadingAtom = atom(false);
export const resultAtom = atom(null);
export const promptModelAtom = atom(null);
export const modelParamsAtom = atom(null);

const useAssistant = () => {
  const [state, dispatch] = useStore();
  const { updateRightPanel } = rightPanelActions(state, dispatch);
  const [prompt, setPrompt] = useAtom(promptAtom);
  const [nbWords, setNbWords] = useAtom(nbWordsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [result, setResult] = useAtom(resultAtom);
  const [promptModel, setPromptModel] = useAtom(promptModelAtom);
  const [modelParams, setModelParams] = useAtom(modelParamsAtom);
  const [nbImages, setNbImages] = useAtom(nbImagesAtom);
  const [imageSize, setImageSize] = useAtom(imageSizeAtom);

  const assistantActions = {
    onOpenAssistant: (promptModelIn, modelParamsIn = {}) => {
      assistantActions.getModelParams(promptModelIn, modelParamsIn);
      setPromptModel(promptModelIn);
      setModelParams(modelParamsIn);
      setResult(null);

      updateRightPanel({
        isOpen: true,
        content: <AssistantPromptForm promptModel={promptModelIn} />,
      });
    },
    getModelParams: (promptModel, modelParams) => {
      if (promptModel) {
        let promptType = PROMPTS[promptModel].promptType;
        let rawPrompt = PROMPTS[promptModel].prompt[modelParams.lang];
        //replace all variables between {{}} by their value in the modelParams object
        for (const [key, value] of Object.entries(modelParams)) {
          rawPrompt = rawPrompt.replace(new RegExp(`{{${key}}}`, "g"), value);
        }
        console.log("promptType", promptType);
        switch (promptType) {
          case PROMPT_TYPES.TEXT:
            setPrompt(rawPrompt);
            setNbWords(PROMPTS[promptModel].nbWords);
            break;
          case PROMPT_TYPES.IMAGE:
            setPrompt(rawPrompt);
            console.log(
              "PROMPTS[promptModel].nbImages",
              PROMPTS[promptModel].nbImages
            );
            setNbImages(PROMPTS[promptModel].nbImages);
            setImageSize(PROMPTS[promptModel].imageSize);
            break;
          default:
            break;
        }
      }
    },

    onPromptTextAssistant: async (prompt, nbWords) => {
      setIsLoading(true);
      try {
        const result = await axios.post(
          ASSISTANT_URL + "lithotherapy/text-generation",
          {
            prompt: prompt,
            nbWords,
          }
        );
        setResult(result.data?.output);
      } catch (err) {
        console.log(err);
        toast.error("Erreur lors de la génération");
      }
      setIsLoading(false);
    },
    onPromptImageAssistant: async (prompt, nbImages, imageSize) => {
      setIsLoading(true);
      try {
        const result = await axios.post(
          ASSISTANT_URL + "lithotherapy/image-generation",
          {
            prompt: prompt,
            nbImages,
            imageSize,
          }
        );
        setResult(result.data);
      } catch (err) {
        console.log(err);
        toast.error("Erreur lors de la génération");
      }
      setIsLoading(false);
    },
    onPromptTranslateAssistant: async (prompt, toLang) => {
      setIsLoading(true);
      try {
        const result = await axios.post(ASSISTANT_URL + "translation", {
          prompt: prompt,
          toLang,
        });
        setResult(result.data?.output);
        setIsLoading(false);
        return result.data?.output;
      } catch (err) {
        console.log(err);
        toast.error("Erreur lors de la traduction");
        return null;
      }
      setIsLoading(false);
    },
  };

  return {
    assistantActions,
    prompt,
    setPrompt,
    nbWords,
    setNbWords,
    isLoading,
    result,
    promptModel,
    modelParams,
    nbImages,
    setNbImages,
    imageSize,
    setImageSize,
  };
};

export default useAssistant;
