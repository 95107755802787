import React, { useEffect } from "react";
import {
  unhappinessSentenceInitialState,
  unhappinessSentenceModel,
} from "../../../../context/reducers/unhappinessSentenceReducers";
import useStore from "../../../../context/useStore";
import useUnhappinessSentences from "../../../../hooks/useUnhappinessSentences/useUnhappinessSentences";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";

const UnhappinessSentencesDetail = (props) => {
  const {
    state,
    unhappinessSentences_actions,
    unhappinessSentence,
    unhappinessSentencesErrors,
    isLoading,
  } = useUnhappinessSentences(props);
  const {
    updateUnhappinessSentenceAttribute,
    saveUnhappinessSentence,
    getUnhappinessSentence,
    updateUnhappinessSentence,
  } = unhappinessSentences_actions;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getUnhappinessSentence(paramsId);
    } else {
      updateUnhappinessSentence(unhappinessSentenceModel());
    }
  }, []);

  return (
    <Page
      title={"Phrase de mal-être"}
      back={`/unhappiness-sentences`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveUnhappinessSentence} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="sentence"
          change={updateUnhappinessSentenceAttribute}
          type="textarea"
          value={unhappinessSentence.sentence}
          error={unhappinessSentencesErrors}
        />
      </FormContainer>
    </Page>
  );
};

export default UnhappinessSentencesDetail;
