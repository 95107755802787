import React from "react";
import tools from "../../../../helpers/tools";

const TextResult = ({ text }) => {
  const ref = React.useRef(null);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-default btn-sm"
          onClick={() => tools.copyText(ref)}
        >
          <i className="fa fa-copy" />
        </button>
      </div>
      <textarea
        ref={ref}
        className="form-control"
        rows="5"
        value={text}
        readOnly
        style={{ height: 800 }}
      />
    </div>
  );
};

export default TextResult;
