import React from "react";

const Pagination = ({ page, nbPages, changePage }) => {
  var pages = [];
  var pageMin = page - 2 < 1 ? 1 : page - 2;
  var pageMax = page + 2 > nbPages ? nbPages : page + 2;
  for (let i = pageMin; i <= pageMax; i++) {
    pages.push(
      <li
        key={"page" + i}
        className={"page-item " + (page == i ? "active" : "")}
      >
        <button className="page-link" onClick={() => changePage(i)}>
          {i}
        </button>
      </li>
    );
  }

  return nbPages == 1 ? (
    <></>
  ) : (
    <>
      <div className="text-center text-muted mb-2">
        <small>
          page {page}/{nbPages}
        </small>
      </div>
      <ul className="pagination justify-content-center">
        {page > 1 ? (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(1)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => changePage(page - 1)}
              >
                <span aria-hidden="true">&lsaquo;</span>
              </button>
            </li>
          </>
        ) : null}

        {pages}
        {page < nbPages ? (
          <>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => changePage(page + 1)}
              >
                <span aria-hidden="true">&rsaquo;</span>
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(nbPages)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </>
        ) : null}
      </ul>
    </>
  );
};

export default Pagination;
