import React from "react";
import usePurifications from "../../../../hooks/usePurifications/usePurifications";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const PurificationsFilters = () => {
  const {
    updateFilters,
    purificationsState,
    purificationsFilters,
    purificationsErrors,
    purifications_actions,
  } = usePurifications();

  return (
    <FiltersContainer
      onSubmit={purifications_actions.searchPurifications}
      filtersState={purificationsState}
      isDataLoaded={purificationsState.purificationsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={purificationsFilters.terms}
            change={updateFilters}
            errors={purificationsErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default PurificationsFilters;
