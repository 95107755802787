import React from "react";
import useStoneCategories from "../../../../hooks/useStoneCategories/useStoneCategories";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import StoneCategoriesFilters from "./StoneCategoriesFilters";
import StoneCategoriesListTable from "./StoneCategoriesListTable";

const StoneCategoriesList = () => {
  const {
    stoneCategoriesState,
    stoneCategoriesSearch,
    stoneCategoriesFilters,
    stone_categories_actions,
  } = useStoneCategories();

  return (
    <Page
      title="Catégories de pierres"
      errors={stoneCategoriesState.errors}
      isLoading={stoneCategoriesState.isLoading}
      action={{
        to: "stone-categories/create",
        text: "Créer une catégorie de pierre",
      }}
    >
      <div className="">
        <StoneCategoriesFilters />
        <div className="mt-3">
          <StoneCategoriesListTable />
        </div>
        {stoneCategoriesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(stoneCategoriesSearch.count / 25)}
            page={stoneCategoriesFilters.page}
            changePage={(p) =>
              stone_categories_actions.updateStoneCategoriesPage({
                ...stoneCategoriesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default StoneCategoriesList;
