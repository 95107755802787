import React from "react";
import useRemedies from "../../../../hooks/useRemedies/useRemedies";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const RemediesFilters = () => {
  const {
    updateFilters,
    remediesState,
    remediesFilters,
    remediesErrors,
    remedies_actions,
  } = useRemedies();
  return (
    <FiltersContainer
      onSubmit={remedies_actions.searchRemedies}
      filtersState={remediesState}
      isDataLoaded={remediesState.remediesSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={remediesFilters.terms}
            change={updateFilters}
            errors={remediesErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default RemediesFilters;
