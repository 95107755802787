import React from "react";

const ImageResult = ({ images }) => {

  const handleImageClick = (image) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${image.buffer}`;
    link.download = 'image' + image.id + '.png';
    link.click();
  };

  return (
    <div className="mt-3">
      {images.map((image, index) => {
        return (
          <div key={index} className="d-center p-2 my-2 btn btn-default">
            <img
              id={`image-${image.id}`}
              src={`data:image/png;base64,${image.buffer}`}
              alt=""
              onClick={() => handleImageClick(image)}
              style={{
                objectFit: "cover",
                width: 256,
                height: 256,
                borderRadius: 10,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ImageResult;
