import { useEffect } from "react";
import { unhappinessActions } from "../../context/actions/unhappinessActions";
import useStore from "../../context/useStore";

const useUnhappinesses = (props) => {
  const [state, dispatch] = useStore();
  const unhappinesses_actions = unhappinessActions(state, dispatch);
  const { updateUnhappinessesFilters, getUnhappiness, deleteUnhappiness } =
    unhappinesses_actions;
  const unhappinessesState = state.unhappinesses;
  const unhappinessesFilters = unhappinessesState.unhappinessesFilters;
  const unhappinessesErrors = unhappinessesState.errors;
  const { unhappiness, isLoading, unhappinessesSearch } = unhappinessesState;

  const updateFilters = (e) => {
    const { name, value } = e.target;
    const filtersObject = unhappinessesState.unhappinessesFilters;
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    updateUnhappinessesFilters(newFilters);
  };

  useEffect(() => {
    if (props && props?.match?.params?.id != "create") {
      getUnhappiness(props.match.params.id);
    }
  }, []);

  return {
    state,
    dispatch,
    constants: state.constants.items,
    unhappinesses_actions,
    unhappinessesState,
    unhappinessesSearch,
    updateFilters,
    unhappinessesFilters,
    unhappinessesErrors,
    unhappiness,
    isLoading,
    languages: state.languages.languages,
    deleteUnhappiness,
  };
};

export default useUnhappinesses;
