import React from "react";
import useAssistant from "../useAssistant/useAssistant";
import { PROMPTS, PROMPT_TYPES } from "../useAssistant/prompts.types";
import TextResult from "./TextResult";
import ImageResult from "./ImageResult";

const AssistantResult = () => {
  const { promptModel, result } = useAssistant();

  if (!result) return null;

  const promptType = PROMPTS[promptModel].promptType;
  switch (promptType) {
    case PROMPT_TYPES.TEXT:
      return <TextResult text={result} />;
    case PROMPT_TYPES.IMAGE:
      return <ImageResult images={result} />;
    default:
      return <TextResult text={result} />;
  }
};

export default AssistantResult;
