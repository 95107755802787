import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const stoneModel = () => ({
  name: languagesObject,
  formula: "",
  stoneCategory: "",
  crystalSystem: "",
  mohsScale: "",
  colors: [""],
  chakras: [""],
  reloadings: [""],
  purifications: [""],
  anecdotes: [],
  description: languagesObject,
  images: [],
  aspects: [],
  visible: false,
  astrologicalSigns: [""],
  audioDescription: {
    audioName: languagesObject,
    readBy: "",
    file: null,
  },
});

export const stoneInitialState = {
  isLoading: false,
  stones: [],
  stonesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  stonesSearch: [],
  stone: stoneModel(),
  errors: {},
};

export default function (state = stoneInitialState, action) {
  switch (action.type) {
    case "UPDATE_STONES_FILTERS":
      return {
        ...state,
        stonesFilters: action.payload,
      };
    case "UPDATE_STONES_SEARCH":
      return {
        ...state,
        stonesSearch: action.payload,
      };
    case "UPDATE_STONES":
      return {
        ...state,
        stones: action.payload,
      };

    case "UPDATE_STONES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_STONES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_STONE":
      return {
        ...state,
        stone: action.payload,
      };
    default:
      return state;
  }
}
