import React from "react";
import useStones from "../../../../hooks/useStones/useStones";
import AudioPreview from "../../../common/FileUpload/AudioPreview";
import AudioUpload from "../../../common/FileUpload/AudioUpload";

const StoneAudioManager = () => {
  const { stones_actions, stone, isLoading } = useStones();
  const { uploadStoneAudioDescription, deleteStoneAudio } = stones_actions;

  return (
    <div className="d-center bg-light flex-column rounded ">
      {stone.id ? (
        <>
          {stone.audioDescription?.file ? (
            <div className="d-center flex-column p-3 w-100">
              <AudioPreview file={stone.audioDescription.file} />
              <div className="mt-2">
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() =>
                    deleteStoneAudio({ stoneId: stone.id, audioId: stone.audioDescription.file.id })
                  }
                >
                  Supprimer le fichier audio
                </button>
              </div>
            </div>
          ) : (
            <AudioUpload
              onFileDrop={(file) =>
                uploadStoneAudioDescription({
                  file,
                  stoneId: stone.id,
                })
              }
              isLoading={isLoading}
            />
          )}
        </>
      ) : (
        <p>Enregistrez la pierre avant de pouvoir envoyer une description audio</p>
      )}
    </div>
  );
};

export default StoneAudioManager;
