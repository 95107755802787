import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const crystalSystemModel = () => ({
  name: languagesObject,
});

export const crystalSystemInitialState = {
  isLoading: false,
  crystalSystems: [],
  crystalSystemsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  crystalSystemsSearch: [],
  crystalSystem: crystalSystemModel(),
  errors: {},
};

export default function (state = crystalSystemInitialState, action) {
  switch (action.type) {
    case "UPDATE_CRYSTAL_SYSTEMS_FILTERS":
      return {
        ...state,
        crystalSystemsFilters: action.payload,
      };
    case "UPDATE_CRYSTAL_SYSTEMS_SEARCH":
      return {
        ...state,
        crystalSystemsSearch: action.payload,
      };
    case "UPDATE_CRYSTAL_SYSTEMS":
      return {
        ...state,
        crystalSystems: action.payload,
      };

    case "UPDATE_CRYSTAL_SYSTEMS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CRYSTAL_SYSTEMS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_CRYSTAL_SYSTEM":
      return {
        ...state,
        crystalSystem: action.payload,
      };
    default:
      return state;
  }
}
