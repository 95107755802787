const tokenize = (formula) => {
  return formula
    ? formula
        .toUpperCase()
        .split(/(\d+)/)
        .map((token, idx) => (idx % 2 ? <sub key={idx}>{token}</sub> : token))
    : "";
};

const Formula = ({ text }) => <span className="mr-2">{tokenize(text)}</span>;

export default Formula;
