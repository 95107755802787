import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const unhappinessSentenceModel = () => ({
  sentence: languagesObject,
});

export const unhappinessSentenceInitialState = {
  isLoading: false,
  unhappinessSentences: [],
  unhappinessSentencesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  unhappinessSentencesSearch: [],
  unhappinessSentence: unhappinessSentenceModel(),
  errors: {},
};

export default function (state = unhappinessSentenceInitialState, action) {
  switch (action.type) {
    case "UPDATE_UNHAPPINESS_SENTENCES_FILTERS":
      return {
        ...state,
        unhappinessSentencesFilters: action.payload,
      };
    case "UPDATE_UNHAPPINESS_SENTENCES_SEARCH":
      return {
        ...state,
        unhappinessSentencesSearch: action.payload,
      };
    case "UPDATE_UNHAPPINESS_SENTENCES":
      return {
        ...state,
        unhappinessSentences: action.payload,
      };

    case "UPDATE_UNHAPPINESS_SENTENCES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_UNHAPPINESS_SENTENCES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "UPDATE_UNHAPPINESS_SENTENCE":
      return {
        ...state,
        unhappinessSentence: action.payload,
      };
    default:
      return state;
  }
}
