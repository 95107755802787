import React from "react";
import { Link } from "react-router-dom";
import useAspects from "../../../../hooks/useAspects/useAspects";
import Table from "../../../common/Table/Table";

const AspectsListTable = () => {
  const { isLoading, aspectsSearch, languages, aspects_actions, state } = useAspects();

  return (
    <Table
      isLoading={isLoading}
      nbResults={aspectsSearch.count}
      datas={aspectsSearch.data}
      languages={languages}
      fields={[
        {
          name: "Aspect",
          path: "name",
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-default btn-sm mr-1" to={`/aspects/${el.id}`}>
                  Ouvrir
                </Link>
                <button
                  className="btn text-danger btn-sm"
                  onClick={() => aspects_actions.deleteAspect(el.id)}
                >
                  <i className="fa fa-times" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default AspectsListTable;
