import { useEffect, useState } from "react";
import colors from "../../../helpers/colors";
import DeleteBtn from "../DeleteBtn/DeleteBtn";

const ImagesPreview = ({
  uniqId = "imagepreviewer",
  className = "",
  files,
  deleteImage = null,
  setMainImage = null,
  setThumbnail = null,
  moveImagePosition,
}) => {
  const [mouseOverIdx, setMouseOverIdx] = useState(null);
  const [objectsUrls, setObjectUrls] = useState([]);
  useEffect(() => {
    if (files) {
      setObjectUrls(
        Array.from(files).map((file) => {
          return file.file ? file.file.url : URL.createObjectURL(file);
        })
      );
    }
  }, [files]);

  const scrollToImage = (imageIdx) => {
    var imageElem = document.querySelector(`#imageview${uniqId}${imageIdx}`);
    var container = document.querySelector(`#image-preview-container${uniqId}`);
    var wrapper = document.querySelector(`#image-preview-wrapper${uniqId}`);
    if (imageElem && container && wrapper) {
      let wrapperImgDiff = (wrapper.clientWidth - imageElem.offsetWidth) / 2;
      container.scrollLeft = imageElem.offsetLeft - wrapperImgDiff;
    }
  };

  const mouseOverNavItem = (itemIdx) => {
    scrollToImage(itemIdx);
    setMouseOverIdx(itemIdx);
  };
  const mouseOutNavItem = () => {
    setMouseOverIdx(null);
  };

  const hasImage = objectsUrls.length ? true : false;

  return (
    <div className={`position-relative w-100 ${className}`} id={`image-preview-wrapper${uniqId}`}>
      <div
        className=""
        id={`image-preview-container${uniqId}`}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
          height: hasImage ? 400 : 50,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        {!hasImage && <p className="d-center h-100">Aucune image pour le moment</p>}
        {objectsUrls.map((urlObj, uk) => {
          var file = files[uk] && files[uk].file ? files[uk] : null;
          return (
            <>
              <div
                id={`imageview${uniqId}${uk}`}
                className="d-inline-block position-relative"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  backgroundImage: `url(${urlObj})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >
                {file && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 5,
                    }}
                  >
                    {file.isMain && (
                      <span className="badge badge-primary">
                        <i className="fa fa-star mr-1" />
                        principale
                      </span>
                    )}
                    {file.isThumbnail && (
                      <span className="badge badge-primary">
                        <i className="fa fa-file-image mr-1" />
                        miniature
                      </span>
                    )}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>

      {hasImage && (
        <div
          className="text-center"
          style={{
            overflowX: "scroll",
            whiteSpace: "nowrap",
          }}
        >
          {objectsUrls.map((urlObj, uk) => {
            let isMouseOver = mouseOverIdx == uk;
            return (
              <div
                className="m-2 position-relative d-inline-block animated fadeInUp fast"
                style={{
                  animationDelay: `${uk * 50}ms`,
                }}
              >
                <div
                  onMouseOver={() => mouseOverNavItem(uk)}
                  //onMouseOut={() => mouseOutNavItem(uk)}
                  className="rounded d-block cursor-pointer"
                  src={urlObj}
                  style={{
                    maxWidth: "100%",
                    height: 40,
                    width: 80,
                    backgroundColor: "white",
                    backgroundImage: `url(${urlObj})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transition: "500ms",
                    transform: isMouseOver ? "translateY(-5px)" : "translateY(0)",
                    boxShadow: isMouseOver ? `0px 0px 18px 4px ${colors.dark}` : "none",
                  }}
                ></div>

                {files[uk] && files[uk].file && (
                  <>
                    <div className="d-flex align-items-start justify-content-between flex-row bg-content p-1">
                      <i
                        className={`cursor-pointer fa fa-star ${
                          files[uk].isMain ? "text-primary" : "text-defaut"
                        }`}
                        title="Définir comme image principale"
                        onClick={() => setMainImage(files[uk].file.id)}
                        style={{ paddingTop: 2 }}
                      />
                      <i
                        className={`cursor-pointer fa fa-file-image ${
                          files[uk].isThumbnail ? "text-primary" : "text-defaut"
                        }`}
                        title="Définir comme image miniature"
                        onClick={() => setThumbnail(files[uk].file.id)}
                        style={{ paddingTop: 2 }}
                      />
                      <DeleteBtn
                        style={{ fontSize: 21 }}
                        className="d-center text-danger bg-trans p-0 m-0  btn-sm"
                        deleteAction={() => deleteImage(files[uk].file.id)}
                      />
                    </div>
                    <div className="d-flex align-items-start justify-content-between flex-row bg-content p-1">
                      <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() =>
                          moveImagePosition(uk, uk - 1 < 0 ? objectsUrls.length - 1 : uk - 1)
                        }
                      />
                      <i
                        className="fa fa-arrow-right cursor-pointer"
                        onClick={() =>
                          moveImagePosition(uk, uk + 1 == objectsUrls.length ? 0 : uk + 1)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImagesPreview;
