import React, { useEffect } from "react";
import {
  reloadingInitialState,
  reloadingModel,
} from "../../../../context/reducers/reloadingReducers";
import useStore from "../../../../context/useStore";
import useReloadings from "../../../../hooks/useReloadings/useReloadings";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer.js/FormContainer";
import IntlInput from "../../../common/IntlInput/IntlInput";
import Page from "../../../common/layout/Page";
import SimpleDynamicList from "../../../common/SimpleDynamicList";

const ReloadingsDetail = (props) => {
  const { reloadings_actions, reloading, reloadingsErrors, isLoading, state } =
    useReloadings(props);
  const {
    updateReloadingAttribute,
    saveReloading,
    getReloading,
    updateReloading,
  } = reloadings_actions;
  const constants = state.constants.items;

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      getReloading(paramsId);
    } else {
      updateReloading(reloadingModel());
    }
  }, []);

  return (
    <Page
      title={reloading.name?.FR || "Rechargements"}
      back={`/reloadings`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={saveReloading} isLoading={isLoading}>
        <IntlInput
          label="Nom"
          name="name"
          change={updateReloadingAttribute}
          type="text"
          value={reloading.name}
          error={reloadingsErrors}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Page liée"
              name="page"
              value={reloading.page}
              change={(event) => updateReloadingAttribute({ event })}
              type="btnList"
              btnInline
              datas={constants.PAGES.filter((p) => p.category == "RELOADING")}
              dataIndex="id"
              dataLabel="name"
              error={reloadingsErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <SimpleDynamicList
              label="Sons"
              name="sounds"
              value={reloading.sounds}
              change={(event) => updateReloadingAttribute({ event })}
              btnLabel="Ajouter un son"
              field={{
                type: "select",
                name: "sound",
                datas: constants.SOUNDS,
              }}
              error={reloadingsErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default ReloadingsDetail;
