import tools from "../../helpers/tools";

let languagesObject = tools.languagesObject();

export const colorModel = () => ({
  name: languagesObject,
  color: "",
  sounds: [],
});

export const colorInitialState = {
  isLoading: false,
  colors: [],
  colorsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  colorsSearch: [],
  color: colorModel(),
  errors: {},
};

export default function (state = colorInitialState, action) {
  switch (action.type) {
    case "UPDATE_COLORS_FILTERS":
      return {
        ...state,
        colorsFilters: action.payload,
      };
    case "UPDATE_COLORS_SEARCH":
      return {
        ...state,
        colorsSearch: action.payload,
      };
    case "UPDATE_COLORS":
      return {
        ...state,
        colors: action.payload,
      };

    case "UPDATE_COLORS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_COLORS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_COLOR":
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
}
