import React from "react";
import useChakras from "../../../../hooks/useChakras/useChakras";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";

const ChakrasFilters = () => {
  const {
    updateFilters,
    chakrasState,
    chakrasFilters,
    chakrasErrors,
    chakras_actions,
  } = useChakras();
  return (
    <FiltersContainer
      onSubmit={chakras_actions.searchChakras}
      filtersState={chakrasState}
      isDataLoaded={chakrasState.chakrasSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            label="Rechercher"
            name="terms"
            type="search"
            value={chakrasFilters.terms}
            change={updateFilters}
            errors={chakrasErrors}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ChakrasFilters;
