import React, { useEffect } from "react";
import { rightPanelActions } from "../../context/actions/rightPanelActions";
import useStore from "../../context/useStore";
import tools from "../../helpers/tools";
import Control from "./Controls/Control";

const SimpleDynamicList = ({
  label,
  uniqueKey,
  name,
  field,
  value,
  btnLabel,
  change,
  className = "",
  entityComponent = null,
}) => {
  const [state, dispatch] = useStore();
  const rpanel_actions = rightPanelActions(state, dispatch);

  const openEntityComponent = () => {
    if (!entityComponent) return false;
    rpanel_actions.updateRightPanel({
      isOpen: true,
      content: entityComponent,
    });
  };

  function addItem() {
    value.push("");
    change({ target: { name: name, value: value } });
  }

  function changeItem(e) {
    value[e.target.k] = e.target.value;
    change({ target: { name: name, value: value } });
  }
  function removeItem(kk) {
    value = value.filter((i, k) => k !== kk);
    change({ target: { name: name, value: value } });
  }

  useEffect(() => {
    checkDoublons();
  });

  function checkDoublons() {
    //let doublons = tools.findDoublonsInArray(value);
  }

  return (
    <div className={`form-group ${className}`}>
      <div className="d-flex align-items-center justify-content-start mb-1">
        {btnLabel && (
          <button
            className="btn btn-outline-default mr-1 btn-sm"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addItem();
            }}
          >
            <i className="fa fa-plus" />
          </button>
        )}

        <label
          className="w-auto pb-0"
          style={{ paddingBottom: 7 }}
          onDoubleClick={openEntityComponent}
        >
          <span className="label-text pb-0">{label}</span>
        </label>
      </div>
      <ul className="list-group list-unstyled d-flex flex-row justify-content-start align-items-center flex-wrap">
        {!value ? (
          <p className="alert alert-warning">aucune valeur</p>
        ) : (
          value.map((it, k) => (
            <li className="mr-2" key={"dyn" + name + uniqueKey + "_" + k}>
              <div className="d-flex align-items-center ">
                <Control
                  className={"mr-0 "}
                  k={k}
                  labelClassname="mb-0 mt-0  text-black-50"
                  innerLabelClassname="badge badge-secondary"
                  inputStyle={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  label={field.label && `${field.label} ${parseInt(k) + 1}`}
                  name={field.name}
                  type={field.type}
                  value={it}
                  datas={field.datas}
                  change={changeItem}
                  suffix={field.suffix ? field.suffix : null}
                  margin={field.margin ? field.margin : ""}
                  {...field}
                />

                <div
                  style={{
                    height: 40,
                  }}
                  className="d-center"
                >
                  <button
                    type="button"
                    className="btn  btn-sm shadow-none"
                    onClick={() => removeItem(k)}
                  >
                    <i className="fa fa-times text-danger" />
                  </button>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default SimpleDynamicList;
