import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const remedyActions = (state, dispatch, props) => {
  const updateErrors = (errors) => {
    dispatch({
      type: "UPDATE_REMEDIES_ERRORS",
      payload: errors,
    });
  };

  const updateIsLoading = (isLoading) => {
    dispatch({
      type: "UPDATE_REMEDIES_LOADING",
      payload: isLoading,
    });
  };
  const updateRemediesFilters = (filters) => {
    dispatch({
      type: "UPDATE_REMEDIES_FILTERS",
      payload: filters,
    });
  };
  const updateRemediesPage = (filters) => {
    dispatch({
      type: "UPDATE_REMEDIES_FILTERS",
      payload: filters,
    });
    searchRemedies(filters);
  };
  const searchRemedies = (filters = null) => {
    updateIsLoading(true);
    dataService.post(
      `remedies/search`,
      filters || state.remedies.remediesFilters,
      (data) => {
        dispatch({
          type: "UPDATE_REMEDIES_SEARCH",
          payload: data,
        });
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };
  const getRemedy = (remedyId) => {
    updateIsLoading(true);
    dataService.get(`remedies/${remedyId}`, updateRemedy, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateRemedy = (remedy) => {
    dispatch({
      type: "UPDATE_REMEDY",
      payload: remedy,
    });
  };
  const getRemedies = () => {
    updateIsLoading(true);
    dataService.get(`remedies`, updateRemedies, updateErrors, () =>
      updateIsLoading(false)
    );
  };

  const updateRemedies = (remedies) => {
    dispatch({
      type: "UPDATE_REMEDIES",
      payload: remedies,
    });
  };
  const updateRemedyAttribute = ({ event, lang }) => {
    const { remedy } = state.remedies;
    const { name, value } = event.target;
    let newRemedy = {};
    if (lang) {
      newRemedy = {
        ...remedy,
        [name]: {
          ...remedy[name],
          [lang]: value,
        },
      };
    } else {
      newRemedy = {
        ...remedy,
        [name]: value,
      };
    }
    updateRemedy(newRemedy);
  };

  const patchRemedy = (remedy) => {
    updateIsLoading(true);
    dataService.patch(
      `remedies/${remedy.id}`,
      remedy,
      (datas) => {
        searchRemedies();
        updateErrors({});
        toast.success("Le remède a bien été mis à jour");
      },
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  const saveRemedy = () => {
    const { remedy } = state.remedies;
    updateIsLoading(true);
    if (remedy.id) {
      dataService.patch(
        `remedies/${remedy.id}`,
        remedy,
        (datas) => {
          updateRemedy(datas);
          updateErrors({});
          toast.success("Le remède a bien été mis à jour");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    } else {
      dataService.post(
        `remedies`,
        remedy,
        (datas) => {
          updateRemedy(datas);
          updateErrors({});
          const { remedies } = state.remedies;
          updateRemedies([...remedies, datas]);
          toast.success("Le remède a bien été créé");
        },
        updateErrors,
        () => updateIsLoading(false)
      );
    }
  };

  const deleteRemedy = (remedyId) => {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    updateIsLoading(true);

    dataService.remove(
      `remedies/${remedyId}`,
      (datas) => searchRemedies(),
      updateErrors,
      () => updateIsLoading(false)
    );
  };

  return {
    patchRemedy,
    updateIsLoading,
    searchRemedies,
    updateRemediesFilters,
    updateRemedy,
    updateRemedyAttribute,
    saveRemedy,
    getRemedy,
    deleteRemedy,
    updateRemediesPage,
    getRemedies,
  };
};
